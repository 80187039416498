/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ClaimButton from '../../components/ClaimButton';

import codeBreakerFlying from '../../images/codebreaker-flying-exobots.png';
import EmptyTopBanner from '../../sharedComponents/EmpyTopBanner/EmptyTopBanner';
import BottomBanner from '../../sharedComponents/BottomBanner';

const ClaimScreen = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <EmptyTopBanner />
      <div className="watercolor-top-blue" />
      <div className="blueprint">
        <div className="container top-banner pb-5">
          <div className="row align-items-center py-5 claim justify-content-center">
            <div className="col-3" />
            <div className="col-6">
              <div className="banner-title mt-5 text-center">
                <span className="ino-header-txt claim-txt display-2 display-5-sm text-center">{t('claim.thank')}</span>
                <br />
                <span className="ino-header-txt claim-txt display-2 text-center">{t('claim.you')}</span>
                <br />
                <h1 className="display-3 claim-title"><em>{t('claim.investors')}</em></h1>
                <h5 className="white-txt">
                  {t('claim.text1')}
                </h5>
                <ClaimButton />
                <img src={codeBreakerFlying} className="img-fluid cb-float" alt="..." />

              </div>

            </div>
            <div className="col-3" />

          </div>
        </div>
        <BottomBanner />
      </div>
    </>
  );
};

export default ClaimScreen;
