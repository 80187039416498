/* eslint-disable no-console */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import Spinner from '../../sharedComponents/spinner';
import { logout } from '../../utils';
import { API } from '../../utils/api';
import { IUserState } from '../../utils/types';

const LinkEmail = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [code, setCode] = useState<string>();
  const [error] = useState();

  const [validationStep1Success, setValidationStep1Success] = useState<boolean>(false);
  const [validationStep2Success] = useState<boolean>(false);

  const user: IUserState = useAppSelector<IUserState>((state: RootState) => state.user);

  const handleValidationStep1 = () => {
    setLoading(true);
    try {
      API.authentication.vinculationStep1(user.token, email).then(() => {
        setValidationStep1Success(true);
        setLoading(false);
      });
    } catch (err) {
      setValidationStep1Success(false);
      setLoading(false);
    }
  };

  const handleValidationStep2 = async () => {
    setLoading(true);
    API.authentication.vinculationStep2(user.token, email, code)
      .then(() => {
        setLoading(false);
        logout();
      });
  };

  return (
    <>
      <button type="button" className="btn btn-primary  btn-xlg" data-bs-toggle="modal" data-bs-target="#link-email">
        Link Email
      </button>
      <div
        className="modal fade shadow"
        id="link-email"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="reset-password"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content shadow">
            <div className="modal-header">
              <h5 className="modal-title avega" id="exampleModalLongTitle">Link Email</h5>
              <button type="button" className="close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i className="fa fa-light fa-xmark" /></span>
              </button>
            </div>
            <div className="modal-body">
              {loading && <Spinner />}
              <form>
                <span>Enter the email you want to link: </span>
                <input type="email" onChange={(e) => setEmail(e.target.value)} className="text-black" />
              </form>
              <button type="button" className="btn btn-primary text-white" onClick={handleValidationStep1}>
                Send code via email
              </button>
              {validationStep1Success
                              && (
                                <>
                                  <form>
                                    <span>
                                      Enter the code we just sent you to :
                                      {email}
                                    </span>
                                    <input type="text" onChange={(e) => setCode(e.target.value)} className="text-black" />
                                  </form>
                                  <button type="button" className="btn btn-primary text-white" onClick={handleValidationStep2}>Link acount</button>
                                </>
                              )}
              {validationStep2Success
                && (
                  <p>
                    AWESOME! Email :
                    {' '}
                    {email}
                    {' '}
                    is now linked to your wallet
                    {' '}
                    {user.wallet}
                  </p>
                )}
              {error && (<p>{error}</p>)}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkEmail;
