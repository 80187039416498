/* eslint-disable no-implied-eval */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';
import { nftContract } from '../../utils/constants';
import nft_abi from '../../utils/abi/nft_abi';
import Spinner from '../spinner';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AppDispatch } from '../../redux/store';
import { setNotification } from '../../redux/features/notifications/notificationsSlice';
import useNavigateWithTransition from '../../hooks/use-navigateWithTransition';

declare let window: any;

type giftProps = {
  exoId: number,
  disabled: boolean,
}

const GiftNft = ({ exoId, disabled }: giftProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();
  const [success, setSuccess] = useState<boolean>();
  const [walletError, setWalletError] = useState<boolean>(false);
  const [recipient, setRecipient] = useState<string>();

  const wallet: string = useAppSelector<string>((state: any) => state.user.wallet);

  const [setTab] = useNavigateWithTransition();

  const { t } = useTranslation('common');

  const dispatch: AppDispatch = useAppDispatch();

  const gift = (signer: any, to: any) => {
    const notificationId = `${wallet.replace('0x', '')}_${to.replace('0x', '')}_${exoId}`;
    const erc201 = new ethers.Contract(nftContract, nft_abi, signer);
    erc201.connect(signer).safeTransferFrom(wallet, to, exoId)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setError(false);
        const payload = {
          notificationId,
        };
        dispatch(setNotification(payload));
        window.location.reload();
        setTab('/dashboard/market');
      })
      .catch(() => {
        setError(true);
        setLoading(false);
        setSuccess(false);
      });
  };

  const putForSale = async () => {
    const prov = new ethers.providers.Web3Provider(window.ethereum);
    const signer = prov.getSigner(wallet);
    gift(signer, recipient?.toLocaleLowerCase());
  };

  const handlePutForSale = () => {
    setLoading(true);
    try {
      putForSale();
    } catch (error3) {
      setLoading(false);
      setSuccess(false);
      setError(true);
    }
  };

  const handleClose = () => {
    setLoading(false);
    setError(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-xxlg btn-outline-light"
        data-bs-toggle="modal"
        data-bs-target="#gifted-exobot"
        style={{ marginLeft: '20px' }}
        disabled={disabled}
      >
        <span>
          <i className="fa fa-solid fa-gift" />
          {' '}
          {t('gift.gift')}
          {' '}
        </span>
      </button>
      <div
        className="modal fade shadow"
        id="gifted-exobot"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="reset-password"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered text-black" role="document">
          <div className="modal-content shadow">
            <div className="modal-header">
              <h5 className="modal-title avega" id="exampleModalLongTitle">{t('gift.setWallet')}</h5>
              <button type="button" className="close modal-close-btn" data-bs-dismiss="modal" onClick={handleClose} aria-label="Close">
                <span aria-hidden="true"><i className="fa fa-light fa-xmark" /></span>
              </button>
            </div>
            <div className="modal-body">
              {loading ? <Spinner /> : (
                success ? (
                  <p>
                    {t('gift.success')}
                    {' '}
                    {exoId}
                    {' '}
                    {t('gift.to')}
                    {' '}
                    {recipient}
                    .
                  </p>
                ) : (
                  error ? (<p>{t('gift.error')}</p>) : (
                    <>
                      {walletError ? (<p>{t('gift.walletError')}</p>)
                        : (<p>{t('gift.wallet')}</p>)}
                      <form>
                        <div className="col">
                          <input
                            type="text"
                            onChange={(e:any) => setRecipient(e.target.value)}
                            className="form-control border"
                            id="set-exo-price"
                            aria-describedby=""
                          />
                        </div>
                      </form>
                    </>
                  )
                )
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">{t('cancel')}</button>
              <button type="submit" className="btn btn-primary" disabled={loading} onClick={handlePutForSale}>{t('gift.gift')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftNft;
