/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import useNavigateWithTransition from '../../hooks/use-navigateWithTransition';
import iconCapsule from '../../images/icon-capsule.png';

type CapsulesBalanceProps = {
  numCapsules: string;
}

const CapsulesBalance = ({ numCapsules }: CapsulesBalanceProps) => {
  const [setTab] = useNavigateWithTransition();

  const handleNavigateToBuyBox = () => {
    setTab('/capsules');
  };
  return (
    <button
      type="button"
      className="transparent-btn"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Your capsule balance"
      style={{ marginRight: '25px' }}
      onClick={handleNavigateToBuyBox}
    >
      <span className="avega white-txt" style={{ paddingLeft: '10px' }}>
        <img src={iconCapsule} width="32" />
        {' '}
        {numCapsules}
        {' '}
        <small> </small>
      </span>

    </button>
  );
};

export default CapsulesBalance;
