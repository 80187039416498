/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import { callWrapper, getConfig } from '../../utils/api';

class Wallet {
  async nonceWithWallet(address: string) {
    return callWrapper(`/Nonce?wallet=${address}`, {});
  }

  async loginWithWallet(wallet: string, signature: string) {
    return callWrapper('/LoginWithWallet', {}, {
      Wallet: wallet,
      Signature: signature,
    }, 'POST');
  }

  async messageToSign(type: number, exobotId: number, price: number, jwt: string | null) {
    return callWrapper('/MessageToSign', getConfig(jwt), {
      MessageType: type,
      ExobotId: Number(exobotId),
      Price: Number(price),
    }, 'POST');
  }

  async putForSale(signature: string, jwt: string | null, data: string, dataSignature: string) {
    return callWrapper('/PutForSale', getConfig(jwt), { MessageSignature: signature, Data: data, DataSignature: dataSignature }, 'POST');
  }

  async purchaseData(jwt: string, wallet: string, exobotId: string) {
    return callWrapper('/PurchaseData', getConfig(jwt), { Wallet: wallet, ExobotId: exobotId }, 'POST');
  }

  async changePrice(MessageSignature: string, jwt: string | null, Data: string, DataSignature: string) {
    return callWrapper('/ChangePrice', getConfig(jwt), { MessageSignature, Data, DataSignature }, 'POST');
  }

  async removeFromSale(exobotId: number, jwt: string | null) {
    return callWrapper('/RemoveFromSale', getConfig(jwt), { ExobotId: Number(exobotId) }, 'POST');
  }
}

export default Wallet;
