/* eslint-disable no-implied-eval */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';
import { nftContract, operatorAddress } from '../../utils/constants';
import nft_abi from '../../utils/abi/nft_abi';

import { useAppSelector } from '../../redux/hooks';
import { sleep } from '../../utils';
import { API } from '../../utils/api';
import TransactionSteps from '../../components/TransactionSteps';
import { transactionStep } from '../../utils/types';

declare let window: any;

type forSaleProps = {
  exoId: number,
  disabled: boolean
}

const PutForSaleButton = ({ exoId, disabled }: forSaleProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>();
  const [price, setPrice] = useState<number>(0);
  const [feeAmount, setFeeAmount] = useState<number>(0);
  const [sellFee, setSellFee] = useState<number>(0);
  const [placeholder, setPlaceholder] = useState<string>('Price in EXOS');
  const [priceError, setPriceError] = useState<boolean>(false);
  const [isApproved, setIsApproved] = useState<boolean>(false);

  const { t } = useTranslation('common');

  const wallet: string = useAppSelector<string>((state: any) => state.user.wallet);
  const jwt: string = useAppSelector<string>((state: any) => state.user.token);

  const [firstStep, setFirstStep] = useState<transactionStep>({
    text: t('putForSale.step1'),
    loading: true,
    error: false,
    success: false,
    waiting: false,
  });

  const [secondStep, setSecondStep] = useState<transactionStep>({
    text: t('putForSale.step2'),
    loading: false,
    error: false,
    success: false,
    waiting: true,
  });

  useEffect(() => {
    API.search.checkSellFee(jwt)
      .then((res: any) => {
        setSellFee(res.data.Result.SellFee);
      });
  }, []);

  const approve = async (signer: any) => {
    const erc20 = new ethers.Contract(nftContract, nft_abi, signer);
    await erc20.connect(signer).setApprovalForAll(operatorAddress, true)
      .catch(() => {
        setFirstStep({
          ...firstStep, loading: false, error: true, success: false, waiting: false,
        });
      });
  };

  const putForSale = async () => {
    setSecondStep({
      ...secondStep, loading: true, error: false, success: false, waiting: false,
    });
    const priceForSell = price || 2000;

    const messageToSign = await API.wallet.messageToSign(0, exoId, priceForSell, jwt);

    if (messageToSign?.status !== 200) {
      setSecondStep({
        ...secondStep, loading: false, error: true, success: false, waiting: false,
      });
    } else {
      const data = messageToSign.data.Result.Data;

      const dataSignature = messageToSign.data.Result.DataSignature;

      const accounts = await window?.ethereum.request({
        method: 'eth_requestAccounts',
      });

      const account = accounts[0];

      const signature = await window?.ethereum.request({ method: 'personal_sign', params: [account, messageToSign.data.Result.MessageToSign] })
        .catch(() => {
          setSecondStep({
            ...secondStep, loading: false, error: true, success: false, waiting: false,
          });
        });

      const response = await API.wallet.putForSale(signature, jwt, data, dataSignature);

      if (response?.status === 200) {
        setSecondStep({
          ...secondStep, loading: false, error: false, success: true, waiting: false,
        });
        setSuccess(true);
        window.location = '/dashboard/market';
      } else {
        setSecondStep({
          ...secondStep, loading: false, error: true, success: false, waiting: false,
        });
        setSuccess(false);
      }
    }
  };

  const handlePutForSale = async () => {
    setLoading(true);
    if (price && price > 0 && price < 10000000) {
      try {
        setPlaceholder('Price in EXOS');
        const prov = new ethers.providers.Web3Provider(window.ethereum);
        prov.send('eth_requestAccounts', []);
        const signer = prov.getSigner(wallet);
        const erc20 = new ethers.Contract(nftContract, nft_abi, signer);
        await erc20.connect(signer).isApprovedForAll(wallet, operatorAddress).then((isApprvd: boolean) => setIsApproved(isApprvd));

        if (isApproved) {
          setFirstStep({
            ...firstStep, loading: false, success: true,
          });
          putForSale();
        } else {
          approve(signer);
          sleep(10000).then(() => {
            setFirstStep({
              ...firstStep, loading: false, error: false, success: true, waiting: false,
            });
            putForSale();
          });
        }
      } catch (error3) {
        setSuccess(false);
      }
    } else {
      setPriceError(true);
    }
  };

  const handleClose = () => {
    setLoading(false);
    setPrice(0);
    setPriceError(false);
    setSellFee(0);
    setFeeAmount(0);
    setFirstStep({
      ...firstStep,
      loading: true,
      error: false,
      success: false,
      waiting: false,
    });
    setSecondStep({
      ...secondStep,
      loading: false,
      error: false,
      success: false,
      waiting: true,
    });
  };

  const handleFee = (amount: number) => {
    const am = sellFee / 100;
    const feeAm = amount * am;
    setFeeAmount(feeAm);
    navigator.vibrate(100);
  };

  const setPriceAndFee = (amount: number) => {
    setPrice(amount);
    handleFee(amount);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-xxlg btn-primary yellow-btn"
        data-bs-toggle="modal"
        data-bs-target="#sell-exobot"
        disabled={disabled}
      >
        <span>
          <i className="fa fa-solid fa-tag" />
          {' '}
          {t('putForSale.sell')}
          {' '}
        </span>
      </button>
      <div
        className="modal fade shadow"
        id="sell-exobot"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="reset-password"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered text-black" role="document">
          <div className="modal-content shadow">
            <div className="modal-header">
              <h5 className="modal-title avega" id="exampleModalLongTitle">{t('putForSale.setPrice')}</h5>
              <button type="button" className="close modal-close-btn" data-bs-dismiss="modal" onClick={handleClose} aria-label="Close">
                <span aria-hidden="true"><i className="fa fa-light fa-xmark" /></span>
              </button>
            </div>
            <div className="modal-body">
              {loading ? <TransactionSteps steps={[firstStep, secondStep]} /> : (
                success ? (<TransactionSteps steps={[firstStep, secondStep]} />) : (
                  <>
                    {priceError ? (<p>{t('putForSale.msg1')}</p>)
                      : (<p>{t('putForSale.msg1')}</p>)}
                    <form>
                      <div className="col">
                        <input
                          type="number"
                          min="1"
                          max="10000000"
                          onChange={(e:any) => setPriceAndFee(e.target.value)}
                          className="form-control border"
                          id="set-exo-price"
                          aria-describedby=""
                          placeholder={placeholder}
                        />
                      </div>
                    </form>
                    {sellFee ? (
                      <p>
                        {t('putForSale.sellFee')}
                        {' '}
                        {sellFee}
                        {' '}
                        %.
                      </p>
                    ) : (<p>{t('putForSale.calculatingSellFee')}</p>)}
                    {feeAmount ? (
                      <p>
                        {t('putForSale.receive')}
                        {' '}
                        {price - feeAmount}
                        {' '}
                        {t('putForSale.receive2')}
                        {' '}
                        {feeAmount}
                        {' '}
                        EXOS
                      </p>
                    ) : (<p>{t('putForSale.calculating')}</p>)}
                  </>
                )
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">{t('cancel')}</button>
              <button type="submit" className="btn btn-primary" disabled={loading} onClick={handlePutForSale}>{t('putForSale.putForSale')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PutForSaleButton;
