/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Spinner from '../../sharedComponents/spinner';
import soloIMG from '../../images/ino-solo-pack.png';
import useBuyNFT from '../../hooks/use-buyNFT';
import { msOperationDelay, URL_BLOB_IMAGES } from '../../utils/constants';
import { sleep } from '../../utils';

const assembling = (text1: any, text2:any) => (
  <div className="modal-body">
    <p className="text-center">
      {text1}
      {' '}
      <br />
      {text2}
    </p>
    <div className="load-wrapp">
      <div className="load-10">
        <div className="bar" />
      </div>
    </div>
    <img className="img-fluid idle-strech" src={soloIMG} alt="Product" />
  </div>
);

const assembled = (urlsToPoll: string[], text1: any, text2: any, text3: any) => (
  <div className="modal-body">
    <p className="text-center">
      {text1}
      {' '}
      <br />
      {text2}
      <br />
      {text3}
    </p>
    <div className="row">
      {urlsToPoll.map((imgURL) => <div className="col" key={imgURL}><img className="img-fluid" src={imgURL} /></div>)}
    </div>
  </div>
);
const ClaimButton = () => {
  const { t } = useTranslation('common');

  const [show, setShow] = useState<boolean>(false);

  const [animation, setAnimation] = useState<boolean>(false);

  const [pollingFinished, setPollingFinished] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);

  const [wallet, setWallet] = useState<string | null>(null);

  const [pollingURL, setPollingURL] = useState<string | null>(null);

  const [urlsToPoll, setUrlsToPoll] = useState<string[] | null>(null);

  const { claimNFT, getSigner } = useBuyNFT();

  const getWallet = async () => {
    const signer = await getSigner();
    const provWallet = await signer.getAddress();
    setWallet(provWallet);
  };

  useEffect(() => {
    if (pollingURL !== null && !pollingFinished) {
      const a = setInterval(() => {
        const config = {
          headers: { 'Access-Control-Allow-Origin': '*' },
        };
        axios.get(pollingURL, config)
          .then(() => {
            setPollingFinished(true);
            setAnimation(false);
            clearInterval(a);
          })
          .catch((err) => {
            if (err.message !== 'Request failed with status code 404') {
              setPollingFinished(true);
              setAnimation(false);
              clearInterval(a);
            }
          });
      }, msOperationDelay);
    }
  }, [pollingURL, show]);

  const handleOpenClaimModal = async () => {
    setShow(true);
    getWallet();
    const numOfClaimedNFTs = await claimNFT();

    if (numOfClaimedNFTs === null || numOfClaimedNFTs.length <= 0) {
      setError(true);
      setAnimation(false);
      setUrlsToPoll(null);
    } else {
      setAnimation(true);
      const urlToFetch = `${URL_BLOB_IMAGES}sm-${numOfClaimedNFTs[0]}.png`;
      setPollingURL(urlToFetch);
      const urls = numOfClaimedNFTs.map((url: any) => `${URL_BLOB_IMAGES}sm-${url}.png`);
      sleep(msOperationDelay * 5);
      setUrlsToPoll(urls);
      setAnimation(false);
    }
  };

  const handleReloadClaimModal = () => {
    setAnimation(false);
    setPollingFinished(false);
    setError(false);
    setWallet(null);
    setPollingURL(null);
    setUrlsToPoll(null);
    setShow(false);
  };

  return (
    <>
      <button className="btn btn-primary me-2 btn-xxlg signup-but mt-4" onClick={handleOpenClaimModal}>
        {t('claim.claim')}
      </button>
      <Modal
        show={show}
        backdrop="static"
        onHide={handleReloadClaimModal}
        className="modal fade shadow"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-content shadow">
          <Modal.Header className="modal-header">
            <h5>{t('claim.claim')}</h5>
            <button type="button" className="close modal-close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleReloadClaimModal}>
              <span aria-hidden="true"><i className="fa fa-light fa-xmark" /></span>
            </button>
          </Modal.Header>
          {!animation && !error && urlsToPoll === null ? (
            <div className="modal-body" style={{ textAlign: 'center' }}>
              <p>Procesing your request, please wait.</p>
              <Spinner />
            </div>
          ) : null }
          {error ? (
            <div className="modal-body" style={{ textAlign: 'center' }}>
              <p>
                Error!! There are no Exobots to claim for wallet:
                {' '}
                {wallet}
              </p>
            </div>
          ) : null}
          {animation && !error ? assembling(t('INO.assembling'), t('INO.pleaseWait')) : null}
          {urlsToPoll !== null && !error ? assembled(urlsToPoll, t('INO.congratulations'), t('INO.fight'), t('INO.redirect')) : null}
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleReloadClaimModal}>{t('exit')}</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClaimButton;
