/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TransactionSteps from '../../components/TransactionSteps';
import useBuyNFT from '../../hooks/use-buyNFT';
import { setNotification } from '../../redux/features/notifications/notificationsSlice';
import { useAppDispatch } from '../../redux/hooks';
import { AppDispatch } from '../../redux/store';
import { transactionStep } from '../../utils/types';

type buyCapsulesProps = {
  numCapsules: number,
  price: number
}

const BuyCapsules = ({ numCapsules, price }: buyCapsulesProps) => {
  const { t } = useTranslation('common');

  const { approveEXOS, buyCapsules } = useBuyNFT();

  const [show, setShow] = useState<boolean>(false);

  const dispatch: AppDispatch = useAppDispatch();

  const [firstStep, setFirstStep] = useState<transactionStep>({
    text: 'Approving and confirming EXOS allowance.',
    loading: true,
    error: false,
    success: false,
    waiting: false,
  });

  const [secondStep, setSecondStep] = useState<transactionStep>({
    text: 'Appropving and confirming buy Capsules transaction.',
    loading: false,
    error: false,
    success: false,
    waiting: true,
  });

  const handleReloadModal = () => {
    setShow(false);
    setFirstStep({
      ...firstStep,
      loading: true,
      error: false,
      success: false,
      waiting: false,
    });
    setSecondStep({
      ...secondStep,
      loading: false,
      error: false,
      success: false,
      waiting: true,
    });
  };

  const buy = async () => {
    setShow(true);
    const signer = await approveEXOS(price);
    if (signer !== null) {
      setFirstStep({
        ...firstStep,
        loading: false,
        success: true,
      });
      setSecondStep({
        ...secondStep,
        waiting: false,
        loading: true,
      });
      const notificationId = await buyCapsules(signer, numCapsules);
      if (notificationId !== null) {
        setSecondStep({
          ...secondStep,
          loading: false,
          waiting: false,
          success: true,
        });
        const payload = {
          notificationId,
        };
        dispatch(setNotification(payload));
        setShow(false);
      } else {
        setSecondStep({
          ...secondStep,
          loading: false,
          error: true,
        });
      }
    } else {
      setFirstStep({
        ...firstStep,
        loading: false,
        error: true,
      });
    }
  };

  return (
    <div className="col-100 mt-2 white text-center">
      <button
        type="button"
        className="btn btn-primary btn-xxlg yellow-btn mb-4"
        style={{ marginLeft: '0' }}
        onClick={buy}
      >
        <span>BUY</span>

      </button>
      <Modal
        show={show}
        onHide={handleReloadModal}
        backdrop="static"
        className="modal fade shadow"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-content-shadow" role="document">
          <div>
            <Modal.Header className="modal-header">
              <h5 className="modal-title avega" id="exampleModalLongTitle">
                BUYING
                {' '}
                {numCapsules}
                {' '}
                CAPSULES
              </h5>
              <button type="button" className="close modal-close-btn" data-bs-dismiss="modal" onClick={handleReloadModal} aria-label="Close">
                <span aria-hidden="true"><i className="fa fa-light fa-xmark" /></span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-body" style={{ textAlign: 'initial' }}>
                <TransactionSteps steps={[firstStep, secondStep]} />
              </div>
            </Modal.Body>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleReloadModal}>{t('exit')}</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BuyCapsules;
