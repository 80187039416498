/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { transactionStep } from '../../utils/types';

type TransactionStepsProps = {
  steps: transactionStep[]
}

const TransactionSteps = ({ steps }: TransactionStepsProps) => (
  <div>
    {steps.map((step) => (
      <p key={step.text}>
        {step.error ? (<i className="fa-solid fa-xmark" style={{ width: 15, height: 15 }} />) : null}
        {step.success ? (<i className="fa-regular fa-circle-check" style={{ width: 15, height: 15 }} />) : null}
        {step.loading ? (
          <div className="spinner-border text-warning" role="status" style={{ width: 15, height: 15 }} />
        ) : null}
        {step.waiting ? (<i className="fa-solid fa-pause" style={{ width: 15, height: 15 }} />) : null}
        {' '}
        {step.text}
      </p>
    ))}
  </div>
);

export default TransactionSteps;
