/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';

type FiltersBarProps = {
  resultsPerPage: number,
  setResultsPerPage: any,
  setSortBy: any,
}

const FiltersBar = (props: FiltersBarProps) => {
  const {
    resultsPerPage, setResultsPerPage, setSortBy,
  } = props;

  const { t } = useTranslation('common');

  return (
    <div className="row align-items-center">
      <div className="col dropdown me-2">
        <a className="btn btn-outline-light dropdown-toggle collapsed" href="#exo-filters" data-bs-toggle="collapse" aria-expanded="false">
          <i className="fa-regular fa-filter me-2" />
          {t('home.filters.title')}
        </a>
      </div>

      <div className="col  text-end right align-items-center">
        {' '}
        <label>{t('home.filters.order')}</label>
        <select className="exo-select dropdown-toggle" aria-label="Order by" defaultValue="0" onChange={(e) => setSortBy(e.target.value)}>
          <option value="latest">{t('home.filters.newest')}</option>
          <option value="priceAsc">
            {t('home.filters.price')}
            {' '}
            &#8595;
          </option>
          <option value="priceDesc">
            {t('home.filters.price')}
            {' '}
            &#8593;
          </option>
          <option value="idAsc">
            {t('home.filters.id')}
            {' '}
            &#8595;
          </option>
          <option value="idDesc">
            {t('home.filters.id')}
            {' '}
            &#8593;
          </option>
        </select>
      </div>
      <div className="col text-end right align-items-center">
        <label>{t('home.filters.show')}</label>
        <select
          className="exo-select dropdown-toggle"
          aria-label="Items displayed"
          onChange={(e) => { setResultsPerPage(e.target.value); }}
          value={resultsPerPage}
        >
          <option value="12">12</option>
          <option value="24">24</option>
          <option value="36">36</option>
        </select>
      </div>
    </div>
  );
};

export default FiltersBar;
