/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import LoginDropDown from '../../components/LoginDropDown';
import ProfileDropDown from '../../components/ProfileDropDown';
import SwitchLanguage from '../../components/SwitchLanguage';

import logo from '../../images/exobots-marketplace-outline.png';

type headerProps = {
  isLoggedIn: boolean,
}

const Header = (props: headerProps) => {
  const { isLoggedIn } = props;
  return (
    <header>
      <SwitchLanguage />
      <div className="container d-flex flex-wrap align-items-center justify-content-between" id="menu-section">
        <div className="col col-sm text-md-start text-center">
          <a href="/" className="d-flex mb-2 mb-md-0 text-dark text-decoration-none">
            <img id="logo" src={logo} alt="Exobots Game Logo" />
          </a>
        </div>
        <div id="top-buttons" className="text-end">
          {isLoggedIn ? <ProfileDropDown /> : <LoginDropDown />}
        </div>
      </div>
    </header>
  );
};

export default Header;
