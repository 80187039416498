/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks';
import { API } from '../../utils/api';
import Spinner from '../spinner';

type IEditPriceButtonProps = {
  exoId: number,
}

declare let window: any;

const EditPriceButton = ({ exoId }: IEditPriceButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [newPrice, setNewPrice] = useState<number>(0);
  const [sellFee, setSellFee] = useState<number>(0);

  const { t } = useTranslation('common');

  const jwt: string = useAppSelector<string>((state: any) => state.user.token);

  useEffect(() => {
    API.search.checkSellFee(jwt)
      .then((res: any) => {
        setSellFee(res.data.Result.SellFee);
      });
  }, []);

  const handleResetModal = () => {
    setNewPrice(0);
    setLoading(false);
    setIsError(false);
    setSellFee(0);
    setSuccess(false);
  };

  const handleEditPrice = async () => {
    setLoading(true);
    if (newPrice && newPrice > 0 && newPrice < 10000000) {
      const priceForSell = newPrice || 2000;

      const { data } = await API.wallet.messageToSign(1, exoId, priceForSell, jwt);

      if (data !== undefined) {
        const { Data, DataSignature, MessageToSign } = data.Result;

        const accounts = await window?.ethereum.request({
          method: 'eth_requestAccounts',
        });

        const account = accounts[0];

        const signature = await window?.ethereum.request({ method: 'personal_sign', params: [account, MessageToSign] });

        const response = await API.wallet.changePrice(signature, jwt, Data, DataSignature);

        if (response?.status === 200) {
          setLoading(false);
          setIsError(false);
          setSuccess(true);
          window.location = '/dashboard/market';
        } else {
          setIsError(true);
          setSuccess(false);
          setLoading(false);
        }
      } else {
        setIsError(true);
        setSuccess(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-xxlg btn-outline-light"
        data-bs-toggle="modal"
        data-bs-target="#edit-price"
        style={{ marginLeft: '20px' }}
      >
        <span>
          <i className="fa fa-solid fa-edit" />
          {' '}
          {t('editPrice.title')}
        </span>
      </button>
      <div
        className="modal fade shadow"
        id="edit-price"
        role="dialog"
        aria-labelledby="edit-price"
        aria-hidden="true"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered text-black" role="document">
          <div className="modal-content shadow">
            <div className="modal-header">
              <h5 className="modal-title avega" id="exampleModalLongTitle">{t('editPrice.title')}</h5>
              <button type="button" className="close modal-close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleResetModal}>
                <span aria-hidden="true"><i className="fa fa-light fa-xmark" /></span>
              </button>
            </div>
            {!loading && !isError ? (
              <div className="modal-body">
                <p>{t('editPrice.setNew')}</p>
                <form>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control border"
                      id="new-exo-price"
                      min="0"
                      aria-describedby=""
                      placeholder="New price in EXOS"
                      value={newPrice > 0 ? newPrice : ''}
                      onChange={(e:any) => setNewPrice(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            ) : null}
            {loading ? (<Spinner />) : null}
            {isError ? (
              <div className="modal-body">
                <p>{t('editPrice.error')}</p>
                <div className="col">
                  {t('editPrice.error2')}
                </div>
              </div>
            ) : null}
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleResetModal}>{t('cancel')}</button>
              <button type="button" disabled={loading || isError} onClick={handleEditPrice} className="btn btn-primary">{t('editPrice.update')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPriceButton;
