/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IExobotDetail } from '../../utils/types';
import PlaceholderImage from './placeholder.png';

import { URL_BLOB_IMAGES } from '../../utils/constants';
import { useAppSelector } from '../../redux/hooks';

const coreMin = [
  'sh',
  'rv',
  'cb',
  'gs',
  'sr',
  'db',
  'ns',
  'sm',
  'ds',
];

const coreName = [
  'Stronghold',
  'Renovator',
  'Codebreaker',
  'Groundsmasher',
  'Sumrak',
  'Deathbringer',
  'Netshadow',
  'Scrapmaster',
  'Datashaper',
];

interface IExobotPros extends IExobotDetail {
  fromInventory?: boolean
}

const getUrl = (id: string) => `${URL_BLOB_IMAGES}sm-${id}.png`;

const Exobot = (props: IExobotPros) => {
  const cssClass = `col-2 exo-info text-center ${coreMin[props.Family]}-bg white p-2 px-0`;
  const route = props?.fromInventory ? `/inventory/${props.Id}` : `/exobot/${props.Id}`;
  const currentPrice: number = useAppSelector<number>((state) => state.user.currentPrice);
  const [price, setPrice] = useState<string | undefined>();

  useEffect(() => {
    if (props.Price) {
      const round = props.Price * currentPrice;
      setPrice(round.toFixed(4));
    } else {
      setPrice(undefined);
    }
  }, []);

  return (
    <div className="col-lg-3 col-md-4 col-sm-6">
      <div className={`card product-card zoom rare${props.Rarity}-bg`}>
        <div className="container top-card-info">
          <div className="row card-id">
            <div className="col-6 white exo-info p-2 ">
              <p className="avega info-card-value">ID</p>
              <p className="info-card-label">{props.Name}</p>
            </div>
            <div
              className={cssClass}
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title={coreName[props.Family]}
            >
              <p className="avega info-card-value">{coreMin[props.Family]}</p>
              <p className="info-card-label">CORE</p>
            </div>
            <div className="col-2 exo-info text-center white p-2 px-0">
              <p className="avega info-card-value">
                {props.MergeCount}
                <small className="out-of">
                  /
                  {props.MaxMerges}
                </small>
              </p>
              <p className="info-card-label">MERGE</p>
            </div>
            <div className="col-2 exo-info text-center white p-2 px-0">
              <p className="avega info-card-value">
                {props.CurrentSlots}
                <small className="out-of">
                  /
                  {props.MaxSlots}
                </small>
              </p>
              <p className="info-card-label">SLOTS</p>
            </div>
          </div>
        </div>

        <div>
          {price !== undefined ? (
            <>
              <h1 className="text-center product-price mb-md-0">
                <b>
                  {props.Price?.toLocaleString()}
                  {' '}
                </b>
                {props.Price ? <span className="fs-6">EXOS</span> : null}
              </h1>
              <p className="text-center product-price-dollar mb-md-0">
                {price.toLocaleString()}
                {' '}
                BNB
              </p>
            </>
          ) : (
            <>
              <h1 className="text-center product-price mb-md-0">
                <b />
                <span className="fs-6" />
              </h1>
              <p className="text-center product-price-dollar mb-md-0" />
            </>
          )}

          <a href={route} className="card card-overlay-bottom card-img-scale pb-4">
            <div className="idle-strech">
              <LazyLoadImage
                width={306}
                height={280}
                src={getUrl(props.Id.toString())}
                className="card-img"
                alt="Product"
                placeholderSrc={PlaceholderImage}
              />
            </div>
            <div className="card-img-overlay d-flex align-items-center p-1 p-sm-2">
              <div>
                <div className="col exobot-level-card align-self-end" style={{ backgroundImage: `url(/rarity-image-${props.Rarity}.png)` }}>
                  {props.Level}
                </div>
              </div>
              <div className="row w-100 mt-auto">
                <div className="col">
                  <div className="row justify-content-center white-txt item-stats text-center p-3 pt-2 pb-1">
                    <div className="col px-1">
                      <p className="hp-th stats-table">HP</p>
                      {props.Stats.HP}
                    </div>

                    <div className="col px-1">
                      <p className="def-th stats-table">DEF</p>
                      {props.Stats.DEF}
                    </div>

                    <div className="col px-1">
                      <p className="ap-th stats-table">AP</p>
                      {props.Stats.AP}
                    </div>

                    <div className="col  px-1">
                      <p className="spd-th stats-table">SPD</p>
                      {props.Stats.SPD}
                    </div>

                    <div className="col px-1">
                      <p className="crit-th stats-table">CRIT</p>
                      {props.Stats.CRIT}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Exobot;
