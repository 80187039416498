/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import BuyBoxesTab from '../../components/BuyBoxesTab';
import Inventory from '../../components/Inventory';
import MyMarket from '../../components/MyMarket';
import Profile from '../../components/Profile';
import NotFound from '../NotFound';
import EmptyTopBanner from '../../sharedComponents/EmpyTopBanner/EmptyTopBanner';
import BottomBanner from '../../sharedComponents/BottomBanner';

const Dashboard = () => {
  const { pathname } = useLocation();
  if (
    pathname !== '/dashboard/account'
  && pathname !== '/dashboard/buyBoxes'
  && pathname !== '/dashboard/exobots'
  && pathname !== '/dashboard/market'
  ) return (<NotFound />);

  const { t } = useTranslation('common');

  const initialState = pathname || '/dashboard/account';

  const [activeTab, setActiveTab] = useState<string>(initialState);

  useEffect(() => {
    setActiveTab(pathname || '/dashboard/account');
  }, [pathname]);

  return (
    <>
      <EmptyTopBanner />
      <div className="blueprint">
        <div className="container pb-sm-5">
          <div className="page-name pb-5">
            <span className="avega white-txt display-1">{t('dashboard.title')}</span>
          </div>

          <div className="row align-items-center justify-content-center">
            <Tabs
              id="myTab2"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(String(k))}
              className="nav nav-tabs exo-tabs"
            >
              <Tab eventKey="/dashboard/account" title={t('profileDropdown.account')} className="nav-item">
                <Profile />
              </Tab>
              <Tab eventKey="/dashboard/exobots" title={t('profileDropdown.myExobots')} className="nav-item">
                <Inventory />
              </Tab>
              <Tab eventKey="/dashboard/market" title={t('profileDropdown.myMarket')} className="nav-item">
                <MyMarket />
              </Tab>
              <Tab eventKey="/dashboard/buyBoxes" title={t('profileDropdown.buyCapsules')} className="nav-item">
                <BuyBoxesTab />
              </Tab>
            </Tabs>
          </div>
        </div>
        <BottomBanner />
      </div>
    </>
  );
};

export default Dashboard;
