/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { BigNumber, ethers } from 'ethers';
import claim_abi from '../utils/abi/claim_abi';
import exos_abi from '../utils/abi/exos_abi';
import marketplace_abi from '../utils/abi/marketplace_abi';
import { claimAddress, exos, marketplaceAddress } from '../utils/constants';
import { nft } from '../utils/types';

declare let window: any;

const useBuyNFT = () => {
  const getSigner = async (): Promise<ethers.providers.JsonRpcSigner> => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    provider.send('eth_requestAccounts', []);
    const temporalSigner = await provider.getSigner();
    return temporalSigner;
  };

  const approveEXOS = async (price: number): Promise<ethers.providers.JsonRpcSigner | null> => {
    const temporalSigner = await getSigner();
    const allowExosContract = new ethers.Contract(exos, exos_abi, temporalSigner);
    try {
      const a = await allowExosContract.connect(temporalSigner).approve(marketplaceAddress, ethers.utils.parseEther(price.toString()));
      await a.wait(); // jejeje
      return temporalSigner;
    } catch (error) {
      return null;
    }
  };

  const buyNft = async ({
    id, owner, price, referralCode, sellFee, signature,
  }: nft, recipientWallet: string, signer: ethers.providers.JsonRpcSigner): Promise<string | null> => {
    const notificationId = `${owner.replace('0x', '')}_${recipientWallet.replace('0x', '')}_${id}`;

    if (signer !== null) {
      const nftContract = new ethers.Contract(marketplaceAddress, marketplace_abi, signer);

      try {
        const a = await nftContract.connect(signer).buy(id, price, sellFee, referralCode, ethers.utils.arrayify(signature), notificationId);
        await a.wait(); // jejeje
        return notificationId;
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  const claimNFT = async () => {
    try {
      const temporalSigner = await getSigner();
      const claimNFTContract = new ethers.Contract(claimAddress, claim_abi, temporalSigner);
      const contractResponse = await claimNFTContract.connect(temporalSigner).getNumberOfClaimableExobots();

      const numOfClaimablesNFTs = contractResponse.toNumber();

      if (numOfClaimablesNFTs <= 0) {
        return null;
      }

      const tx = await claimNFTContract.connect(temporalSigner).claimExobots();
      await tx.wait();

      const numOfClaimedExobots = await claimNFTContract.connect(temporalSigner).getClaimedExobots();
      const ids = numOfClaimedExobots.map((bigNumer: BigNumber) => bigNumer.toNumber());
      return ids;
    } catch (error) {
      return null;
    }
  };

  const buyCapsules = async (signer: ethers.providers.JsonRpcSigner, numCapsules: number) => {
    if (signer !== null) {
      try {
        const notificationId = `v${crypto.randomUUID()}`;
        const capsuleContract = new ethers.Contract(marketplaceAddress, marketplace_abi, signer);
        const a = await capsuleContract.connect(signer).buyBox(numCapsules, notificationId);
        await a.wait(); // jejeje
        return notificationId;
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  const buyExonites = async (signer: ethers.providers.JsonRpcSigner, numExonites: number) => {
    if (signer !== null) {
      try {
        const notificationId = `v${crypto.randomUUID()}`;
        const marketplaceContract = new ethers.Contract(marketplaceAddress, marketplace_abi, signer);
        const a = await marketplaceContract.connect(signer).buyExonite(numExonites, notificationId);
        await a.wait(); // jejeje
        return notificationId;
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  return {
    approveEXOS, buyNft, getSigner, claimNFT, buyCapsules, buyExonites,
  };
};

export default useBuyNFT;
