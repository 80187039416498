/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import { callWrapper } from '../../utils/api';
import AxiosClient from '../client';

type CheckResponse = {
  error: string
  result: 0 | 1 | 2
}

class Checks {
  /**
   * It takes an email address as a string,
   * and returns a promise that resolves to a CheckResponse object
   * @param {string} email - The email address to check.
   * @returns CheckResponse
   */
  static async checkEmail(email: string): Promise<CheckResponse> {
    return AxiosClient.post('/CheckEmail', {
      email,
    });
  }

  /**
   * It sends a POST request to the server with the nickname as the body,
   * and returns a promise that resolves to a CheckResponse object
   * @param {string} nickname - The nickname to check
   * @returns CheckResponse
   */
  static async checkNickname(nickname: string): Promise<CheckResponse> {
    return AxiosClient.post('/CheckNickname', {
      nickname,
    });
  }

  async checkExobotIsInUse(jwt: string | null, exobotId: number): Promise<AxiosResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
    return callWrapper(`/ExobotInUse?exobotId=${exobotId}`, config);
  }

  async getCapsulePriceInExos(): Promise<AxiosResponse> {
    return callWrapper('/BoxPrice', {});
  }

  async checkSellFee(jwt: string): Promise<AxiosResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
    return callWrapper('/SellFee', config);
  }
}

export default Checks;
