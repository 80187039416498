/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLocalStorage, LOCALSTORAGE_KEYS, setLocalStorage } from '../../../utils/localStorage';

type setNotificationsPayload = {
  notificationId: string
}

type deleteNotificationPayload = {
  notificationId: string
}
let initialState: Array<string>;

if (getLocalStorage(LOCALSTORAGE_KEYS.NOTIFICATIONS_IDS) === null) {
  setLocalStorage(LOCALSTORAGE_KEYS.NOTIFICATIONS_IDS, '[]');
  initialState = [];
} else {
  const a = getLocalStorage(LOCALSTORAGE_KEYS.NOTIFICATIONS_IDS) || '[]';
  initialState = JSON.parse(a);
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setNotification: (state: Array<string>, action: PayloadAction<setNotificationsPayload>) => {
      if (state === undefined) {
        state = [action.payload.notificationId];
      } else {
        state.push(action.payload.notificationId);
      }
      setLocalStorage(LOCALSTORAGE_KEYS.NOTIFICATIONS_IDS, JSON.stringify(state));
    },
    deleteNotification: (state: Array<string>, action:PayloadAction<deleteNotificationPayload>) => {
      const prev = getLocalStorage(LOCALSTORAGE_KEYS.NOTIFICATIONS_IDS);
      const prevState = JSON.parse(prev || '[]');
      const newState = prevState.filter((notificationId: string) => notificationId !== action.payload.notificationId);
      state = newState;
      setLocalStorage(LOCALSTORAGE_KEYS.NOTIFICATIONS_IDS, JSON.stringify(state));
    },
    clearNotifications: (state: Array<string>) => {
      const emptyState: any[] = [];
      state = emptyState;
      setLocalStorage(LOCALSTORAGE_KEYS.NOTIFICATIONS_IDS, JSON.stringify(state));
    },
  },
});

export const {
  deleteNotification, setNotification, clearNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
