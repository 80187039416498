/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Spinner from '../spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { API } from '../../utils/api';
import useBuyNFT from '../../hooks/use-buyNFT';
import { sleep } from '../../utils';
import { msOperationDelay } from '../../utils/constants';
import { AppDispatch } from '../../redux/store';
import { setNotification } from '../../redux/features/notifications/notificationsSlice';
import TransactionSteps from '../../components/TransactionSteps';
import { transactionStep } from '../../utils/types';

type BuyButtonProps = {
  ownerWallet: string,
  exoId: number,
  price: number,
}

const BuyButton = ({ ownerWallet, exoId, price }: BuyButtonProps) => {
  const {
    approveEXOS, buyNft,
  } = useBuyNFT();

  const dispatch: AppDispatch = useAppDispatch();

  const { t } = useTranslation('common');

  const [message, setMessage] = useState<string>('');

  const [show, setShow] = useState<boolean>(false);

  const isLogged: boolean = useAppSelector<boolean>((state: any) => state.user.isLogged);
  const recipientWallet: string = useAppSelector<string>((state: any) => state.user.wallet);
  const jwt: string = useAppSelector<string>((state: any) => state.user.token);

  const [firstStep, setFirstStep] = useState<transactionStep>({
    text: 'Approve marketplace to access my EXOS',
    loading: true,
    error: false,
    success: false,
    waiting: false,
  });
  const [secondStep, setSecondStep] = useState<transactionStep>({
    text: 'Receiving approve confirmation and purchase data.',
    loading: false,
    error: false,
    success: false,
    waiting: true,
  });
  const [thirdStep, setThirdStep] = useState<transactionStep>({
    text: 'Approve buy transaction.',
    loading: false,
    error: false,
    success: false,
    waiting: true,
  });

  const buy = async () => {
    setShow(true);
    const signer = await approveEXOS(price);

    if (signer !== null) {
      setFirstStep({
        ...firstStep,
        loading: false,
        success: true,
      });

      setSecondStep({
        ...secondStep,
        loading: true,
        waiting: false,
      });

      API.wallet.purchaseData(jwt, recipientWallet, exoId.toString())
        .then(async ({ data }) => {
          const ReferralCode = data.Result.ReferralCode || '';
          const SellFee = data.Result.SellFee || 0;
          const {
            Signature,
            Price,
          } = data.Result;
          await sleep(msOperationDelay);
          setSecondStep({
            ...secondStep,
            loading: false,
            success: true,
            waiting: false,
          });

          setThirdStep({
            ...thirdStep,
            loading: true,
            waiting: false,
          });
          const notificationId = await buyNft({
            id: exoId,
            owner: ownerWallet,
            price: Price,
            referralCode: ReferralCode,
            sellFee: SellFee,
            signature: Signature,
          }, recipientWallet, signer);
          if (notificationId !== null) {
            const payload = {
              notificationId,
            };
            dispatch(setNotification(payload));
            setThirdStep({
              ...thirdStep,
              loading: false,
              error: false,
              success: true,
              waiting: false,
            });
            setMessage('Success!! Check notifications.');
          } else {
            setThirdStep({
              ...thirdStep,
              loading: false,
              error: true,
              waiting: false,
            });
          }
        })
        .catch(() => {
          setSecondStep({
            ...secondStep,
            loading: false,
            error: true,
            waiting: false,
            success: false,
          });
        });
    } else {
      setFirstStep({
        ...firstStep,
        loading: false,
        error: true,
      });
    }
  };

  const handleReloadSteps = () => {
    setShow(false);
    setMessage('');
    setFirstStep({
      ...firstStep,
      loading: true,
      error: false,
      success: false,
      waiting: false,
    });
    setSecondStep({
      ...secondStep,
      loading: false,
      error: false,
      success: false,
      waiting: true,
    });
    setThirdStep({
      ...thirdStep,
      loading: false,
      error: false,
      success: false,
      waiting: true,
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-xlg btn-primary single-buy-btn"
        disabled={!isLogged}
        onClick={buy}
      >
        <span>BUY</span>

      </button>
      <Modal
        show={show}
        onHide={handleReloadSteps}
        backdrop="static"
        className="modal fade shadow"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-content-shadow" role="document">
          <div className="modal-content shadow">
            <Modal.Header className="modal-header">
              <h5 className="modal-title avega" id="exampleModalLongTitle">
                BUY EXOBOT #
                {exoId}
              </h5>
              <button type="button" className="close modal-close-btn" data-bs-dismiss="modal" onClick={handleReloadSteps} aria-label="Close">
                <span aria-hidden="true"><i className="fa fa-light fa-xmark" /></span>
              </button>
            </Modal.Header>
            <div className="modal-body" style={{ textAlign: 'initial' }}>
              <TransactionSteps steps={[firstStep, secondStep, thirdStep]} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleReloadSteps}>{t('exit')}</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BuyButton;
