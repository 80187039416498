/* eslint-disable camelcase */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_en from './translations/en/translations.json';
import common_es from './translations/es/translations.json';
import common_zh from './translations/zh/translations.json';
import { LOCALSTORAGE_KEYS, getLocalStorage } from './utils/localStorage';

const lang: string = getLocalStorage(LOCALSTORAGE_KEYS.LANG) || 'en';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: lang,
    debug: false,
    lng: lang,
    resources: {
      en: {
        common: common_en,
      },
      es: {
        common: common_es,
      },
      zh: {
        common: common_zh,
      },
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
