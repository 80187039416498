import React from 'react';
import { Link } from 'react-router-dom';

import img from '../../images/kat-404.png';
import EmptyTopBanner from '../../sharedComponents/EmpyTopBanner/EmptyTopBanner';

const NotFound = () => (
  <>
    <EmptyTopBanner />
    <div className="watercolor-top-blue" />
    <div className="blueprint">
      <div className="container pb-5 mb-2 mb-md-4 mt-3 white-txt">
        <div className="d-flex align-items-center justify-content-center mb-5">
          <div className="text-center row">
            <div className=" col-md-6 mb-md-4">
              <img
                src={img}
                alt=""
                className="img-fluid"
                loading="lazy"
              />
            </div>
            <div className=" col-md-6 mt-5">
              <h1 className="display-1 fw-bold avega yellow-txt">404</h1>
              <p className="fs-3 white-txt">
                {' '}
                <span className="pink-txt">Opps!</span>
                {' '}
                Page not found.
              </p>
              <p className="lead white-txt">
                The page you’re looking for doesn’t exist.
              </p>
              <Link to="/" className="btn btn-primary">Go Home</Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  </>
);

export default NotFound;
