/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteNotification } from '../../redux/features/notifications/notificationsSlice';
import { useAppDispatch } from '../../redux/hooks';
import { AppDispatch } from '../../redux/store';
import { URL_NOTIFICATIONS } from '../../utils/constants';

type detailProps = {
  id: string,
  type: string,
  update: any,
  handleReloadCapsules: any,
}

const NotificationDetail = ({
  id, type, update, handleReloadCapsules,
}: detailProps) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { t } = useTranslation('common');

  const dispatch: AppDispatch = useAppDispatch();

  const handleDismissNotification = () => {
    const payload = {
      notificationId: id,
    };
    dispatch(deleteNotification(payload));
    update();
  };

  useEffect(() => {
    axios.get(URL_NOTIFICATIONS + id)
      .then((res) => {
        setInProgress(false);
        setError(false);
        setSuccess(true);
      })
      .catch((errw) => {
        const a = setInterval(() => {
          axios.get(URL_NOTIFICATIONS + id)
            .then((res) => {
              setInProgress(false);
              setError(false);
              setSuccess(true);
              if (type === 'v') {
                handleReloadCapsules();
              }
              clearInterval(a);
            })
            .catch((err: any) => {
              if (err.message === 'Request failed with status code 404') {
                setInProgress(true);
                setError(false);
                setSuccess(false);
              } else {
                setInProgress(false);
                setError(true);
                setSuccess(false);
                clearInterval(a);
              }
            });
        }, 5000);
      });
  }, []);

  return (
    <div className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
      {success ? (
        <>
          <div className="toast-header">
            <strong className="me-auto">
              <span className="badge notification-completed">
                <i className="fa fa-solid fa-robot fs-6" />
              </span>
              {' '}
              {t('notifications.success')}
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={handleDismissNotification}
            />
          </div>
          <div className="toast-body">{t('notifications.completed')}</div>
        </>
      ) : null}

      {inProgress ? (
        <>
          <div className="toast-header">
            <strong className="me-auto">
              <span className="badge notification-in-process">
                <i className="fa fa-solid fa-robot fs-6" />
              </span>
              {' '}
              {t('notifications.inProgress')}
            </strong>
          </div>
          <div className="toast-body">
            {t('notifications.progressing')}
          </div>
        </>
      ) : null}

      {error ? (
        <>
          <div className="toast-header">
            <strong className="me-auto">
              <span className="badge notification-error">
                <i className="fa fa-solid fa-robot fs-6" />
              </span>
              {' '}
              {t('notifications.error')}
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={handleDismissNotification}
            />
          </div>
          <div className="toast-body">{t('notifications.errorMsg')}</div>
        </>
      ) : null}
    </div>
  );
};

export default NotificationDetail;
