/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clearNotifications } from '../../redux/features/notifications/notificationsSlice';
import { useAppDispatch } from '../../redux/hooks';
import { AppDispatch } from '../../redux/store';
import NotificationDetail from './NotificationDetail';

type NotificationProps = {
  notificationsIds : Array<string>,
  handleReloadCapsules: any,
}

const Notifications = ({ notificationsIds, handleReloadCapsules }: NotificationProps) => {
  const [numNotifications, setNumNotifications] = useState<number>(notificationsIds.length || 0);

  const dispatch: AppDispatch = useAppDispatch();

  const { t } = useTranslation('common');

  const updateNum = () => {
    const notifications = numNotifications - 1;
    setNumNotifications(notifications);
  };

  const handleClearAllNotifications = () => {
    dispatch(clearNotifications());
    setNumNotifications(0);
  };

  useEffect(() => {
    if (notificationsIds !== undefined) {
      setNumNotifications(notificationsIds.length);
    } else {
      setNumNotifications(0);
    }
  }, [notificationsIds]);

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-light position-relative"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNotifications"
        aria-controls="offcanvasWithBothOptions"
      >
        <i className="fa-solid fa-bell" />
        {numNotifications > 0 ? (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {numNotifications}
            <span className="visually-hidden">{t('notifications.unread')}</span>
          </span>
        ) : null}
      </button>

      <div
        className="offcanvas offcanvas-end notifications"
        data-bs-scroll="true"
        data-bs-backdrop="true"
        tabIndex={-1}
        id="offcanvasNotifications"
        aria-labelledby="offcanvasNotifications"
      >
        <div className="offcanvas-header">
          <h4 className="offcanvas-title" id="offcanvasScrollingLabel">{t('notifications.title')}</h4>
          <button type="button" className="btn btn-primary text-white" data-bs-dismiss="offcanvas" aria-label="Clear" onClick={handleClearAllNotifications}>Clear all</button>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body text-start">
          {numNotifications < 1 ? (
            <p>{t('notifications.none')}</p>
          ) : (
            <p>{t('notifications.new')}</p>
          )}
          <div className="toast-container position-static">
            {numNotifications > 0 ? (
              notificationsIds.map((notificationID: string) => (
                <NotificationDetail id={notificationID} key={notificationID} type={notificationID.charAt(0)} update={() => updateNum()} handleReloadCapsules={handleReloadCapsules} />
              ))
            ) : null}
          </div>
        </div>
      </div>

    </>
  );
};

export default Notifications;
