/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable no-promise-executor-return */
import { AxiosResponse } from 'axios';
import { startTransition } from 'react';
import { useNavigate } from 'react-router';
import { API } from './api';
import { LOCALSTORAGE_KEYS, getLocalStorage, removeFromLocalStorage } from './localStorage';

declare let window: any;
/**
 * It returns a promise that resolves after a given number of milliseconds.
 * @param {number} ms - number - The number of milliseconds to sleep.
 * @returns A promise that resolves after a set amount of time.
 */
const sleep = (ms: number): Promise<AxiosResponse> => new Promise<AxiosResponse>((resolve) => setTimeout(resolve, ms));

const useHandleLinkWithTransition = (tab: string) => {
  const navigate = useNavigate();
  startTransition(() => navigate(`/dashboard/${tab}`));
};

const containsOnlyNumbers = (str: string) => /^[0-9]+$/.test(str);

const validPrice = (price: number | undefined) => {
  if (price === undefined || price === 0) {
    return true;
  }
  return false;
};

const logout = () => {
  removeFromLocalStorage(LOCALSTORAGE_KEYS.JWT);
  removeFromLocalStorage(LOCALSTORAGE_KEYS.ACC);
  removeFromLocalStorage(LOCALSTORAGE_KEYS.EMAIL);
  removeFromLocalStorage(LOCALSTORAGE_KEYS.NICKNAME);
  removeFromLocalStorage(LOCALSTORAGE_KEYS.REFERRAL_CODE);
  removeFromLocalStorage(LOCALSTORAGE_KEYS.NOTIFICATIONS_IDS);
  window.location = '/';
};

const loginWithWallet = async () => {
  const accounts = await window?.ethereum.request({
    method: 'eth_requestAccounts',
  });
  const persistAcc = localStorage.getItem('userAcc');
  const account = accounts[0];
  if (persistAcc === account) {
    const persistJWT = localStorage.getItem('userJWT');
    const payload = {
      token: persistJWT,
    };
    return payload;
  }
  const nonce: any = await API.wallet.nonceWithWallet(account);
  const signature = await window?.ethereum.request({ method: 'personal_sign', params: [account, nonce.data.Result] })
    .catch((err: any) => console.log(err));
  console.log(signature);
  const a: any = await API.wallet.loginWithWallet(account, signature);
  const payload = {
    token: a.data.Result.Jwt,
  };
  return payload;
};

const paramsToObject = (searchParams: any) => {
  const entries = searchParams.entries();
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export {
  sleep,
  useHandleLinkWithTransition,
  containsOnlyNumbers,
  logout,
  loginWithWallet,
  validPrice,
  paramsToObject,
};
