/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALSTORAGE_KEYS, getLocalStorage, setLocalStorage } from '../../utils/localStorage';

const languages = ['en', 'es', 'zh'];

const SwitchLanguage = () => {
  const language = getLocalStorage(LOCALSTORAGE_KEYS.LANG) || 'en';

  const { i18n } = useTranslation('common');
  const [lng, setLng] = useState<string>(language);

  const handleChangeLang = (lang: string) => {
    setLocalStorage(LOCALSTORAGE_KEYS.LANG, lang);
    i18n.changeLanguage(lang);
    setLng(lang);
  };

  return (
    <div className="bg-dark language-bar">
      <div className="container d-flex flex-wrap justify-content-end">
        <div className="ml-3 text-nowrap">
          <div className="btn-group">
            <a href="/leaders-board?season=S01&name=standard-pvp_p2e" className="btn btn-link mx-3 text-decoration-none avega fa-fade">
              <i className="fa-sharp fa-solid fa-ranking-star white-txt" />
              &nbsp;&nbsp;LEADERBOARD
            </a>
            <div className="dropdown">
              <button
                className="btn btn-primary me-2 dropdown-toggle language-picker"
                type="button"
                id="dropLangTop"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-testid="active"
              >
                {lng.toUpperCase()}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropLangTop" style={{ zIndex: '999999999' }}>
                {languages.filter((lang) => lang !== language).map((lang: string) => (
                  <button key={lang} type="button" className="dropdown-item" onClick={() => handleChangeLang(lang)}>{lang.toUpperCase()}</button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchLanguage;
