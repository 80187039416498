/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

type IPaginationProps = {
    currentPage: number,
    numPages: number,
    setCurrentPage: any
}

const Pagination = ({ currentPage, numPages, setCurrentPage }: IPaginationProps) => {
  const renderNumPages = () => {
    const content: JSX.Element[] = [];
    for (let i = 1; i < numPages + 1; i += 1) {
      if (i === currentPage) {
        content.push(
          <li
            key={i}
            className="page-item"
          >
            <a
              type="button"
              className="page-link active"
              href="#listed"
              onClick={() => setCurrentPage(i)}
            >
              {i}
            </a>
          </li>,
        );
      } else {
        content.push(
          <li
            key={i}
            className="page-item"
          >
            <a
              type="button"
              className="page-link"
              href="#listed"
              onClick={() => setCurrentPage(i)}
            >
              {i}
            </a>
          </li>,
        );
      }
    }
    return content;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className="page-item">
          {currentPage <= 1 ? null : (
            <a type="button" className="page-link" aria-label="Previous" href="#listed" onClick={(e) => setCurrentPage(currentPage - 1)}>
              <span aria-hidden="true" className="avega">&laquo;</span>
            </a>
          )}
        </li>
        {renderNumPages()}
        <li className="page-item">
          {currentPage >= numPages ? null : (
            <a type="button" className="page-link" aria-label="Next" href="#listed" onClick={(e) => setCurrentPage(currentPage + 1)}>
              <span aria-hidden="true" className="avega">&raquo;</span>
            </a>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
