/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import Spinner from '../../sharedComponents/spinner';
import { API } from '../../utils/api';
import { FoundExobot, InventoryExobot } from '../../utils/types';
import NotFound from '../NotFound';
import ShowDetail from './ShowExobotDetail';
import ShowInventoryExobotDetail from './ShowExobotDetail/ShowInventoryExobotDetail';

interface ShowProps {
  isError: boolean,
  isLogged: boolean,
  exobot: any,
  isInventory: boolean,
  inventoryExobot: any,
  isOwner: boolean,
}

interface ShowInventoryProps {
  isLogged: boolean,
  exobot: any,
  isInventory: boolean,
  inventoryExobot: any,
  isOwner: boolean,
}

const ShowFromInventory = ({
  isInventory, isLogged, exobot, inventoryExobot, isOwner,
}: ShowInventoryProps) => (!isInventory ? <ShowDetail isOwner={isOwner} exobot={exobot} /> : <ShowInventoryExobotDetail exobot={inventoryExobot} />);

const Show = ({
  isError, isLogged, exobot, isInventory, inventoryExobot, isOwner,
}: ShowProps) => (isError ? <NotFound /> : <ShowFromInventory isOwner={isOwner} isInventory={isInventory} isLogged={isLogged} exobot={exobot} inventoryExobot={inventoryExobot} />);

interface IExobotDetailProps {
  fromInventory: boolean
}

const ExobotDetail = (props: IExobotDetailProps) => {
  const params = useParams();
  const { fromInventory } = props;
  if (params.id == null) return <NotFound />;

  const isLogged: boolean = useAppSelector<boolean>((state) => state.user.isLogged);
  const wallet: string = useAppSelector<string>((state) => state.user.wallet);
  const jwt: string | null = useAppSelector<string | null>((state) => state.user.token);
  const [exobot, setExobot] = useState<FoundExobot>();
  const [inventoryExobot, setInventoryExobot] = useState<InventoryExobot>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errLoading, setErrLoading] = useState<boolean>(false);
  const [isInventory, setisInventory] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const fetchFromInventory = async () => {
    try {
      const response = await API.search.singleSearchInventory(params.id, jwt);
      const { data } = response;
      setInventoryExobot(data.Result.DetailedInventoryExobot);
      setErrLoading(false);
      setisInventory(true);
    } catch (error: any) {
      // console.log(error);
      setErrLoading(true);
      setExobot(undefined);
    }
    setLoading(false);
  };

  const fetchSingleSearch = async () => {
    try {
      const response = await API.search.singleSearch(params.id);
      const { data } = response;
      setExobot(data.Result.DetailedFoundExobot);
      setErrLoading(false);
      if (data.Result.DetailedFoundExobot.Owner === wallet) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    } catch (error: any) {
      // console.log(error);
      setExobot(undefined);
      setErrLoading(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setExobot(undefined);
    setInventoryExobot(undefined);
    if (fromInventory) {
      fetchFromInventory();
    } else {
      fetchSingleSearch();
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="top-container city-header">
        <div className="top-banner container" />
      </div>
      <div className="watercolor-top-blue" />
      <div className="blueprint" id="detail">
        <div className="container pb-5 mb-2 mb-md-4 mt-3 white-txt">
          {isLoading ? <Spinner /> : <Show isInventory={isInventory} isOwner={isOwner} isError={errLoading} isLogged={isLogged} exobot={exobot} inventoryExobot={inventoryExobot} />}
        </div>
      </div>
    </>
  );
};

export default ExobotDetail;
