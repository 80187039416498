/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserState } from '../../../utils/types';
import { RootState } from '../../store';
import { LOCALSTORAGE_KEYS, setLocalStorage } from '../../../utils/localStorage';

type loginPayloadType = {
  token: string,
}

export type setPricePayload = {
  currentPrice: number,
}

type setCapsulesPriceInExosPayload = {
  capsulePriceInExos: number
}

type populateProfilePayloadType = {
  Nickname: string | null,
  Email: string,
  Wallet: string,
  ReferralCode: string | null,
}

type logPayloadType = {
  jwt: string | null,
  wallet: string,
  isLogged: boolean,
  email: string,
  nickname: string | null,
  referralCode: string | null,
}

// Define the initial state using that type
const initialState: IUserState = {
  token: null,
  isLogged: false,
  isCryptoUser: false,
  nickname: null,
  email: '',
  wallet: '',
  referralCode: null,
  currentPrice: 0,
  capsulePriceInExos: 0,
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    login: (state: IUserState, action: PayloadAction<loginPayloadType>) => {
      state.token = action.payload.token;
      state.isLogged = true;
      state.isCryptoUser = true;
      setLocalStorage(LOCALSTORAGE_KEYS.JWT, action.payload.token);
    },
    populateProfile: (state: IUserState, action: PayloadAction<populateProfilePayloadType>) => {
      state.nickname = action.payload.Nickname;
      state.email = action.payload.Email === null ? '' : action.payload.Email;
      state.wallet = action.payload.Wallet;
      state.referralCode = action.payload.ReferralCode;
      setLocalStorage(LOCALSTORAGE_KEYS.ACC, state.wallet);
      setLocalStorage(LOCALSTORAGE_KEYS.EMAIL, state.email);
      setLocalStorage(LOCALSTORAGE_KEYS.NICKNAME, state.nickname || '');
      setLocalStorage(LOCALSTORAGE_KEYS.REFERRAL_CODE, state.referralCode || '');
    },
    log: (state: IUserState, action: PayloadAction<logPayloadType>) => {
      state.isLogged = action.payload.isLogged;
      state.token = action.payload.jwt;
      state.wallet = action.payload.wallet;
      state.email = action.payload.email;
      state.nickname = action.payload.nickname;
      state.referralCode = action.payload.referralCode;
    },
    setCurrentPrice: (state: IUserState, action: PayloadAction<setPricePayload>) => {
      state.currentPrice = action.payload.currentPrice;
    },
    setCapsulePriceInExos: (state: IUserState, action: PayloadAction<setCapsulesPriceInExosPayload>) => {
      state.capsulePriceInExos = action.payload.capsulePriceInExos;
    },
  },
});

export const {
  login,
  populateProfile,
  log,
  setCurrentPrice,
  setCapsulePriceInExos,
} = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToken = (state: RootState) => state.user.token;

export default userSlice.reducer;
