import { useTransition } from 'react';
import { useNavigate } from 'react-router';

const useNavigateWithTransition = () => {
  const navigate = useNavigate();
  const [, startTransition] = useTransition();
  const setTab = (tab: string) => {
    startTransition(() => navigate(tab));
  };

  return [setTab] as const;
};

export default useNavigateWithTransition;
