import AuthenticationModule from './modules/authentication';
import ChecksModule from './modules/checks';
import SearchModule from './modules/search';
import WalletModule from './modules/wallet';

class APIWrapper {
  authentication: AuthenticationModule;

  checks: ChecksModule;

  search: SearchModule;

  wallet: WalletModule;

  constructor() {
    this.authentication = new AuthenticationModule();
    this.checks = new ChecksModule();
    this.search = new SearchModule();
    this.wallet = new WalletModule();
  }
}

export default APIWrapper;
