/* eslint-disable import/no-duplicates */
/* eslint-disable camelcase */

/* SUMRAK */
import aim from '../../../images/cards/aim.png';
import camouflage from '../../../images/cards/camouflage.png';
import deadly_accuracy from '../../../images/cards/deadly-accuracy.png';
import devastating_shot from '../../../images/cards/devastating-shot.png';
import energy_burst from '../../../images/cards/energy-burst.png';
import flash_grenade from '../../../images/cards/flash-grenade.png';
import lock_on_and_shoot from '../../../images/cards/lock-on-and-shoot.png';
import mark_prey from '../../../images/cards/mark-prey.png';
import overclock from '../../../images/cards/overclock.png';
import power_shot from '../../../images/cards/power-shot.png';
import safety_distance from '../../../images/cards/safety-distance.png';
import time_bomb from '../../../images/cards/time-bomb.png';

/* NETSHADOW */
import acid_blade from '../../../images/cards/acid-blade.png';
import extract from '../../../images/cards/extract.png';
import find_weakness from '../../../images/cards/find-weakness.png';
import hacking_blade from '../../../images/cards/hacking-blade.png';
import lethal_protocol from '../../../images/cards/lethal-protocol.png';
import mug from '../../../images/cards/mug.png';
import phase_out from '../../../images/cards/phase-out.png';
import pristine_laser from '../../../images/cards/pristine-laser.png';
import retreat from '../../../images/cards/retreat.png';
import smoke_screen from '../../../images/cards/smoke-screen.png';
import stalk from '../../../images/cards/stalk.png';
import thievery from '../../../images/cards/thievery.png';

/* GROUNDSMASHER */
import acid_rain from '../../../images/cards/acid-rain.png';
import aegis_protocol from '../../../images/cards/aegis-protocol.png';
import break_defenses from '../../../images/cards/break-defenses.png';
import bury from '../../../images/cards/bury.png';
import covered_attack from '../../../images/cards/covered-attack.png';
import earthquake from '../../../images/cards/earthquake.png';
import electromagnetic_field from '../../../images/cards/electromagnetic-field.png';
import gamma_ray from '../../../images/cards/gamma-ray.png';
import grab from '../../../images/cards/grab.png';
import gravel_storm from '../../../images/cards/gravel-storm.png';
import ground_slam from '../../../images/cards/ground-slam.png';
import iron_grip from '../../../images/cards/iron-grip.png';

/* DEATHBRINGER */
import anti_tank_calibre from '../../../images/cards/anti-tank-calibre.png';
import assault_protocol from '../../../images/cards/assault-protocol.png';
import barrage from '../../../images/cards/barrage.png';
import burning_gust from '../../../images/cards/burning-gust.png';
import call_arms from '../../../images/cards/call-to-arms.png';
import counter_measures from '../../../images/cards/countermeasures.png';
import devastating_blast from '../../../images/cards/devastating-blast.png';
import hunting_protocol from '../../../images/cards/hunting-protocol.png';
import killer_instinct from '../../../images/cards/killer-instinct.png';
import neutrons_ray from '../../../images/cards/neutrons-ray.png';
import payback from '../../../images/cards/payback.png';
import piercing_shots from '../../../images/cards/piercing-shots.png';

/* CODEBREAKER */
import beta_beam from '../../../images/cards/beta-beam.png';
import crippling_beam from '../../../images/cards/crippling-beam.png';
import cyber_infection from '../../../images/cards/cyber-infection.png';
import discharge from '../../../images/cards/discharge.png';
import energy_overload from '../../../images/cards/energy-overload.png';
import gravity_well from '../../../images/cards/gravity-well.png';
import hacker_protocol from '../../../images/cards/hacker-protocol.png';
import harmful_frequency from '../../../images/cards/harmful-frequency.png';
import reflect from '../../../images/cards/reflect.png';
import short_circuit from '../../../images/cards/short-circuit.png';
import spreading_fire from '../../../images/cards/spreading-fire.png';
import stock_up from '../../../images/cards/stock-up.png';

/* STRONGHOLD */
import draining_vortex from '../../../images/cards/draining-vortex.png';
import energy_wall from '../../../images/cards/energy-wall.png';
import guardian from '../../../images/cards/guardian.png';
import harmful_light from '../../../images/cards/harmful-light.png';
import laser_recoil from '../../../images/cards/laser-recoil.png';
import resistance_protocol from '../../../images/cards/resistance-protocol.png';
import scatter_beam from '../../../images/cards/scatter-beam.png';
import seismic_shift from '../../../images/cards/seismic-shift.png';
import self_repair from '../../../images/cards/self-repair.png';
import shield_strike from '../../../images/cards/shield-strike.png';
import solid_shield from '../../../images/cards/solid-shield.png';
import unbreakable_barrier from '../../../images/cards/unbreakable-barrier.png';

/* RENOVATOR */
import trample from '../../../images/cards/trample.png';
import nanobots_swarm from '../../../images/cards/nanobots-swarm.png';
import precision_laser from '../../../images/cards/precision-laser.png';
import energy_drain from '../../../images/cards/energy-drain.png';
import regenerate from '../../../images/cards/regenerate.png';
import renovate from '../../../images/cards/renovate.png';
import rebuild from '../../../images/cards/rebuild.png';
import surgical_laser from '../../../images/cards/surgical-laser.png';
import emergency_protocol from '../../../images/cards/emergency-protocol.png';
import cleanse from '../../../images/cards/cleanse.png';
import damage_transfer from '../../../images/cards/damage-transfer.png';
import gear_switch from '../../../images/cards/gear-switch.png';

/* SCRAPMASTER */
import armor from '../../../images/cards/armor.png';
import ballistic_missile from '../../../images/cards/ballistic-missile.png';
import comeback_protocol from '../../../images/cards/comeback-protocol.png';
import economize from '../../../images/cards/economize.png';
import fix from '../../../images/cards/fix.png';
import healing_beacon from '../../../images/cards/healing-beacon.png';
import incendiary_bomb from '../../../images/cards/incendiary-bomb.png';
import lava_geyser from '../../../images/cards/lava-geyser.png';
import particle_beam from '../../../images/cards/particle-beam.png';
import perfect_ammo from '../../../images/cards/perfect-ammo.png';
import shielding from '../../../images/cards/shielding.png';
import special_ammo from '../../../images/cards/special-ammo.png';

/* DATASHAPER */
import accelerating_attack from '../../../images/cards/accelerating-attack.png';
import break_down from '../../../images/cards/break-down.png';
import check_up from '../../../images/cards/check-up.png';
import contagious_damage from '../../../images/cards/contagious-damage.png';
import good_deal from '../../../images/cards/good-deal.png';
import improved_cleansing from '../../../images/cards/improved-cleansing.png';
import opportunity_attack from '../../../images/cards/opportunity-attack.png';
import penetrating_attack from '../../../images/cards/penetrating-attack.png';
import smart_healing from '../../../images/cards/smart-healing.png';
import sticky_bomb from '../../../images/cards/sticky-bomb.png';
import surprise_shot from '../../../images/cards/surprise-shot.png';
import team_attack from '../../../images/cards/team-attack.png';

const imgMap = new Map();

// STRONGHOLD
imgMap.set('draining-vortex', draining_vortex);
imgMap.set('energy-wall', energy_wall);
imgMap.set('guardian', guardian);
imgMap.set('laser-recoil', laser_recoil);
imgMap.set('harmful-light', harmful_light);
imgMap.set('resistance-protocol', resistance_protocol);
imgMap.set('scatter-beam', scatter_beam);
imgMap.set('self-repair', self_repair);
imgMap.set('shield-strike', shield_strike);
imgMap.set('solid-shield', solid_shield);
imgMap.set('seismic-shift', seismic_shift);
imgMap.set('unbreakable-barrier', unbreakable_barrier);

// SCRAPMASTER
imgMap.set('armor', armor);
imgMap.set('ballistic-missile', ballistic_missile);
imgMap.set('comeback-protocol', comeback_protocol);
imgMap.set('crippling-beam', crippling_beam);
imgMap.set('economize', economize);
imgMap.set('fix', fix);
imgMap.set('healing-beacon', healing_beacon);
imgMap.set('incendiary-bomb', incendiary_bomb);
imgMap.set('lava-geyser', lava_geyser);
imgMap.set('perfect-ammo', perfect_ammo);
imgMap.set('special-ammo', special_ammo);
imgMap.set('shielding', shielding);

// DATASHAPER
imgMap.set('accelerating-attack', accelerating_attack);
imgMap.set('break-down', break_down);
imgMap.set('check-up', check_up);
imgMap.set('contagious-damage', contagious_damage);
imgMap.set('good-deal', good_deal);
imgMap.set('improved-cleansing', improved_cleansing);
imgMap.set('opportunity-attack', opportunity_attack);
imgMap.set('penetrating-attack', penetrating_attack);
imgMap.set('smart-healing', smart_healing);
imgMap.set('sticky-bomb', sticky_bomb);
imgMap.set('surprise-shot', surprise_shot);
imgMap.set('team-attack', team_attack);

// RENOVATOR
imgMap.set('precision-laser', precision_laser);
imgMap.set('nanobots-swarm', nanobots_swarm);
imgMap.set('cleanse', cleanse);
imgMap.set('acid-blade', acid_blade);
imgMap.set('acid-rain', acid_rain);
imgMap.set('aegis-protocol', aegis_protocol);
imgMap.set('aim', aim);
imgMap.set('anti-tank-calibre', anti_tank_calibre);
imgMap.set('assault-protocol', assault_protocol);
imgMap.set('barrage', barrage);
imgMap.set('pristine-laser', pristine_laser);
imgMap.set('break-defenses', break_defenses);
imgMap.set('burning-gust', burning_gust);
imgMap.set('bury', bury);
imgMap.set('call-to-arms', call_arms);
imgMap.set('camouflage', camouflage);
imgMap.set('counter-measures', counter_measures);
imgMap.set('cyber-infection', cyber_infection);
imgMap.set('damage-transfer', damage_transfer);
imgMap.set('deadly-accuracy', deadly_accuracy);
imgMap.set('devastating-shot', devastating_shot);
imgMap.set('beta-beam', beta_beam);
imgMap.set('discharge', discharge);
imgMap.set('earthquake', earthquake);
imgMap.set('electromagnetic-field', electromagnetic_field);
imgMap.set('emergency-protocol', emergency_protocol);
imgMap.set('energy-burst', energy_burst);
imgMap.set('energy-drain', energy_drain);
imgMap.set('energy-overload', energy_overload);
imgMap.set('extract', extract);
imgMap.set('find-weakness', find_weakness);
imgMap.set('flash-grenade', flash_grenade);
imgMap.set('gamma-ray', gamma_ray);
imgMap.set('gear-switch', gear_switch);
imgMap.set('grab', grab);
imgMap.set('gravel-storm', gravel_storm);
imgMap.set('gravity-well', gravity_well);
imgMap.set('ground-slam', ground_slam);
imgMap.set('hacker-protocol', hacker_protocol);
imgMap.set('hacking-blade', hacking_blade);
imgMap.set('hunting-protocol', hunting_protocol);
imgMap.set('iron-grip', iron_grip);
imgMap.set('killer-instinct', killer_instinct);
imgMap.set('surgical-laser', surgical_laser);
imgMap.set('lethal-protocol', lethal_protocol);
imgMap.set('lock-on-and-shoot', lock_on_and_shoot);
imgMap.set('mark-prey', mark_prey);
imgMap.set('mug', mug);
imgMap.set('neutrons-ray', neutrons_ray);
imgMap.set('overclock', overclock);
imgMap.set('payback', payback);
imgMap.set('phase-out', phase_out);
imgMap.set('piercing-shots', piercing_shots);
imgMap.set('power-shot', power_shot);
imgMap.set('rebuild', rebuild);
imgMap.set('reflect', reflect);
imgMap.set('regenerate', regenerate);
imgMap.set('renovate', renovate);
imgMap.set('particle-beam', particle_beam);
imgMap.set('retreat', retreat);
imgMap.set('safety-distance', safety_distance);
imgMap.set('short-circuit', short_circuit);
imgMap.set('smoke-screen', smoke_screen);
imgMap.set('spreading-fire', spreading_fire);
imgMap.set('stalk', stalk);
imgMap.set('stock-up', stock_up);
imgMap.set('thievery', thievery);
imgMap.set('time-bomb', time_bomb);
imgMap.set('devastating-blast', devastating_blast);
imgMap.set('trample', trample);
imgMap.set('covered-attack', covered_attack);
imgMap.set('harmful-frequency', harmful_frequency);

const getImg = (id: string) => imgMap.get(id);

export default getImg;
