import React from 'react';
import { Link } from 'react-router-dom';
import miniCapsules from '../../images/mini-banner-capsules.png';
import miniExonites from '../../images/mini-banner-exonites.png';
import miniExos from '../../images/mini-banner-exos.png';

const BottomBanner = () => (
  <div className="container mb-6 pb-5 mt-5">
    <div className="row align-items-center gx-5">
      <div className="col-4 col-lg-4 col-md-4 zoom2 mt-5">
        <Link className="fs-4 mb-4 mt-4 " to="/capsules">
          <img draggable="false" src={miniCapsules} className="img-fluid" alt="..." />
        </Link>
      </div>
      <div className="col-4 col-lg-4 col-md-4 zoom2 mt-5">
        <Link className="fs-4 mb-4 mt-4" to="/exonite">
          <img draggable="false" src={miniExonites} className="img-fluid" alt="..." />
        </Link>
      </div>
      <div className="col-4 col-lg-4 col-md-4 zoom2 mt-5">
        <a target="_blank" href="https://shorturl.at/pMZ12" className="fs-4 mb-4 mt-4" rel="noreferrer">
          <img draggable="false" src={miniExos} className="img-fluid" alt="..." />
        </a>
      </div>
    </div>
  </div>
);

export default BottomBanner;
