/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import { callWrapper, getConfig } from '../../utils/api';

class Search {
/**
 * It takes in three parameters, and returns a promise that resolves to the result of an HTTP POST
 * request.
 * @param {number} pageSize - number of items to return
 * @param {string} sortBy - The field to sort by.
 * @param {number} pageNumber - The page number to return.
 * @returns An AxiosPromise&lt;T&gt;
 */
  async search(
    pageSize:string,
    sortBy:string,
    pageNumber:string,
    cortex?: string,
    helmet?: string,
    rightGear?: string,
    leftGear?: string,
    kinetics?: string,
    frontek?: string,
    maxVIT?: string,
    maxHP?: string,
    maxARM?: string,
    maxDEF?: string,
    maxPOT?: string,
    maxAP?: string,
    maxAGI?: string,
    maxSPD?: string,
    maxACC?: string,
    maxCRIT?: string,
    slots?: string,
  ) {
    const url = `/Search?pageSize=${pageSize}&sort=${sortBy}&pageNumber=${pageNumber}&level=0&level=${slots || 6}`;
    const urlWithAtts = `&vit=0&vit=${maxVIT}&arm=0&arm=${maxARM}&pot=0&pot=${maxPOT}&agi=0&agi=${maxAGI}&acc=0&acc=${maxACC}`;
    const urlWithStats = `${url}&hp=0&hp=${maxHP}&def=0&def=${maxDEF}&ap=0&ap=${maxAP}&spd=0&spd=${maxSPD}&crit=0&crit=${maxCRIT}${urlWithAtts}`;
    const useURL = maxHP !== undefined ? urlWithStats : url;

    const urlWithCortex = cortex !== '' ? `${useURL}&part=${cortex}` : useURL;
    const urlWithHelmet = helmet !== '' ? `${urlWithCortex}&part=${helmet}` : urlWithCortex;
    const urlWithLeftGear = leftGear !== '' ? `${urlWithHelmet}&part=${leftGear}` : urlWithHelmet;
    const urlWithRightGear = rightGear !== '' ? `${urlWithLeftGear}&part=${rightGear}` : urlWithLeftGear;
    const urlWithfrontek = frontek !== '' ? `${urlWithRightGear}&part=${frontek}` : urlWithRightGear;
    const urlWithKinetics = kinetics !== '' ? `${urlWithfrontek}&part=${kinetics}` : urlWithfrontek;
    return callWrapper(urlWithKinetics, {});
  }

  async singleSearch(exoId: string | undefined) {
    return callWrapper(`/Exobot/${exoId}`, {});
  }

  async singleSearchInventory(exoId: string | undefined, jwt: string | null): Promise<any> {
    return callWrapper(`/Inventory/${exoId}`, getConfig(jwt));
  }

  async myInventory(jwt: string | null): Promise<any> {
    return callWrapper('/Inventory', getConfig(jwt));
  }

  async myMarket(jwt: string | null): Promise<any> {
    return callWrapper('/MyMarket', getConfig(jwt));
  }

  async checkExobotIsInUse(jwt: string, exobotId: string): Promise<any> {
    return callWrapper(`/ExobotInUse/exobotId=${exobotId}`, getConfig(jwt));
  }

  async checkSellFee(jwt: string): Promise<any> {
    return callWrapper('/SellFee', getConfig(jwt));
  }

  async getNumCapsules(jwt: string): Promise<any> {
    return callWrapper('/NumBoxes', getConfig(jwt));
  }

  async getPlayerMarket(wallet: string): Promise<any> {
    return callWrapper(`/PlayerMarket?wallet=${wallet}`, {});
  }

  async getLeaderboardSummary(season: string, name: string): Promise<any> {
    return callWrapper(`/Leaderboard?season=${season}&name=${name}`, {});
  }
}

export default Search;
