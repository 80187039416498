/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { logout } from '.';
import APIWrapper from '../api';
import AxiosClient from '../api/client';

export const API: APIWrapper = new APIWrapper();

export const getConfig = (jwt: string | null) => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  return config;
};

export const callWrapper = async (url:string, config: any, params?: any, method:string = 'GET') => {
  try {
    let response;
    switch (method) {
      case 'GET':
        response = await AxiosClient.get(url, config);
        break;
      case 'POST':
        response = await AxiosClient.post(url, params, config);
        break;
      default:
        response = await AxiosClient.get(url, config);
        break;
    }
    return response;
  } catch (error: any) {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  }
};
