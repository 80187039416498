/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import useNavigateWithTransition from '../../hooks/use-navigateWithTransition';

interface ToastProps {
  text: string,
  redirectButton: boolean,
  showCloseButton: boolean,
}

const ToastMessage = ({ text, redirectButton, showCloseButton }: ToastProps) => {
  const [setTab] = useNavigateWithTransition();
  return (
    <div className="toast fade show position-absolute" role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-body">
        {text}
        <div className="mt-2 pt-2 border-top">
          {redirectButton ? (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => setTab('dashboard/account')}
            >
              Link email
            </button>
          ) : null}
          {showCloseButton ? (
            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="toast">Close</button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ToastMessage;
