/* eslint-disable no-unused-vars */
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Capsule from '../../components/Capsule';
import img from './merge-banner.png';
import bannerImg from './exobots-cards-army.png';
import EmptyTopBanner from '../../sharedComponents/EmpyTopBanner/EmptyTopBanner';
import BottomBanner from '../../sharedComponents/BottomBanner';

const CapsulesScreen = () => {
  const { t } = useTranslation('common');
  const navigate: NavigateFunction = useNavigate();

  const handleNavigateToHome = () => {
    navigate('/');
  };
  return (
    <>
      <EmptyTopBanner />
      <div className="blueprint">
        <div className="container top-banner mt-n3">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-7 col-md-6">
              <div className="banner-title">
                <span className="avega white-txt display-2 lh-sm">{t('capsules.text1')}</span>
                <br />
                <span className="avega yellow-txt display-2 lh-sm">{t('capsules.text2')}</span>
                <br />
                <span className="clint purple-txt display-2 lh-sm">{t('capsules.text3')}</span>
              </div>

            </div>
            <div className="col-xl-6 col-lg-5 col-md-6 text-lg-end">
              <img src={img} className="img-fluid" alt="banner" />
            </div>
          </div>
        </div>
        <div className="container mb-sm-4">

          <div className="row pt-3 mx-n2">

            <Capsule numCapsules={1} price={500} />
            <Capsule numCapsules={5} price={2375} />
            <Capsule numCapsules={10} price={4500} />

          </div>
        </div>
        <div className="container mb-6 pb-3">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="banner-title  ls-sm">
                <span className="avega yellow-txt display-5 mb-1 lh-sm">{t('capsules.text4')}</span>
                <br />
                <span className="avega white-txt display-5 mb-1 lh-sm">{t('capsules.text5')}</span>
                <br />
                <span className="clint purple-txt display-5 mb-1 lh-sm">{t('capsules.text6')}</span>
                <button
                  className="btn btn-xxlg btn-outline-light fs-4 mb-4 mt-4"
                  type="button"
                  onClick={handleNavigateToHome}
                >
                  {t('capsules.text7')}
                </button>
              </div>

            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-lg-end">
              <img src={bannerImg} className="img-fluid" alt="banner" />

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default CapsulesScreen;
