/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import AxiosClient from '../client';

import {
  ILoginRequest,
  IRegisterRequest,
  IResetPass1Request,
  IResetPass2Request,
  IValidateRequest,
} from '../../utils/types';
import { callWrapper, getConfig } from '../../utils/api';

// TODO change normal functions to arrow functions for better performance
class Authentication {
  /**
   * It sends a POST request to the server with the body of the request being the body parameter.
   * @param {IRegisterRequest} body - IRegisterRequest
   * @returns AxiosResponse
   */
  async register({
    email, password, password2, nickname,
  }: IRegisterRequest): Promise<AxiosResponse> {
    return AxiosClient.post('/NewUserStep1', {
      email,
      password,
      password2,
      nickname,
    });
  }

  /**
  * It takes an object of type ILoginRequest, and returns a promise of type AxiosResponse
  * @param {ILoginRequest} body - ILoginRequest
  * @returns AxiosResponse
  */
  login({ input, password }: ILoginRequest): any {
    return callWrapper('/Login', {}, {
      input,
      password,
    }, 'POST');
  }

  /**
   * It takes a token, and sends it to the server.
   *
   * The server then validates the token, and returns a response.
   *
   * The response is then returned to the caller.
   * @param {string} token - The token that was sent to the user's email address.
   * @returns The response from the server.
   */
  async validateUser(body: IValidateRequest): Promise<AxiosResponse> {
    return callWrapper('/NewUserStep2', {}, body, 'POST');
  }

  /**
  * This function sends a POST request to the server with the email address of the user who wants to
  * reset their password.
  * @param {IResetPass1Request}  - IResetPassRequest is an interface that has the following:
  * @returns AxiosResponse
  */
  async resetPasswordStep1({ input }: IResetPass1Request): Promise<AxiosResponse> {
    return AxiosClient.post('/ResetPassStep1', { input });
  }

  /**
 * "This function takes an object with three properties and sends it to the server."
 *
 * The function is called like this:
 * @param {IResetPass2Request}  - IResetPass2Request
 * @returns AxiosResponse
 */
  async resetPasswordStep2({ password, password2, token }: IResetPass2Request): Promise<AxiosResponse> {
    return AxiosClient.post('/ResetPassStep2', {
      password, password2, token,
    });
  }

  async populateProfile(jwt: string | null): Promise<any> {
    return callWrapper('/Profile', getConfig(jwt));
  }

  async vinculationStep1(jwt: string | null, Email: string | undefined): Promise<any> {
    return callWrapper('/VinculationStep1', getConfig(jwt), {
      Email,
    }, 'POST');
  }

  async vinculationStep2(jwt: string | null, Email: string | undefined, ChallengeCode: string | undefined): Promise<any> {
    return callWrapper('/VinculationStep2', getConfig(jwt), {
      Email,
      ChallengeCode,
    }, 'POST');
  }

  async updateProfile(jwt: string | null, nickname:string | null, ReferralCode: string | null): Promise<AxiosResponse> {
    return callWrapper('/UpdateProfile', getConfig(jwt), {
      ReferralCode,
      nickname,
    }, 'POST');
  }
}

export default Authentication;
