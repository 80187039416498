import React from 'react';

const EmptyTopBanner = () => (
  <>
    <div className="top-container city-header">
      <div className="top-banner container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-l-6 col-md-6 col-sm-12" />
          <div className="col-xl-6 col-lg-6 col-md-6" />

        </div>
      </div>
    </div>
    <div className="watercolor-top-blue" />
  </>
);

export default EmptyTopBanner;
