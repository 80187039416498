/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import FiltersBar from './FiltersBar';
import FiltersExpanded from './FiltersExpanded';

type FilterProps = {
  resultsPerPage: any,
  setResultsPerPage: any,
  sortById: any,
  setSortBy: any,
  setCortex: any,
  setFrontek: any,
  setHelmet: any,
  setKinetics: any,
  setRightGear: any,
  setLeftGear: any,
  applyFilters: any,
  setMaxVIT: any,
  setMaxHP: any,
  setMaxARM: any,
  setMaxDEF: any,
  setMaxPOT: any,
  setMaxAP: any,
  setMaxAGI: any,
  setMaxSPD: any,
  setMaxACC: any,
  setMaxCRIT: any,
  setSlots: any,
}

const Filters = ({
  resultsPerPage,
  setResultsPerPage,
  sortById,
  setSortBy,
  setCortex,
  setHelmet,
  setFrontek,
  setKinetics,
  setRightGear,
  setLeftGear,
  applyFilters,
  setMaxVIT,
  setMaxHP,
  setMaxARM,
  setMaxDEF,
  setMaxPOT,
  setMaxAP,
  setMaxAGI,
  setMaxSPD,
  setMaxACC,
  setMaxCRIT,
  setSlots,
}: FilterProps) => (
  <div className="filters-bar py-4 mt-n5 mb-4">
    <FiltersBar
      setSortBy={setSortBy}
      resultsPerPage={resultsPerPage}
      setResultsPerPage={setResultsPerPage}
    />
    <FiltersExpanded
      sortById={sortById}
      setCortex={setCortex}
      setFrontek={setFrontek}
      setHelmet={setHelmet}
      setKinetics={setKinetics}
      setRightGear={setRightGear}
      setLeftGear={setLeftGear}
      applyFilters={applyFilters}
      setMaxVIT={setMaxVIT}
      setMaxHP={setMaxHP}
      setMaxARM={setMaxARM}
      setMaxDEF={setMaxDEF}
      setMaxPOT={setMaxPOT}
      setMaxAP={setMaxAP}
      setMaxAGI={setMaxAGI}
      setMaxSPD={setMaxSPD}
      setMaxACC={setMaxACC}
      setMaxCRIT={setMaxCRIT}
      setSlots={setSlots}
    />
  </div>
);

export default Filters;
