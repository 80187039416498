/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import exoniteOption1 from './exonite-bundle-01.png';
import exoniteOption2 from './exonite-bundle-02.png';
import exoniteOption3 from './exonite-bundle-03.png';
import exoniteOption4 from './exonite-bundle-04.png';
import exoniteOption5 from './exonite-bundle-05.png';
import { useAppSelector } from '../../redux/hooks';
import BuyExonites from '../../sharedComponents/buyExonites';

type IExoniteProps = {
  numExonites: number,
  price: number
}

const Exonite = ({ numExonites, price }: IExoniteProps) => {
  const { t } = useTranslation('common');
  const [img, setImg] = useState<string>('');
  const [text, setText] = useState<string>('');

  const currentPrice: number = useAppSelector<number>((state) => state.user.currentPrice);

  useEffect(() => {
    switch (numExonites) {
      case 20:
        setImg(exoniteOption1);
        setText(t('exonite.option1'));
        break;

      case 55:
        setImg(exoniteOption2);
        setText(t('exonite.option2'));
        break;

      case 220:
        setImg(exoniteOption3);
        setText(t('exonite.option3'));
        break;
      case 500:
        setImg(exoniteOption4);
        setText(t('exonite.option4'));
        break;

      case 1355:
        setImg(exoniteOption5);
        setText(t('exonite.option5'));
        break;

      default:
        setImg(exoniteOption1);
        setText(t('exonite.option1'));
        break;
    }
  }, []);

  return (
    <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="card product-card capsule-box zoom">
        <div className="container top-card-info top-capsule">
          <div className="row">
            <div className="col white exo-info p-2 text-center">
              <h3 className="avega info-card-value yellow-txt p-3">
                {text}
                {' '}
                {t('exonite.exonite')}
              </h3>
            </div>

          </div>
        </div>

        <div className="pt-4">
          <h1 className="text-center product-price mb-md-0">
            <b>
              {price}
              {' '}
            </b>
            <span className="fs-6">EXOS</span>
          </h1>
          <p className="text-center product-price-dollar mb-md-0">
            {(currentPrice * price).toLocaleString()}
            <span className="fs-6"> BNB</span>
          </p>
          <img
            className="img-fluid"
            src={img}
            alt="Product"
          />
          <div className="d-grid gap-2 col-6 mx-auto pb-3">
            <BuyExonites numExonites={numExonites} price={price} />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Exonite;
