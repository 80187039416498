import React from 'react';
import Capsule from '../Capsule';

const BuyBoxesTab = () => (
  <div className="container mb-sm-4">

    <div className="row pt-3 mx-n2">

      <Capsule numCapsules={1} price={500} />
      <Capsule numCapsules={5} price={2375} />
      <Capsule numCapsules={10} price={4500} />

    </div>
  </div>
);

export default BuyBoxesTab;
