/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks';
import { API } from '../../utils/api';
import Spinner from '../spinner';

declare let window: any;

const RemoveFromSaleButton = (exoId: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const jwt: string = useAppSelector<string>((state: any) => state.user.token);

  const { t } = useTranslation('common');

  const handleRemoveFromSale = async () => {
    setLoading(true);

    const response = await API.wallet.removeFromSale(exoId?.exoId, jwt);

    if (response?.status === 200) {
      setLoading(false);
      setSuccess(true);
      window.location = '/dashboard/exobots';
    } else {
      setSuccess(false);
      setLoading(false);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-xxlg btn-primary red-btn"
        data-bs-toggle="modal"
        data-bs-target="#unlist-exobot"
      >
        <span>
          <i className="fa fa-solid fa-xmark" />
          {' '}
          {t('unlist.title')}
          {' '}
        </span>
      </button>
      <div
        className="modal fade shadow"
        id="unlist-exobot"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="reset-password"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered text-black" role="document">
          <div className="modal-content shadow">
            <div className="modal-header">
              <h5 className="modal-title avega" id="exampleModalLongTitle">{t('unlist.sure')}</h5>
              <button
                type="button"
                className="close modal-close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true"><i className="fa fa-light fa-xmark" /></span>
              </button>
            </div>
            <div className="modal-body">
              {loading ? <Spinner /> : (
                success ? (<p>{t('successfulOperation')}</p>) : (
                  <p>
                    {t('unlist.msg1')}
                  </p>
                )
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('cancel')}</button>
              <button type="button" className="btn btn-primary" disabled={loading} onClick={handleRemoveFromSale}>{t('unlist.title')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveFromSaleButton;
