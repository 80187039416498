/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import SwitchLanguage from './components/SwitchLanguage';
import {
  log, login, populateProfile, setCurrentPrice, setPricePayload,
} from './redux/features/user/userSlice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { AppDispatch } from './redux/store';
import CapsulesScreen from './screens/CapsulesScreen';
import ExoniteScreen from './screens/ExoniteScreen';
import ClaimScreen from './screens/ClaimScreen/ClaimScreen';
import CookiesAndPrivacy from './screens/Cookies';
import Dashboard from './screens/Dashboard';
import ExobotDetail from './screens/ExobotDetail';
import Home from './screens/Home';
import LeadersBoard from './screens/LeadersBoard';
import NotFound from './screens/NotFound';
import SharedMarket from './screens/SharedMarket';
import Footer from './sharedComponents/footer';
import Header from './sharedComponents/header';
import { loginWithWallet, logout } from './utils';
import { API } from './utils/api';
import { API_COINGEKO_URL, ROUTE_PATHS } from './utils/constants';
import { LOCALSTORAGE_KEYS, getLocalStorage } from './utils/localStorage';

declare let window: any;

const App = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const isLogged: boolean = useAppSelector<boolean>((state: any) => state.user.isLogged);
  const persistJWT = getLocalStorage(LOCALSTORAGE_KEYS.JWT);
  const persistAcc = getLocalStorage(LOCALSTORAGE_KEYS.ACC);
  const persistEMail: string = getLocalStorage(LOCALSTORAGE_KEYS.EMAIL) || '';
  const persistNickname: string = getLocalStorage(LOCALSTORAGE_KEYS.NICKNAME) || '';
  const persistReferralCode: string = getLocalStorage(LOCALSTORAGE_KEYS.REFERRAL_CODE) || '';

  const reloadFromAnotherWallet = async () => {
    try {
      const payload = await loginWithWallet();
      dispatch(login(payload));
      const { data } = await API.authentication.populateProfile(payload.token);
      dispatch(populateProfile(data.Result.PlayerProfile));
      window.location.reload();
    } catch (error) {
      // console.error(error);
    }
  };

  const checkUnlock = async () => {
    const isUnlocked = await window?.ethereum?._metamask?.isUnlocked();
    if (!isUnlocked && persistAcc) {
      logout();
    }
  };

  const fetchBNBCurrentPrice = async () => {
    const payload: setPricePayload = {
      currentPrice: 0,
    };
    try {
      const response = await fetch(API_COINGEKO_URL);
      const result: any = await response.json();
      payload.currentPrice = result[0].current_price;
    } catch (error: any) {
      // console.error(error);
    } finally {
      dispatch(setCurrentPrice(payload));
    }
  };

  useEffect(() => {
    checkUnlock();
    fetchBNBCurrentPrice();
    if (window?.ethereum) {
      window?.ethereum?.on('accountsChanged', () => {
        reloadFromAnotherWallet();
      });
    }
    /** API.checks.getCapsulePriceInExos()
      .then(({ data }) => {
        const { BoxPrice } = data.Result;
        const payload = {
          capsulePriceInExos: BoxPrice,
        };
        dispatch(setCapsulePriceInExos(payload));
      }); */
  }, []);

  if (persistAcc && persistJWT) {
    const payload = {
      isLogged: true,
      jwt: persistJWT,
      wallet: persistAcc,
      email: persistEMail,
      nickname: persistNickname,
      referralCode: persistReferralCode,
    };
    dispatch(log(payload));
  }
  return (
    <BrowserRouter>
      <Header isLoggedIn={isLogged} />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route
            path={ROUTE_PATHS.DashboardTab}
            element={(
              <ProtectedRoute isLoggedIn={isLogged}>
                <Dashboard />
              </ProtectedRoute>
          )}
          />
          <Route path={ROUTE_PATHS.Inventory} element={(<ExobotDetail fromInventory />)} />
          <Route path={ROUTE_PATHS.PrivacyPolicy} element={(<CookiesAndPrivacy />)} />
          <Route path={ROUTE_PATHS.TermsAndConditions} element={(<CookiesAndPrivacy />)} />
          <Route path={ROUTE_PATHS.LeadersBoard} element={<LeadersBoard />} />
          <Route path={ROUTE_PATHS.Claim} element={<ClaimScreen />} />
          <Route
            path={ROUTE_PATHS.DashboardTab}
            element={(
              <ProtectedRoute isLoggedIn={isLogged}>
                <Dashboard />
              </ProtectedRoute>
          )}
          />
          <Route path={ROUTE_PATHS.Exobot} element={(<ExobotDetail fromInventory={false} />)} />
          <Route path={ROUTE_PATHS.PrivacyPolicy} element={(<CookiesAndPrivacy />)} />
          <Route path={ROUTE_PATHS.SharedMarket} element={(<SharedMarket />)} />
          <Route path={ROUTE_PATHS.Capsules} element={(<CapsulesScreen />)} />
          <Route path={ROUTE_PATHS.Exonite} element={(<ExoniteScreen />)} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <div className="top-footer" />
      <Footer />
      <SwitchLanguage />
    </BrowserRouter>
  );
};
export default App;
