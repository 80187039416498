/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import NotFound from '../NotFound';
import Spinner from '../../sharedComponents/spinner';
import { API } from '../../utils/api';
import img from '../../images/play-earn-img-banner.png';
import BottomBanner from '../../sharedComponents/BottomBanner';

const LeadersBoard = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const season = queryParams.get('season');
  const name = queryParams.get('name');
  if (season == null) return <NotFound />;
  if (name == null) return <NotFound />;
  const [leaderBoardsEntries, setLeadersBoardEntries] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    API.search.getLeaderboardSummary(season || '', name || '')
      .then((res: any) => {
        setIsLoading(false);
        const validEntries = [];
        for (const entry of res.data.Result.LeaderboardSummary.Top) {
          validEntries.push({
            nickname: entry.Nickname,
            score_1: entry.Scores[0],
          });
        }
        setLeadersBoardEntries(validEntries);
      });
  }, []);

  return (
    <>
      <div className="top-container lights-header">
        <div className="container top-banner">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-5 col-md-5">
              <div className="banner-title ls-sm">
                <span className="avega yellow-txt fs-3 mb-1 lh-sm">
                  <span className="avegaol">
                    PLAY & EARN
                  </span>
                  <span className="white-txt">
                    /
                  </span>
                  SEASON 1
                </span>
                <br />
                <span className="avega white-txt display-2 mb-0 lh-sm ls-xsm">
                  LEADERBOARD
                </span>
                <br />
                <span className="clint blue-txt display-4 mb-1 lh-xsm">
                  FIGHT YOUR WAY UP & WIN
                  <br />
                </span>
                <span className="white-txt fs-6 mt-3 lh-sm">
                  <br />
                  Open until October 17
                  <sup>
                    th
                  </sup>
                  <br />
                </span>
              </div>
              <button type="button" className="btn btn-lg btn-outline-light fs-5 mb-4 mt-4 avega" data-bs-toggle="modal" data-bs-target="#exampleModal">
                VIEW RULES
              </button>
              <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5 avega" id="rulesModalLabel">REQUIREMENTS & RULES</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                      <p className="mt-3">
                        You will need to&nbsp;
                        <b>
                          play with 3 Digital Collectible Exobots in the squad in order to rank
                        </b>
                        &nbsp;in this league.
                        <b className="pink-txt">
                          &nbsp;Non-NFT Exobots are not eligible.&nbsp;
                        </b>
                        They must be 3, if you place only two you won&apos;t rank either, so make sure your Exobots are in your squad.
                      </p>
                      <p className="mt-3">
                        When you score in the P&E ranking
                        <b>
                          &nbsp;you also score in the traditional ranking&nbsp;
                        </b>
                        of the application.
                      </p>
                      <p className="mt-3">
                        Only the positions in the
                        <b>
                          &nbsp;P&E ranking hosted in our marketplace count&nbsp;
                        </b>
                        and not in the game.
                      </p>
                      <p className="mt-3">
                        A total of
                        <b>
                          &nbsp;$3,000 in $EXOS&nbsp;
                        </b>
                        will be distributed as of the distribution date:
                      </p>
                      <ul className="list-group">
                        <li className="list-group-item">
                          <b className="avega px-1 fs-5">
                            1
                            <sup>
                              st
                            </sup>
                          </b>
                          $2,000 in $EXOS
                        </li>
                        <li className="list-group-item">
                          <b className="avega px-1 fs-5">
                            2
                            <sup>
                              nd
                            </sup>
                          </b>
                          $500 in $EXOS
                        </li>
                        <li className="list-group-item">
                          <b className="avega px-1 fs-5">
                            3
                            <sup>
                              rd
                            </sup>
                          </b>
                          $250 in $EXOS
                        </li>
                        <li className="list-group-item">
                          <b className="avega px-1 fs-5">
                            4
                            <sup>
                              rd
                            </sup>
                          </b>
                          $150 in $EXOS
                        </li>
                        <li className="list-group-item">
                          <b className="avega px-1 fs-5">
                            5
                            <sup>
                              th
                            </sup>
                          </b>
                          $100 in $EXOS
                        </li>
                      </ul>
                      <p className="mt-3">
                        <b>
                          &nbsp;Prize distribution&nbsp;
                        </b>
                        will occur 15 to 25 days after the end of the event.
                      </p>
                      <p className="mt-3">
                        Players who take advantage of exploits
                        <b className="pink-txt">
                          &nbsp;will be removed from the ranking and banned.
                        </b>
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7 text-lg-end">
              <img src={img} className="img-fluid" alt="banner" />
            </div>
          </div>
        </div>
      </div>
      <div className="watercolor-top-blue" />
      <div className="blueprint">
        <div className="container mb-6 pb-3">
          <div className="row align-items-center">
            <div className="leaders-table">
              {isLoading ? <Spinner /> : (
                <table className="table text-white">
                  <thead className="avega yellow-txt center">
                    <th>Rank</th>
                    <th>Nickname</th>
                    <th>SCORE</th>
                  </thead>
                  <tbody>
                    {leaderBoardsEntries.map(({
                      nickname, score_1,
                    }) => (
                      <tr>
                        <td>{nickname}</td>
                        <td>{score_1 || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomBanner />

    </>
  );
};
export default LeadersBoard;
