import axios from 'axios';
import config from '../utils/config';

/* Creating a new instance of axios with the timeout and baseURL set. */
const AxiosClient = axios.create({
  timeout: config.NET_REQUEST_TIMEOUT,
  baseURL: config.SERVER_URL,
});

export default AxiosClient;
