/* eslint-disable brace-style */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Exobot from '../../components/Exobot';
import Filters from '../../components/Filters';
import Pagination from '../../components/Pagination';
import Banner from '../../sharedComponents/banner';
import Spinner from '../../sharedComponents/spinner';
import { API } from '../../utils/api';
import { IExobotDetail } from '../../utils/types';
import BottomBanner from '../../sharedComponents/BottomBanner';
import { paramsToObject } from '../../utils';

const emptySearch = (text: string) => (
  <div className="col-md-6 offset-md-3">
    <h3 className="text-center white-txt py-4 ">
      <i className="fa fa-solid fa-circle-exclamation yellow-txt text-center py-3 fs-1" />
      <br />
      {text}
    </h3>
  </div>
);

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation('common');
  const [currentPage, setCurrentPage] = useState('1');
  const [resultsPerPage, setResultsPerPage] = useState(searchParams.get('resultsPerPage') || '12');
  const [numPages, setNumPages] = useState('0');
  const [loading, setLoading] = useState(true);

  const [exobots, setExobots] = useState<IExobotDetail[]>([]);

  const [sortBy, setSortBy] = useState<string>('latest');

  const [cortex, setCortex] = useState<string>(searchParams.get('cortex') || '');
  const [helmet, setHelmet] = useState<string>(searchParams.get('helmet') || '');
  const [frontek, setFrontek] = useState<string>(searchParams.get('frontek') || '');
  const [kinetics, setKinetics] = useState<string>(searchParams.get('kinetics') || '');
  const [rightGear, setRightGear] = useState<string>(searchParams.get('rightGear') || '');
  const [leftGear, setLeftGear] = useState<string>(searchParams.get('leftGear') || '');

  // stats filters
  const [maxHP, setMaxHP] = useState(searchParams.get('maxHP') || '4000');
  const [maxDEF, setMaxDEF] = useState(searchParams.get('maxDEF') || '200');
  const [maxAP, setMaxAP] = useState(searchParams.get('maxAP') || '300');
  const [maxSPD, setMaxSPD] = useState(searchParams.get('maxSPD') || '300');
  const [maxCRIT, setMaxCRIT] = useState(searchParams.get('maxCRIT') || '2000');

  // attributes filters
  const [maxVIT, setMaxVIT] = useState(searchParams.get('maxVIT') || '300');
  const [maxARM, setMaxARM] = useState(searchParams.get('maxARM') || '300');
  const [maxPOT, setMaxPOT] = useState(searchParams.get('maxPOT') || '300');
  const [maxAGI, setMaxAGI] = useState(searchParams.get('maxAGI') || '300');
  const [maxACC, setMaxACC] = useState(searchParams.get('maxACC') || '300');

  // slots and level filters
  const [slots, setSlots] = useState('6');
  const [level, setLevel] = useState('0');

  const fetchInitialExobots = async () => {
    try {
      const response = await API.search.search(resultsPerPage, sortBy, currentPage);
      const { data } = response;
      setNumPages(data.Result.TotalPages);
      setResultsPerPage(data.Result.PageSize);
      setExobots(data.Result.FoundExobots);
    } catch (error: any) {
      // console.log(error);
      setNumPages('0');
      setResultsPerPage('10');
      setExobots([]);
      setCortex('');
      setHelmet('');
      setFrontek('');
      setKinetics('');
      setRightGear('');
      setLeftGear('');
    }
    setLoading(false);
  };

  const applyFilters = async () => {
    setLoading(true);
    try {
      const response = await API.search.search(
        resultsPerPage,
        sortBy,
        currentPage,
        cortex,
        helmet,
        rightGear,
        leftGear,
        kinetics,
        frontek,
        maxVIT,
        maxHP,
        maxARM,
        maxDEF,
        maxPOT,
        maxAP,
        maxAGI,
        maxSPD,
        maxACC,
        maxCRIT,
        slots,
      );
      const { data } = response;
      setNumPages(data.Result.TotalPages);
      setResultsPerPage(data.Result.PageSize);
      setExobots(data.Result.FoundExobots);
    } catch (error: any) {
      // console.log(error);
      setNumPages('0');
      setResultsPerPage('10');
      setExobots([]);
      setCortex('');
      setHelmet('');
      setFrontek('');
      setKinetics('');
      setRightGear('');
      setLeftGear('');
      setMaxVIT('300');
      setMaxHP('4000');
      setMaxARM('300');
      setMaxDEF('200');
      setMaxPOT('300');
      setMaxAP('300');
      setMaxAGI('300');
      setMaxSPD('200');
      setMaxACC('300');
      setMaxCRIT('200');
      setLoading(false);
      setSlots('6');
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    // comprueba si hay algun search params en la url y en ese caso activa los filtros
    const paramsFromURL = paramsToObject(searchParams);
    if (JSON.stringify(paramsFromURL) !== '{}') {
      applyFilters();
    } else {
      fetchInitialExobots();
    }
  }, [currentPage]);

  const sortById = (id: string) => {
    if (!id) {
      setSortBy('');
    }
    const sorted = exobots.filter((exobot) => exobot.Id.toString().includes(id));
    setExobots(sorted);
  };

  return (
    <>
      <Banner />
      <div className="watercolor-top-blue" />
      <div className="blueprint">
        <div className="container pb-sm-5">
          <Filters
            setSortBy={setSortBy}
            sortById={sortById}
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}
            setCortex={setCortex}
            setFrontek={setFrontek}
            setHelmet={setHelmet}
            setKinetics={setKinetics}
            setRightGear={setRightGear}
            setLeftGear={setLeftGear}
            applyFilters={applyFilters}
            setMaxVIT={setMaxVIT}
            setMaxHP={setMaxHP}
            setMaxARM={setMaxARM}
            setMaxDEF={setMaxDEF}
            setMaxPOT={setMaxPOT}
            setMaxAP={setMaxAP}
            setMaxAGI={setMaxAGI}
            setMaxSPD={setMaxSPD}
            setMaxACC={setMaxACC}
            setMaxCRIT={setMaxCRIT}
            setSlots={setSlots}
          />
          <h2 className="white-txt avega">
            {t('home.recentlyListed')}
            {' '}
            /////
          </h2>
          {loading ? <Spinner />
            : (exobots.length < 1 ? (emptySearch(t('home.emptySearch'))) : (
              <>
                <div className="row pt-3 mx-n2">
                  {exobots.map((exobot: IExobotDetail) => (
                    <Exobot
                      key={exobot.Id}
                      {...exobot}
                    />
                  ))}
                </div>
                <Pagination currentPage={Number(currentPage)} numPages={Number(numPages)} setCurrentPage={setCurrentPage} />
              </>
            )
            )}
          <BottomBanner />

        </div>
      </div>
    </>
  );
};

export default Home;
