/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNavigateWithTransition from '../../hooks/use-navigateWithTransition';
import { useAppSelector } from '../../redux/hooks';
import CapsulesBalance from '../../sharedComponents/capsulesBalance';
import ToastMessage from '../../sharedComponents/toastMessage';
import { logout } from '../../utils';
import { API } from '../../utils/api';
import Notifications from '../Notifications';

const ProfileDropDown = () => {
  const { t } = useTranslation('common');
  const [setTab] = useNavigateWithTransition();

  const email: string | null = useAppSelector<string | null>((state) => state.user.email);
  const jwt = useAppSelector<string | null>((state) => state.user.token);

  const notificationsIds: Array<string> = useAppSelector<Array<string>>((state) => state.notifications);
  const [numCapsules, setNumCapsules] = useState<string>(' ');

  const fetchNumCapsules = async () => {
    try {
      const response = await API.search.getNumCapsules(jwt || '');
      const { data } = response;
      setNumCapsules(data.Result.NumBoxes);
    } catch (error: any) {
      // console.log(error);
      setNumCapsules('-');
    }
  };

  useEffect(() => {
    fetchNumCapsules();
  }, []);

  return (
    <>
      <div className="btn-group p-3 mt-3" role="group" aria-label="Operations buttons">
        <CapsulesBalance numCapsules={numCapsules} />
        <Notifications notificationsIds={notificationsIds} handleReloadCapsules={fetchNumCapsules} />
        <div className="dropdow col" style={{ marginLeft: '15px' }}>
          <button className="btn btn-primary me-2 dropdown-toggle signup-but" type="button" id="dropDownMyAccount" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-solid fa-user" />
            {' '}
            {t('profileDropdown.myProfile')}
          </button>
          <div className="dropdown-menu exo-dropdown" aria-labelledby="dropDownMyAccount">
            <a onClick={() => setTab('dashboard/account')} className="dropdown-item">
              <i className="fa fa-regular fa-address-card" />
              {' '}
              {t('profileDropdown.account')}
            </a>
            <a onClick={() => setTab('dashboard/exobots')} className="dropdown-item">
              <i className="fa fa-regular fa-robot" />
              {' '}
              {t('profileDropdown.myExobots')}
            </a>
            <a onClick={() => setTab('dashboard/market')} className="dropdown-item">
              <i className="fa fa-regular fa-store" />
              {' '}
              {t('profileDropdown.myMarket')}
            </a>
            <a onClick={() => setTab('dashboard/buyBoxes')} className="dropdown-item">
              <i className="fa-solid fa-hexagon-vertical-nft" />
              {' '}
              {t('profileDropdown.buyCapsules')}
            </a>
            <div className="dropdown-divider" />
            <a className="dropdown-item" onClick={logout}>
              <b>
                <i className="fa fa-solid fa-arrow-right-to-bracket" />
                {' '}
                {t('profileDropdown.logout')}
              </b>
            </a>
          </div>
        </div>
      </div>
      {' '}
      {!email ? (
        <ToastMessage
          text={t('toastMessage.link')}
          showCloseButton={false}
          redirectButton
        />
      ) : null}
    </>
  );
};

export default ProfileDropDown;
