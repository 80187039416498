/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { IUserState } from '../../utils/types';
import LinkEmail from '../LinkEmail';
import UpdateReferral from './UpdateProfile/UpdateReferral';

const Profile = () => {
  const {
    nickname, wallet, email, referralCode,
  } = useAppSelector<IUserState>((state: RootState) => state.user);

  const { t } = useTranslation('common');

  return (
    <div className="tab-content mt-3" id="profile">
      <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account">
        <form className="signin-full-form pt-3">
          <div className="row">
            <label className="white-txt">{t('dashboard.nickname')}</label>

            <div className="col">

              <input type="text" readOnly className="form-control" id="Nickname" placeholder={nickname || ''} />
            </div>
            <div className="col" />
          </div>
          <div className="form-group row">
            <label className="white-txt">E-mail</label>
            {email !== '' ? (
              <div className="col">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder={email}
                  readOnly
                />
              </div>
            ) : null}
            <div className="col mb-3">
              {email === '' && (<LinkEmail />)}
            </div>
          </div>

          <div className="form-group row">
            <label className="white-txt">{t('dashboard.wallet')}</label>

            <div className="col">
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                placeholder={wallet || ''}
                readOnly
              />
            </div>
            <div className="col" />
          </div>
          {/* <div className="form-group">
            <label className="white-txt">{t('dashboard.ref')}</label>
            <div className="row">
              <div className="col">
                <input type="" className="form-control" id="referal" placeholder={referralCode || ''} />
              </div>
              <div className="col">
                <UpdateReferral />
              </div>
            </div>
          </div> */}

        </form>
      </div>
    </div>
  );
};

export default Profile;
