/* eslint-disable max-len */
import React from 'react';

import logo from '../../images/logo.png';

const Footer = () => (
  <footer className="text-center text-lg-start text-muted p-5">
    <div className="container py-5">
      <div className="d-flex flex-wrap justify-content-between  pb-1 mb-2 white-txt">
        <div className="col-md-4 d-flex ">
          <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
            <img id="mc-logo" src={logo} alt="MetronCity Games" style={{ maxWidth: '100px', marginLeft: '100px' }} />
          </a>
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <div id="block-23" className="widget widget_block widget_text">
            <div id="footer-os-icons" className="widget widget_block">
              <div>
                <h4 className="clint text-right pb-0 yellow-txt">DOWNLOAD THE GAME</h4>
              </div>
              <div className="wpb_wrapper pb-3">

                <p className="download-icons fs-3">
                  {' '}
                  <span className="">
                    <a
                      itemProp="url"
                      href="https://launcher.elixir.app/games/exobots"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-brands fa-windows fa-lg"
                      />
                    </a>
                  </span>
                  <span
                    className="px-3"
                  >
                    <a
                      itemProp="url"
                      href="https://testflight.apple.com/join/EnbxfBLt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-brands fa-apple fa-appe-2x"
                      />
                    </a>
                  </span>
                  <span
                    className=""
                  >
                    <a
                      itemProp="url"
                      href="https://play.google.com/store/apps/details?id=com.MetronCityGames.Exobots"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-brands fa-android fa-android-2x"
                      />
                    </a>
                  </span>
                  <span
                    className="px-3"
                  >
                    <a
                      itemProp="url"
                      href="https://testflight.apple.com/join/EnbxfBLt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-brands fa-app-store-ios fa-app-store-ios-2x"
                      />
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div id="legal-menu">
              <ul className="menu">
                <li>
                  <a target="_blank" href="https://exobotsgame.com/cookies-policy/" rel="noreferrer">Cookies Policy</a>
                </li>
                <li>
                  <a target="_blank" href="/terms-and-conditions" rel="noreferrer">Legal Notice and Terms of use</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
        <div className="nav col-md-4 justify-content-end list-unstyled d-flex column3 footer_col3">
          <div className="column_inner">
            <div id="footer-social-icons" className="widget widget_block">
              <div>
                <h4 className="clint text-right pb-0 yellow-txt">Follow us!</h4>
              </div>
              <div className="wpb_wrapper">

                <p>
                  {' '}
                  <span
                    data-type="circle"
                    data-hover-bg-color="#f028ff"
                    data-hover-icon-color="#c838f2"
                    className="qode_icon_shortcode fa-stack q_font_awsome_icon_stack fa-lg  "
                    style={{ margin: '10px 2px' }}
                  >
                    <a
                      itemProp="url"
                      href="https://t.me/Exobots"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-circle fa-stack-base fa-stack-2x"
                        style={{ color: '#ffffff' }}
                      />
                      <i
                        className="qode_icon_font_awesome_5 fa5 fab fa-telegram-plane qode_icon_element fa-stack-1x"
                        style={{ color: 'rgb(15, 15, 15)' }}
                      />
                    </a>
                  </span>
                  <span
                    data-type="circle"
                    data-hover-bg-color="#f028ff"
                    data-hover-icon-color="#c838f2"
                    className="qode_icon_shortcode fa-stack q_font_awsome_icon_stack fa-lg  "
                    style={{ margin: '10px 2px' }}
                  >
                    <a
                      itemProp="url"
                      href="https://twitter.com/ExobotsGame"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-circle fa-stack-base fa-stack-2x"
                        style={{ color: '#ffffff' }}
                      />
                      <i
                        className="qode_icon_font_awesome_5 fa5 fab fa-twitter qode_icon_element fa-stack-1x"
                        style={{ color: 'rgb(15, 15, 15)' }}
                      />
                    </a>
                  </span>
                  <span
                    data-type="circle"
                    data-hover-bg-color="#f028ff"
                    data-hover-icon-color="#c838f2"
                    className="qode_icon_shortcode fa-stack q_font_awsome_icon_stack fa-lg  "
                    style={{ margin: '10px 2px' }}
                  >
                    <a
                      itemProp="url"
                      href="https://twitch.tv/ExobotsGame"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-circle fa-stack-base fa-stack-2x"
                        style={{ color: '#ffffff' }}
                      />
                      <i
                        className="qode_icon_font_awesome_5 fa5 fab fa-twitch qode_icon_element fa-stack-1x"
                        style={{ color: 'rgb(15, 15, 15)' }}
                      />
                    </a>
                  </span>
                  <span
                    data-type="circle"
                    data-hover-bg-color="#f028ff"
                    data-hover-icon-color="#c838f2"
                    className="qode_icon_shortcode fa-stack q_font_awsome_icon_stack fa-lg  "
                    style={{ margin: '10px 2px' }}
                  >
                    <a
                      itemProp="url"
                      href="https://discord.com/invite/Hcx3FwNVga"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-circle fa-stack-base fa-stack-2x"
                        style={{ color: '#ffffff' }}
                      />
                      <i
                        className="qode_icon_font_awesome_5 fa5 fab fa-discord qode_icon_element fa-stack-1x"
                        style={{ color: 'rgb(15, 15, 15)' }}
                      />
                    </a>
                  </span>
                  <span
                    data-type="circle"
                    data-hover-bg-color="#f028ff"
                    data-hover-icon-color="#c838f2"
                    className="qode_icon_shortcode fa-stack q_font_awsome_icon_stack fa-lg  "
                    style={{ margin: '10px 2px' }}
                  >
                    <a
                      itemProp="url"
                      href="https://www.facebook.com/Exobots-Omens-of-Steel-100722959371391/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-circle fa-stack-base fa-stack-2x" style={{ color: '#ffffff' }} />
                      <i
                        className="qode_icon_font_awesome_5 fa5 fab fa-facebook-f qode_icon_element fa-stack-1x"
                        style={{ color: 'rgb(15, 15, 15)' }}
                      />
                    </a>
                  </span>
                  <span
                    data-type="circle"
                    data-hover-bg-color="#f028ff"
                    data-hover-icon-color="#c838f2"
                    className="qode_icon_shortcode fa-stack q_font_awsome_icon_stack fa-lg"
                    style={{ margin: '10px 2px' }}
                  >
                    <a
                      itemProp="url"
                      href="https://www.youtube.com/channel/UC9bnhTD-dHdGuwJZJmnx65A"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-circle fa-stack-base fa-stack-2x"
                        style={{ color: '#ffffff' }}
                      />
                      <i
                        className="qode_icon_font_awesome_5 fa5 fab fa-youtube qode_icon_element fa-stack-1x"
                        style={{ color: 'rgb(15, 15, 15)' }}
                      />
                    </a>
                  </span>
                  <span
                    data-type="circle"
                    data-hover-bg-color="#f028ff"
                    data-hover-icon-color="#c838f2"
                    className="qode_icon_shortcode fa-stack q_font_awsome_icon_stack fa-lg  "
                    style={{ margin: '10px 2px' }}
                  >
                    <a
                      itemProp="url"
                      href="https://www.instagram.com/exobotsgame/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="fa fa-circle fa-stack-base fa-stack-2x"
                        style={{ color: '#ffffff' }}
                      />
                      <i
                        className="qode_icon_font_awesome_5 fa5 fab fa-instagram qode_icon_element fa-stack-1x"
                        style={{ color: 'rgb(15, 15, 15)' }}
                      />
                    </a>
                  </span>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
