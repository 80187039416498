/* eslint-disable no-new */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { login, populateProfile } from '../../redux/features/user/userSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AppDispatch } from '../../redux/store';
import ToastMessage from '../../sharedComponents/toastMessage';
import { loginWithWallet } from '../../utils';
import { API } from '../../utils/api';
import ProfileDropDown from '../ProfileDropDown';

declare let window: any;
const LoginDropDown = () => {
  const { t } = useTranslation('common');

  const isLogged: boolean = useAppSelector<boolean>((state: any) => state.user.isLogged);

  const [alert, setAlert] = useState<boolean>(false);

  const dispatch: AppDispatch = useAppDispatch();

  const handleLogin = async () => {
    setAlert(false);
    if (window?.ethereum) {
      try {
        const payload = await loginWithWallet();
        const { data } = await API.authentication.populateProfile(payload.token);
        dispatch(login(payload));
        dispatch(populateProfile(data.Result.PlayerProfile));
      } catch (error) {
        // console.log(error);
      }
    } else {
      setAlert(true);
    }
  };

  return (
    <div className="dropdown">
      {!isLogged ? (
        <>
          <button className="btn btn-primary me-2 login-but" type="button" onClick={handleLogin}>
            <i className="fa fa-solid fa-user" />
            {' '}
            {t('home.login')}
          </button>
          {alert ? (
            <ToastMessage
              text={t('toastMessage.install')}
              redirectButton={false}
              showCloseButton={false}
            />
          ) : null}
        </>
      ) : (
        <ProfileDropDown />
      ) }
    </div>
  );
};

export default LoginDropDown;
