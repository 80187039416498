/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { paramsToObject } from '../../../utils';

const exobotsNames = [
  'stronghold',
  'renovator',
  'groundsmasher',
  'codebreaker',
  'deathbringer',
  'sumrak',
  'netshadow',
  'datashaper',
];

type FiltersExpandedProps = {
  sortById: any,
  setCortex: any,
  setFrontek: any,
  setHelmet: any,
  setKinetics: any,
  setRightGear: any,
  setLeftGear: any,
  applyFilters: any,
  setMaxVIT: any,
  setMaxHP: any,
  setMaxARM: any,
  setMaxDEF: any,
  setMaxPOT: any,
  setMaxAP: any,
  setMaxAGI: any,
  setMaxSPD: any,
  setMaxACC: any,
  setMaxCRIT: any,
  setSlots: any,
}

const FiltersExpanded = ({
  sortById,
  setCortex,
  setHelmet,
  setFrontek,
  setKinetics,
  setRightGear,
  setLeftGear,
  applyFilters,
  setMaxVIT,
  setMaxHP,
  setMaxARM,
  setMaxDEF,
  setMaxPOT,
  setMaxAP,
  setMaxAGI,
  setMaxSPD,
  setMaxACC,
  setMaxCRIT,
  setSlots,
}: FiltersExpandedProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resetAll = () => {
    setSearchParams({});
    window.location.reload();
  };

  const [maxVIT, setMaxVITUI] = useState(searchParams.get('maxVIT') || '300');
  const [maxHP, setMaxHPUI] = useState(searchParams.get('maxHP') || '4000');
  const [maxARM, setMaxARMUI] = useState(searchParams.get('maxARM') || '300');
  const [maxDEF, setMaxDEFUI] = useState(searchParams.get('maxDEF') || '200');
  const [maxPOT, setMaxPOTUI] = useState(searchParams.get('maxPOT') || '300');
  const [maxAP, setMaxAPUI] = useState(searchParams.get('maxAP') || '300');
  const [maxAGI, setMaxAGIUI] = useState(searchParams.get('maxAGI') || '300');
  const [maxSPD, setMaxSPDUI] = useState(searchParams.get('maxSPD') || '200');
  const [maxACC, setMaxACCUI] = useState(searchParams.get('maxACC') || '300');
  const [maxCRIT, setMaxCRITUI] = useState(searchParams.get('maxCRIT') || '2000');
  const [slotsUI, setSLOTSUI] = useState(searchParams.get('slots') || '6');

  const [cortexUI, setCortexUI] = useState(searchParams.get('cortex') || '');
  const [helmetUI, setHelmetUI] = useState(searchParams.get('helmet') || '');
  const [rightGearUI, setRightGearUI] = useState(searchParams.get('rightGear') || '');
  const [leftGearUI, setLeftGearUI] = useState(searchParams.get('leftGear') || '');
  const [frontekUI, setFrontekUI] = useState(searchParams.get('frontek') || '');
  const [kineticsUI, setKineticsUI] = useState(searchParams.get('kinetics') || '');

  const handleSlotsChange = (value: string) => {
    setSlots(value);
    setSLOTSUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, slots: value });
  };
  const handleVITChange = (value: string) => {
    setMaxVIT(value);
    setMaxVITUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxVIT: value });
  };
  const handleHPChange = (value: string) => {
    setMaxHP(value);
    setMaxHPUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxHP: value });
  };
  const handleARMChange = (value: string) => {
    setMaxARM(value);
    setMaxARMUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxARM: value });
  };
  const handleDEFChange = (value: string) => {
    setMaxDEF(value);
    setMaxDEFUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxDEF: value });
  };
  const handlePOTChange = (value: string) => {
    setMaxPOT(value);
    setMaxPOTUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxPOT: value });
  };
  const handleAPChange = (value: string) => {
    setMaxAP(value);
    setMaxAPUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxAP: value });
  };
  const handleAGIChange = (value: string) => {
    setMaxAGI(value);
    setMaxAGIUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxAGI: value });
  };
  const handleSPDChange = (value: string) => {
    setMaxSPD(value);
    setMaxSPDUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxSPD: value });
  };
  const handleACCChange = (value: string) => {
    setMaxACC(value);
    setMaxACCUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxACC: value });
  };
  const handleCRITChange = (value: string) => {
    setMaxCRIT(value);
    setMaxCRITUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, maxCRIT: value });
  };

  // PARTS
  const handleSetCortex = (value: string) => {
    setCortex(value);
    setCortexUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, cortex: value });
  };
  const handleSetHelmet = (value: string) => {
    setHelmet(value);
    setHelmetUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, helmet: value });
  };
  const handleSetRightGear = (value: string) => {
    setRightGear(value);
    setRightGearUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, rightGear: value });
  };
  const handleSetLeftGear = (value: string) => {
    setLeftGear(value);
    setLeftGearUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, leftGear: value });
  };
  const handleSetFrontek = (value: string) => {
    setFrontek(value);
    setFrontekUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, frontek: value });
  };
  const handleSetKinetics = (value: string) => {
    setKinetics(value);
    setKineticsUI(value);
    const params = paramsToObject(searchParams);
    setSearchParams({ ...params, kinetics: value });
  };

  return (
    <div className="collapse" id="exo-filters">
      <div className="row pt-4">
        <div className="col-lg-4 col-sm-6">
          <div className="card mb-grid-gutter">
            <div className="card-body px-4 py-4">
              <div className="input-group input-group-sm mb-2 margin-top">
                <input
                  className="widget-filter-search form-control p-2 mb-4"
                  type="text"
                  placeholder="Search by ID"
                  id="idSearch"
                  onChange={(e) => sortById(e.target.value)}
                />
                {' '}
                <i
                  className="fa-regular fa-search position-absolute end-0 translate-middle-y fs-sm me-3 white-txt"
                />

              </div>
              <div className="form-group col avega white-txt pb-0">
                <h5>
                  SLOTS
                  {' '}
                  <span style={{ marginLeft: '250px' }}>{slotsUI}</span>
                  {' '}
                </h5>
              </div>
              <div
                className="range-slider"
                data-start-min="250"
                data-start-max="680"
                data-min="0"
                data-max="1000"
                data-step="1"
              >
                <input
                  type="range"
                  className="form-control dropdown"
                  value={slotsUI}
                  min="0"
                  step="1"
                  max="6"
                  id="Slots"
                  onChange={(e) => handleSlotsChange(e.target.value)}
                />
              </div>

            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">

          <div className="card mb-grid-gutter">
            <div className="card-body px-4">
              <div className="widget">
                <h5 className="avega white-txt pb-0">
                  <span>ATTRIBUTES</span>
                  <span style={{ marginLeft: '60px' }}>STATS</span>
                </h5>
                <div className="row mb-3">
                  <div className="form-group  col">
                    <label htmlFor="Cortex">Max VIT</label>
                    <label>{maxVIT}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxVIT}
                      min="0"
                      step="5"
                      max="300"
                      id="Cortex"
                      onChange={(e) => handleVITChange(e.target.value)}
                    />
                  </div>
                  <div className="form-group  col">
                    <label htmlFor="Helmet">Max HP</label>
                    <label>{maxHP}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxHP}
                      min="0"
                      step="10"
                      max="4000"
                      id="Helmet"
                      onChange={(e) => handleHPChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="form-group  col">
                    <label htmlFor="Cortex">Max ARM</label>
                    <label>{maxARM}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxARM}
                      min="0"
                      step="5"
                      max="300"
                      id="Cortex"
                      onChange={(e) => handleARMChange(e.target.value)}
                    />
                  </div>
                  <div className="form-group  col">
                    <label htmlFor="Helmet">Max DEF</label>
                    <label>{maxDEF}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxDEF}
                      min="0"
                      step="1"
                      max="200"
                      id="Helmet"
                      onChange={(e) => handleDEFChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="form-group  col">
                    <label htmlFor="Cortex">Max POT</label>
                    <label>{maxPOT}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxPOT}
                      min="0"
                      step="5"
                      max="300"
                      id="Cortex"
                      onChange={(e) => handlePOTChange(e.target.value)}
                    />
                  </div>
                  <div className="form-group  col">
                    <label htmlFor="Helmet">Max AP</label>
                    <label>{maxAP}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxAP}
                      min="0"
                      step="1"
                      max="300"
                      id="Helmet"
                      onChange={(e) => handleAPChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="form-group  col">
                    <label htmlFor="Cortex">Max AGI</label>
                    <label>{maxAGI}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxAGI}
                      min="0"
                      step="5"
                      max="300"
                      onChange={(e) => handleAGIChange(e.target.value)}
                    />
                  </div>
                  <div className="form-group  col">
                    <label htmlFor="Helmet">Max SPD</label>
                    <label>{maxSPD}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxSPD}
                      min="0"
                      step="1"
                      max="200"
                      id="Helmet"
                      onChange={(e) => handleSPDChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="form-group  col">
                    <label htmlFor="Cortex">Max ACC</label>
                    <label>{maxACC}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxACC}
                      min="0"
                      step="5"
                      max="300"
                      id="Cortex"
                      onChange={(e) => handleACCChange(e.target.value)}
                    />
                  </div>
                  <div className="form-group  col">
                    <label htmlFor="Helmet">Max CRIT</label>
                    <label>{maxCRIT}</label>
                    <input
                      type="range"
                      className="form-control dropdown"
                      value={maxCRIT}
                      min="0"
                      step="1"
                      max="200"
                      id="Helmet"
                      onChange={(e) => handleCRITChange(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="card mb-grid-gutter">
            <div className="card-body px-4">
              <div className="widget widget-filter">
                <h5 className="avega white-txt pb-0">PARTS</h5>
                <div className="input-group input-group-sm mb-2">

                  <div className="row mb-3">
                    <div className="form-group  col">
                      <label htmlFor="Cortex">Cortex</label>
                      <select className="form-control exo-select dropdown" id="Cortex" onChange={(e) => handleSetCortex(e.target.value)}>
                        <option value="">All</option>
                        {exobotsNames.map((name) => (
                          `cortex-${name}` === cortexUI
                            ? (<option value={`cortex-${name}`} selected>{name}</option>)
                            : (<option value={`cortex-${name}`}>{name}</option>)))}
                      </select>
                    </div>
                    <div className="form-group  col">
                      <label htmlFor="Helmet">Helmet</label>
                      <select className="form-control exo-select dropdown" id="Helmet" onChange={(e) => handleSetHelmet(e.target.value)}>
                        <option value="">All</option>
                        {exobotsNames.map((name) => (
                          `helmet-${name}` === helmetUI
                            ? (<option value={`helmet-${name}`} selected>{name}</option>)
                            : (<option value={`helmet-${name}`}>{name}</option>)))}
                      </select>
                    </div>
                  </div>

                </div>
                <div className="input-group input-group-sm mb-2">

                  <div className="row mb-3">
                    <div className="form-group col">
                      <label htmlFor="RightArm">Right Gear</label>
                      <select className="form-control exo-select dropdown" id="RightArm" onChange={(e) => handleSetRightGear(e.target.value)}>
                        <option value="">All</option>
                        {exobotsNames.map((name) => (
                          `rightGear-${name}` === rightGearUI
                            ? (<option value={`rightGear-${name}`} selected>{name}</option>)
                            : (<option value={`rightGear-${name}`}>{name}</option>)))}
                      </select>
                    </div>
                    <div className="form-group col">
                      <label htmlFor="LeftArm">Left Gear</label>
                      <select className="form-control exo-select dropdown" id="LeftArm" onChange={(e) => handleSetLeftGear(e.target.value)}>
                        <option value="">All</option>
                        {exobotsNames.map((name) => (
                          `leftGear-${name}` === leftGearUI
                            ? (<option value={`leftGear-${name}`} selected>{name}</option>)
                            : (<option value={`leftGear-${name}`}>{name}</option>)))}
                      </select>
                    </div>
                  </div>

                </div>
                <div className="input-group input-group-sm mb-2">

                  <div className="row mb-3">
                    <div className="form-group col">
                      <label htmlFor="Frontex">Frontek</label>
                      <select className="form-control exo-select dropdown" id="Frontex" onChange={(e) => handleSetFrontek(e.target.value)}>
                        <option value="">All</option>
                        {exobotsNames.map((name) => (
                          `frontek-${name}` === frontekUI
                            ? (<option value={`frontek-${name}`} selected>{name}</option>)
                            : (<option value={`frontek-${name}`}>{name}</option>)))}
                      </select>
                    </div>
                    <div className="form-group col">
                      <label htmlFor="LeftArm">Kinetics</label>
                      <select className="form-control exo-select dropdown" id="Kinetics" onChange={(e) => handleSetKinetics(e.target.value)}>
                        <option value="">All</option>
                        {exobotsNames.map((name) => (
                          `kinetics-${name}` === kineticsUI
                            ? (<option value={`kinetics-${name}`} selected>{name}</option>)
                            : (<option value={`kinetics-${name}`}>{name}</option>)))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="bd-example">
        <button className="btn btn-primary btn-xlg" type="button" onClick={applyFilters}>
          APPLY FILTERS
          {' '}
          <i className="fa fa-solid fa-check" />
        </button>
        <button className="btn btn-outline-light" type="button" onClick={resetAll}>
          RESET FILTERS
          {' '}
          <i className="fa fa-regular fa-eraser" />
        </button>
      </div>
    </div>
  );
};

export default FiltersExpanded;
