/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Exobot from '../../components/Exobot';
import BottomBanner from '../../sharedComponents/BottomBanner';
import EmptyTopBanner from '../../sharedComponents/EmpyTopBanner/EmptyTopBanner';
import Spinner from '../../sharedComponents/spinner';
import { API } from '../../utils/api';
import { IExobotDetail } from '../../utils/types';
import NotFound from '../NotFound';

const SharedMarket = () => {
  const params = useParams();
  if (params.wallet == null) return <NotFound />;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [exobotsForSale, setExobotsForSale] = useState<IExobotDetail[]>([]);

  useEffect(() => {
    API.search.getPlayerMarket(params.wallet || '')
      .then((res: any) => {
        setIsLoading(false);
        if (res.data.Result.PlayerMarket.length < 1) {
          setIsError(true);
        } else {
          setExobotsForSale(res.data.Result.PlayerMarket);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return (
    <>
      <EmptyTopBanner />
      <div className="blueprint">
        <div className="container pb-sm-5">
          {isLoading ? <Spinner /> : (
            isError ? <NotFound /> : (
              <div className="row pt-3 mx-n2">
                <h2 className="white-txt avega mb-4" id="listed" style={{ marginTop: '30px' }}><span className="yellow-txt">MARKET OF :</span> {params.wallet} </h2>
                {exobotsForSale.map((exobot) => (
                  <Exobot
                    key={exobot.Id}
                    {...exobot}
                  />
                ))}
              </div>
            )
          )}
        </div>
      </div>
      <BottomBanner />
    </>
  );
};

export default SharedMarket;
