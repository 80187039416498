/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logoMerge from '../../images/merge-img-banner.png';
import bannerImg from '../../images/exonite-img-banner.png';
import bannerImg1 from '../../images/play-earn-img-banner.png';

const Banner = () => {
  const { t } = useTranslation('common');
  return (
    <div className="top-container city-header">
      <div className="top-banner container">
        <div id="carouselHome" className="carousel slide" data-ride="carousel">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselHome"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="1" aria-label="Slide 2" />
            <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="2" aria-label="Slide 3" />
          </div>
          <ol className="carousel-indicators">
            <li data-target="#carouselHome" data-slide-to="0" className="active" />
            <li data-target="#carouselHome" data-slide-to="1" />
            <li data-target="#carouselHome" data-slide-to="2" />
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-5 col-md-5">
                  <div className="banner-title ls-sm">
                    <span className="avega yellow-txt fs-3 mb-1 lh-sm">
                      <span className="avegaol">
                        PLAY & EARN
                      </span>
                      <span className="white-txt">
                        /
                      </span>
                      SEASON 1
                    </span>
                    <br />
                    <span className="avega white-txt display-3 lh-sm">
                      {' '}
                      UP TO $3,000
                    </span>
                    <br />
                    <span className="clint purple-txt display-5 mb-1 lh-sm">
                      FIGHT YOUR WAY UP & WIN
                      <br />
                    </span>
                    <span className="white-txt fs-6 mt-3 lh-sm">
                      <br />
                      Open until October 17
                      <sup>
                        th
                      </sup>
                      <br />
                    </span>
                    <Link
                      className="btn btn-xxlg btn-outline-light fs-4 mb-4 mt-4"
                      to="/leaders-board?season=S01&name=standard-pvp_p2e"
                    >
                      LEADERBOARD
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7 text-lg-end">
                  <img draggable="false" src={bannerImg1} className="img-fluid" alt="..." />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-5 col-md-5">
                  <div className="banner-title  ls-sm">
                    <span className="avega yellow-txt display-5 mb-1 lh-sm">
                      LOAD YOUR BAG
                    </span>
                    <br />
                    <span className="avega white-txt display-5 mb-1 lh-sm">
                      {' '}
                      WITH EXONITES
                    </span>
                    <br />
                    <span className="clint purple-txt display-5 mb-1 lh-sm">
                      AND GO SHOPPING!
                      <br />
                    </span>
                    <Link className="btn btn-xxlg btn-outline-light fs-4 mb-4 mt-4" to="/exonite">
                      BUY
                      EXONITE
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7 text-lg-end">
                  <img draggable="false" src={bannerImg} className="img-fluid" alt="..." />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-5 col-md-5">
                  <div className="banner-title ls-sm">
                    <span className="avega yellow-txt display-5 mb-1 lh-sm">
                      TOP UP
                      <br />
                      YOUR CAPSULES,
                    </span>
                    <br />
                    <span className="avega white-txt display-5 mb-1 lh-sm">
                      {' '}
                      TO MERGE
                      {' '}
                      <br />
                      NEW EXOBOTS
                    </span>
                    <br />
                    <span className="clint purple-txt display-5 mb-1 lh-sm">
                      AND GROW YOUR ARMY!
                      <br />
                    </span>
                    <Link className="btn btn-xxlg btn-outline-light fs-4 mb-4 mt-4" to="/capsules">
                      BUY
                      CAPSULES
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7 text-lg-end">
                  <img draggable="false" src={logoMerge} className="img-fluid" alt="..." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
