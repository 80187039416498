const LOCALSTORAGE_KEYS = {
  JWT: 'userJWT',
  ACC: 'userACC',
  EMAIL: 'userEmail',
  NICKNAME: 'userNickname',
  REFERRAL_CODE: 'userReferralCode',
  NOTIFICATIONS_IDS: 'notifications',
  LANG: 'lang',
};

const setLocalStorage = (key: string, value: string) => localStorage.setItem(key, value);

const getLocalStorage = (key: string) => localStorage.getItem(key);

const removeFromLocalStorage = (key: string) => localStorage.removeItem(key);

export {
  getLocalStorage,
  setLocalStorage,
  removeFromLocalStorage,
  LOCALSTORAGE_KEYS,
};
