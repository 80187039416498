/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Spinner from '../../sharedComponents/spinner';
import { containsOnlyNumbers, validPrice } from '../../utils';
import { API } from '../../utils/api';
import { IExobotDetail } from '../../utils/types';
import Exobot from '../Exobot';
import { LOCALSTORAGE_KEYS, getLocalStorage } from '../../utils/localStorage';

const emptyInventory = () => (
  <div className="col-md-6 offset-md-3">
    <h3 className="text-center white-txt py-4 ">
      <i className="fa fa-solid fa-circle-exclamation yellow-txt text-center py-3 fs-1" />
      <br />
      Hey mate! You don't own any Exobot yet. Take a look to the marketpace, choose wisely at least 3, buy them and let the fun begin!
    </h3>
  </div>
);

const normalInventory = (exobots: IExobotDetail[]) => (
  <div className="row pt-3 mx-n2">
    {exobots.map((exobot: IExobotDetail) => (
      <Exobot
        fromInventory
        key={exobot.Id}
        {...exobot}
      />
    ))}
  </div>
);

const Inventory = () => {
  const jwt = getLocalStorage(LOCALSTORAGE_KEYS.JWT);
  const [isEmpty, setIsEmpty] = useState(true);
  const [exobots, setExobots] = useState<IExobotDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchInventoryExobots = async () => {
    try {
      const response = await API.search.myInventory(jwt);
      const { data } = response;
      const exobotsToShow = data.Result?.InventoryExobots.filter((exobot: IExobotDetail) => containsOnlyNumbers(exobot.Id) && validPrice(exobot.Price));
      if (exobotsToShow.length < 1) throw Error('Empty inventory');
      setExobots(exobotsToShow);
      setIsEmpty(false);
    } catch (error: any) {
      // console.log(error);
      setExobots([]);
      setIsEmpty(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInventoryExobots();
  }, []);

  return (
    <div className="row pt-3 mx-n2">
      {loading ? (<Spinner />) : (
        isEmpty ? emptyInventory()
          : normalInventory(exobots)
      )}
    </div>
  );
};

export default Inventory;
