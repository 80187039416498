/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BuyButton from '../../../sharedComponents/buyButton';
import core from '../../../images/icon-core.svg';
import merge from '../../../images/icon-merges.svg';
import tiers from '../../../images/icon-tiers.svg';
import getImg from './Cards';
import PlaceholderImage from './placeholderBig.png';
import { FoundExobot } from '../../../utils/types';
import RemoveFromSaleButton from '../../../sharedComponents/removeFromSaleButton';
import { URL_BLOB_IMAGES } from '../../../utils/constants';
import { useAppSelector } from '../../../redux/hooks';
import EditPriceButton from '../../../sharedComponents/editPriceButton';

interface DetailProps {
  isOwner: boolean,
  exobot: FoundExobot,
}

const exobots = [
  'Stronghold',
  'Renovator',
  'Codebreaker',
  'Groundsmasher',
  'Sumrak',
  'Deathbringer',
  'Netshadow',
  'Scrapmaster',
  'Datashaper',
];

const exobotsMin = [
  'sh',
  'rv',
  'cb',
  'gs',
  'sr',
  'db',
  'ns',
  'sm',
  'ds',
];

const getUrl = (id: string) => `${URL_BLOB_IMAGES}bg-${id}.png`;

const ShowDetail = ({ exobot, isOwner }: DetailProps) => {
  const currentPrice: number = useAppSelector<number>((state) => state.user.currentPrice);

  const navigate = useNavigate();

  const [price, setPrice] = useState<string>('');

  const [maxHeihgt, setMaxHeight] = useState<number>(2500);

  const [style, setStyle] = useState('img-fluid idle-strech mb-2');

  const { t } = useTranslation('common');

  const getPosition = () => {
    const imgPosition = window.scrollY;
    if (imgPosition > 250 && imgPosition <= maxHeihgt) {
      setStyle('img-fluid idle-strech mb-2 scroll exobot-shadow');
    } else if (imgPosition > maxHeihgt) {
      setStyle('img-fluid idle-strech mb-2 scroll-stop exobot-shadow');
    } else {
      setStyle('img-fluid idle-strech mb-2 exobot-shadow');
    }
  };

  useEffect(() => {
    if (exobot.DerivedsIds !== null || exobot.SourcesIds !== null) {
      setMaxHeight(2700);
      if (exobot.DerivedsIds?.length > 4) {
        setMaxHeight(3100);
      }
      if (exobot.SourcesIds?.length > 4) {
        setMaxHeight(3100);
      }
    }
    window.addEventListener('scroll', getPosition, { passive: true });
    const round = exobot.Price * currentPrice;
    setPrice(round.toFixed(4));
  }, []);

  return (
    <>
      <div className="mb-3">
        <a type="button" onClick={() => navigate(-1)} id="back-button">
          <i className="fa fa-angle-left" />
          <span className="avega">
            BACK
          </span>
        </a>
        <span className="exobot-id-num">
          <span className="avega px-3">
            ID
            <span className="yellow-txt">
              //
            </span>
          </span>
          <span>
            #
            {exobot?.Name}
          </span>
        </span>
      </div>
      <div className="exobot-profile">
        <div className="py-1 mt-n5 mb-4">
          <div className="row justify-content-between">
            <div className="col-lg-6 white exo-info fs-6">
              <div className="exobot-picture" id="exobot-picture">
                <LazyLoadImage
                  width={624}
                  height={570}
                  src={getUrl(exobot.Id.toString())}
                  className={style}
                  alt="Exobot"
                  placeholderSrc={PlaceholderImage}
                />
              </div>
            </div>
            <div className="col-lg-6">
              {!isOwner ? (
                <div className="row justify-content-between">
                  <div className="col-lg-9 text-end white p-2 px-0">
                    <div className="text-center text-lg-end  single-product-price mb-md-0">
                      <span
                        className="text-center text-lg-end  product-price-dollar mb-md-0"
                      >
                        {price.toLocaleString()}
                        {' '}
                        BNB
                      </span>
                      <span className="price-exos">
                        <b>
                          {' '}
                          {exobot.Price}
                        </b>
                      </span>
                      <span className=""> EXOS</span>

                    </div>
                  </div>
                  <div className="col-3 text-center text-lg-end mt-2 center-lg white">
                    <BuyButton price={exobot.Price} ownerWallet={exobot.Owner} exoId={exobot.Id} />
                  </div>
                </div>
              ) : null}
              {isOwner ? (
                <div className="btn-group p-3 mt-3" role="group">
                  <RemoveFromSaleButton exoId={exobot.Id} />
                  <EditPriceButton exoId={exobot.Id} />
                </div>
              ) : null}
              <div className="exobot-level" style={{ backgroundImage: `url(/rarity-image-${exobot.Rarity}.png)` }}>
                {exobot.Level}
              </div>
              {/* End rarity */}
              <div className="exobot-data mt-3 py-3">
                <div className="row mb-3">
                  <h3 className="parts mb-3 detail-title">OWNER</h3>
                  <div className="exobot-id-num fs-5">
                    <Link to={`/player-market/${exobot.Owner}`} className="text-white">
                      {exobot.Owner}
                    </Link>
                  </div>
                </div>
                <div className="row mb-3">
                  <h3 className="parts mb-3 detail-title">MAIN</h3>
                  <div className="col mb-3">
                    <div className="row text-start justify-content-start">
                      <img
                        src={core}
                        className="float-left profile-icons"
                      />

                    </div>
                    <span className="avega">CORE</span>
                    {' '}
                    <i
                      className="fa-regular fa-circle-question more-info"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Identifies the type of Exobot and is related to the highest number of equal pieces."
                    />
                    <br />
                    <span className="fs-5 single-core" style={{ color: `var(--exo-${exobotsMin[exobot.Family]}-color)` }}>{exobots[exobot.Family]}</span>

                  </div>
                  <div className="col mb-3">
                    <div className="row text-start justify-content-start">
                      <img
                        src={merge}
                        className="float-left profile-icons"
                      />

                    </div>
                    <span className="avega">MERGE </span>
                    <i
                      className="fa-regular fa-circle-question more-info"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Number of spent and available Merge of the Exobot. Merge is the capacity to generate new Exobots"
                    />
                    <br />
                    <span className="fs-3">
                      <b>{exobot.MergeCount}</b>
                      <sub>
                        /
                        {exobot.MaxMerges}
                      </sub>
                    </span>
                  </div>
                  <div className="col mb-3">
                    <div className="row text-start justify-content-start">
                      <img
                        src={tiers}
                        className="float-left profile-icons"
                      />

                    </div>
                    <span className="avega">SLOTS </span>
                    <i
                      className="fa-regular fa-circle-question more-info"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Number of sockets contained in your Exobot directly related to the purity."
                    />
                    <br />
                    <span className="fs-3">
                      <b>{exobot.CurrentSlots}</b>
                      <sub>
                        /
                        {exobot.MaxSlots}
                      </sub>
                    </span>
                  </div>
                </div>
                <div className="row mb-3">
                  <h3 className="parts detail-title">{t('exobotDetail.inventory.parts')}</h3>
                  <div className="col-md-6">
                    <span className="parts-label">Helmet</span>
                    <div className="parts-row" style={{ borderLeft: `10px solid var(--exo-${exobotsMin[exobot.MainRoutine?.Helmet]}-color)` }}>{exobots[exobot.MainRoutine?.Helmet]}</div>
                    <span className="parts-label">Cortex</span>
                    <div className="parts-row" style={{ borderLeft: `10px solid var(--exo-${exobotsMin[exobot.MainRoutine?.Cortex]}-color)` }}>{exobots[exobot.MainRoutine?.Cortex]}</div>
                    <span className="parts-label">Frontek</span>
                    <div className="parts-row" style={{ borderLeft: `10px solid var(--exo-${exobotsMin[exobot.MainRoutine?.Frontek]}-color)` }}>{exobots[exobot.MainRoutine?.Frontek]}</div>
                  </div>
                  <div className="col-md-6">
                    <span className="parts-label">Left Gear</span>
                    <div className="parts-row" style={{ borderLeft: `10px solid var(--exo-${exobotsMin[exobot.MainRoutine?.Left_Gear]}-color)` }}>{exobots[exobot.MainRoutine?.Left_Gear]}</div>
                    <span className="parts-label">Right Gear</span>
                    <div className="parts-row" style={{ borderLeft: `10px solid var(--exo-${exobotsMin[exobot.MainRoutine?.Right_Gear]}-color)` }}>{exobots[exobot.MainRoutine?.Right_Gear]}</div>
                    <span className="parts-label">Kinetics</span>
                    <div className="parts-row" style={{ borderLeft: `10px solid var(--exo-${exobotsMin[exobot.MainRoutine?.Kinetics]}-color)` }}>{exobots[exobot.MainRoutine?.Kinetics]}</div>
                  </div>
                </div>
                <div className="blank-space" />
                <div className="row">
                  <h3 className="avega parts float-start text-start">{t('exobotDetail.inventory.stats')}</h3>
                </div>
                <div className="row exobot-profile mb-3 white-txt item-stats text-center py-2 mx-1">
                  <div className="col px-1">
                    <p className="hp-th stats-table">HP</p>
                    {exobot?.Stats.HP}
                  </div>
                  <div className="col px-1">
                    <p className="def-th stats-table">DEF</p>
                    {exobot?.Stats.DEF}
                  </div>
                  <div className="col px-1">
                    <p className="ap-th stats-table">AP</p>
                    {exobot?.Stats.AP}
                  </div>
                  <div className="col  px-1">
                    <p className="spd-th stats-table">SPD</p>
                    {exobot?.Stats.SPD}
                  </div>
                  <div className="col px-1">
                    <p className="crit-th stats-table">CRIT</p>
                    {exobot?.Stats.CRIT}
                  </div>
                </div>
                <div className="blank-space" />
                <div className="row">
                  <h3 className="avega parts float-start text-start">{t('exobotDetail.inventory.att')}</h3>
                </div>
                <div className="row exobot-profile mb-3 white-txt item-stats text-center py-2 mx-1">
                  <div className="col px-1">
                    <p className="hp-th stats-table">VIT</p>
                    {exobot?.Stats.VIT}
                  </div>
                  <div className="col px-1">
                    <p className="def-th stats-table">ARM</p>
                    {exobot?.Stats.ARM}
                  </div>
                  <div className="col px-1">
                    <p className="ap-th stats-table">POT</p>
                    {exobot?.Stats.POT}
                  </div>
                  <div className="col  px-1">
                    <p className="spd-th stats-table">AGI</p>
                    {exobot?.Stats.AGI}
                  </div>
                  <div className="col px-1">
                    <p className="crit-th stats-table">ACC</p>
                    {exobot?.Stats.ACC}
                  </div>
                </div>
                <div className="blank-space" />
                <div className="row cards mb-3">
                  <h3 className="avega parts">{t('exobotDetail.inventory.cards')}</h3>
                  {exobot.CardsIds.map((id: string) => (
                    <div className="col-4 mb-3" key={`${id}card`}>
                      <img key={id} src={getImg(id)} className="img-fluid" alt="..." />
                    </div>
                  ))}
                </div>
                <div className="blank-space" />
                <div className="row mb-3">
                  <h3 className="avega parts float-start text-start">{t('exobotDetail.inventory.ascendants')}</h3>
                  {exobot.SourcesIds ? (
                    exobot.SourcesIds.map((id) => (
                      <div className="col-md-6" key={`${id}po`}>
                        <div className="card product-card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                          <div className="container top-card-info">
                            <div className="row ">
                              <div className="col-6 white exo-info p-2 ">
                                <p className="avega info-card-value">ID</p>
                                <p className="info-card-label">{id}</p>
                              </div>
                            </div>
                          </div>

                          <div className="card-id" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>

                            <a className="card-img-top d-block exobot-shadow" href={`/exobot/${id}`}>
                              <img
                                className="img-fluid idle-strech mb-2"
                                src={getUrl(id)}
                                alt="Product"
                              />

                            </a>

                          </div>
                        </div>
                      </div>
                    ))
                  ) : (<p>{t('exobotDetail.inventory.noasc')}</p>)}
                </div>
                <div className="blank-space" />
                <div className="row mb-3">
                  <h3 className="avega parts float-start text-start">{t('exobotDetail.inventory.descendants')}</h3>
                  {exobot.DerivedsIds ? (
                    exobot.DerivedsIds.map((id) => (
                      <div className="col-md-6" key={id}>
                        <div className="card product-card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                          <div className="container top-card-info">
                            <div className="row ">
                              <div className="col-6 white exo-info p-2 ">
                                <p className="avega info-card-value">ID</p>
                                <p className="info-card-label">{id}</p>
                              </div>
                            </div>
                          </div>

                          <div className="card-id" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>

                            <a className="card-img-top d-block exobot-shadow" href={`/exobot/${id}`}>
                              <img
                                className="img-fluid idle-strech mb-2"
                                src={getUrl(id)}
                                alt="Product"
                              />

                            </a>

                          </div>
                        </div>
                      </div>
                    ))
                  ) : (<p>{t('exobotDetail.inventory.nodesc')}</p>)}
                </div>
                <div className="blank-space" />
                <div className="row">
                  <h3 className="avega parts float-start text-start">
                    {t('exobotDetail.inventory.routines')}
                    {' '}
                    <i
                      className="fs-6 fa-regular fa-circle-question more-info"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="The Exobots Routines are central and often foundational part usually distinct from the enveloping part by a difference in nature"
                    />
                  </h3>
                </div>
                <div className="row mb-3 white-txt item-stats text-center py-2 mx-1">
                  <table className="table stats-table white-txt">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        <th scope="col">MAIN</th>
                        <th scope="col">SUB 1</th>
                        <th scope="col">SUB 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Helmet</th>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.MainRoutine?.Helmet]}-color)` }}>{exobots[exobot.MainRoutine?.Helmet]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine1?.Helmet]}-color)` }}>{exobots[exobot.SubRoutine1?.Helmet]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine2?.Helmet]}-color)` }}>{exobots[exobot.SubRoutine2?.Helmet]}</td>
                      </tr>
                      <tr>
                        <th scope="row">Cortex</th>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.MainRoutine?.Cortex]}-color)` }}>{exobots[exobot.MainRoutine?.Cortex]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine1?.Cortex]}-color)` }}>{exobots[exobot.SubRoutine1?.Cortex]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine2?.Cortex]}-color)` }}>{exobots[exobot.SubRoutine2?.Cortex]}</td>
                      </tr>
                      <tr>
                        <th scope="row">Frontek</th>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.MainRoutine?.Frontek]}-color)` }}>{exobots[exobot.MainRoutine?.Frontek]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine1?.Frontek]}-color)` }}>{exobots[exobot.SubRoutine1?.Frontek]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine2?.Frontek]}-color)` }}>{exobots[exobot.SubRoutine2?.Frontek]}</td>
                      </tr>
                      <tr>
                        <th scope="row">Left Gear</th>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.MainRoutine?.Left_Gear]}-color)` }}>{exobots[exobot.MainRoutine?.Left_Gear]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine1?.Left_Gear]}-color)` }}>{exobots[exobot.SubRoutine1?.Left_Gear]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine2?.Left_Gear]}-color)` }}>{exobots[exobot.SubRoutine2?.Left_Gear]}</td>
                      </tr>
                      <tr>
                        <th scope="row">Right Gear</th>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.MainRoutine?.Right_Gear]}-color)` }}>{exobots[exobot.MainRoutine?.Right_Gear]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine1?.Right_Gear]}-color)` }}>{exobots[exobot.SubRoutine1?.Right_Gear]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine2?.Right_Gear]}-color)` }}>{exobots[exobot.SubRoutine2?.Right_Gear]}</td>

                      </tr>
                      <tr>
                        <th scope="row">Kinetics</th>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.MainRoutine?.Kinetics]}-color)` }}>{exobots[exobot.MainRoutine?.Kinetics]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine1?.Kinetics]}-color)` }}>{exobots[exobot.SubRoutine1?.Kinetics]}</td>
                        <td style={{ borderColor: `var(--exo-${exobotsMin[exobot.SubRoutine2?.Kinetics]}-color)` }}>{exobots[exobot.SubRoutine2?.Kinetics]}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="blank-space" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowDetail;
