/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import oneCapsule from './capsule-bundle-01.png';
import fiveCapsules from './capsule-bundle-02.png';
import tenCapsules from './capsule-bundle-03.png';
import { useAppSelector } from '../../redux/hooks';
import BuyCapsules from '../../sharedComponents/buyCapsules';

type ICapsuleProps = {
  numCapsules: number,
  price: number
}

const Capsule = ({ numCapsules, price }: ICapsuleProps) => {
  const { t } = useTranslation('common');
  const [img, setImg] = useState<string>('');
  const [text, setText] = useState<string>('');

  const currentPrice: number = useAppSelector<number>((state) => state.user.currentPrice);

  useEffect(() => {
    switch (numCapsules) {
      case 1:
        setImg(oneCapsule);
        setText(t('capsules.one'));
        break;

      case 5:
        setImg(fiveCapsules);
        setText(t('capsules.five'));
        break;

      case 10:
        setImg(tenCapsules);
        setText(t('capsules.ten'));
        break;

      default:
        setImg(oneCapsule);
        setText(t('capsules.one'));
        break;
    }
  }, []);

  return (
    <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="card product-card capsule-box zoom">
        <div className="container top-card-info top-capsule">
          <div className="row">
            <div className="col white exo-info p-2 text-center">
              <h3 className="avega info-card-value yellow-txt p-3">
                {text}
                {' '}
                {t('capsules.capsule')}
              </h3>
            </div>

          </div>
        </div>

        <div className="pt-4">
          <h1 className="text-center product-price mb-md-0">
            <b>
              {price}
              {' '}
            </b>
            <span className="fs-6">EXOS</span>
          </h1>
          <p className="text-center product-price-dollar mb-md-0">
            {(currentPrice * price).toLocaleString()}
            <span className="fs-6"> BNB</span>
          </p>
          <img
            className="img-fluid"
            src={img}
            alt="Product"
          />
          <div className="d-grid gap-2 col-6 mx-auto p-3">
            <BuyCapsules numCapsules={numCapsules} price={price} />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Capsule;
