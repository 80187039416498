import React, { ReactElement } from 'react';
import { Navigate } from 'react-router';

type ProtectedRouteProps = {
  isLoggedIn: boolean | undefined,
  children: ReactElement | JSX.Element,
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { isLoggedIn, children } = props;
  if (!isLoggedIn) return <Navigate to="/" />;

  return children;
};

export default ProtectedRoute;
