/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import BottomBanner from '../../sharedComponents/BottomBanner';

const CookiesAndPrivacy = () => (
  <>
    <div className="top-container city-header">
      <div className="top-banner container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-l-6 col-md-6 col-sm-12" />
          <div className="col-xl-6 col-lg-6 col-md-6" />

        </div>
      </div>
    </div>
    <div className="watercolor-top-blue" />
    <div className="text-white blueprint">
      <h4><strong>LEGAL NOTICE</strong></h4>
      <p>
        Metrobots Games, S.L. (hereinafter, the Holder) with NIF B67907915, and domiciled in
        Espinardo, Murcia, Parque Científico de Murcia - Ctra de Madrid Km 388 - Edificio R, módulos 26/27 registered in the Mercantile Registry of Murcia, Volume:
        3.555, Folio: 40, Section: 1, Registry Sheet: 106455, is the owner of the Website accessible
        through the URL: https://exobotsgame.com/ (hereinafter, Exobots or the Website, indistinctly).
        For any question, doubt or suggestion regarding Exobots, you can contact us through the e-mail
        address info@exobotsgame.com.
      </p>
      <h4><strong>Disclaimer</strong></h4>
      <p>
        The Website is intended solely for general informational purposes about the "Exobots Omens
        Of Steel" NFT game and does not constitute a prospectus, securities offering document,
        investment solicitation, or any offer to sell any product, item or asset (whether digital or
        otherwise).
        Exobots (EXOS) tokens are configured as utility tokens in any jurisdiction and are not and
        cannot be considered security tokens or any other kind of regulated tokens. They are also not
        FIAT or asset-backed, either on a global or limited scope basis.
      </p>
      <h1>TERMS AND CONDITIONS OF USE</h1>
      <p>
        These Terms of Use constitute a legally binding agreement between you ("You"), either
        personally or on behalf of an entity and METROBOTS GAMES, SOCIEDAD LIMITADA
        ("we", "us", "us" or "our") ID B67907915 in connection with your access to and use of the
        Website, the "Exobots: Omens of steel", the marketplace associated therewith, as well as any
        other form of media, media channel, mobile website or mobile application related, linked or
        otherwise connected thereto (collectively, the "Site" and the "App").
        "Exobots: Omens of steel" is an application owned by the company METROBOTS GAMES
        LIMITED COMPANY currently running on the Binance Smart Chain Network (the
        "Blockchain"), using specially developed smart contracts (each, a "Smart Contract") to allow
        users to own, transfer, fight and breed unique digital creatures. As well as allowing other digital
        assets associated with the game and its operation to be owned and transferred.
        These assets can be displayed on a website that the user can interact with (the "Marketplace").
        The Smart Contracts and the Marketplace are collectively referred to in these Terms as the
        "Application".
        Through the Application, users can view their assets and use Smart Contracts to acquire, trade,
        fight and breed creatures with other users of the Applicationç
      </p>
      <ol>
        <h2><li>ABOUT THE ACCEPTANCE OF THE TERMS AND CONDITIONS</li></h2>
        <p>
          METROBOTS GAMES, SOCIEDAD LIMITADA only and exclusively allows the use of the
          application, the smart contracts, the marketplace and any other content related to "Exobots:
          Omens of steel" upon acceptance of these terms and conditions.
          {' '}
          <br />
          Such acceptance shall be materialized in triplicate, being however sufficient with any one of the
          three to have the effects intended by these Terms and Conditions.
          {' '}
          <br />
          a) Express acceptance through the linking of the wallet or any other software enabled to interact
          with the application, the marketplace or the web.
          {' '}
          <br />
          b) Express acceptance by downloading the application "Exobots: Omens of steel" from any of
          the stores where it is made available to the user.
          {' '}
          <br />
          c) Tacit acceptance through the use of the "Exobots: Omens of steel" application, the
          marketplace or the web.
          {' '}
          <br />
          If you do not agree with the terms and conditions stated in this document, you are expressly
          prohibited from using the "Exobots: Omens of steel" application, the website, the marketplace
          and the smart contracts. You must stop using them immediately.
          We reserve the right, in our sole discretion, to make changes or modifications to these Terms
          and Conditions at any time and for any reason. We will notify you of any changes by updating the "Last Updated" date on these Terms of Use, and you waive any right to receive specific
          notice of each such change.
          {' '}
          <br />
          It is your responsibility to periodically review these Terms of Use to stay informed of updates.
          You will be bound by any changes to the revised Terms and Conditions of Use and will be
          deemed to know and accept them by your continued use of the Marketplace, the App and Smart
          Contracts after the date of posting of such revisions.
          The information contained in the Site,, the marketplace, Application and Smart Contracts is not
          intended for distribution to any person or entity in any jurisdiction or country where such
          distribution or use would be contrary to law or regulation, or which would subject us to any
          registration requirements in such jurisdiction or country. Accordingly, persons who access the
          Site and/or the Application in violation of their local laws do so on their own initiative and
          must abide by the consequences where applicable.
          {' '}
          <br />
          The Site is intended for users over the age of 18. Persons under the age of 18 are not authorized
          to use or register in the marketplace, the application or to make use of Smart Contracts
        </p>
        <h2><li>INTELLECTUAL PROPERTY RIGHTS.</li></h2>
        <p>
          Unless otherwise noted, the Site, the marketplace, Application and Smart Contracts are our
          property and all source code, database, functionality, software, website design, audio, video,
          text, photographs and graphics on the Site and Applications (collectively, the "Content") and
          trademarks, the service marks and logos contained therein (the "Marks") are owned, controlled
          or licensed by us, and are protected by copyright and trademark laws and other intellectual
          property rights and unfair competition laws of Spain, foreign jurisdictions and international
          conventions.
        </p>
        <p>
          Except as expressly provided in these Terms of Use, no part of the Site, the marketplace, the
          Application, the Smart Contract and no Content or Mark may be copied, reproduced, added to,
          republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed,
          sold, licensed or otherwise exploited for any commercial purpose, without our express prior
          written permission
        </p>
        <p>
          Provided that you are eligible to use the Site, the marketplace, the App and Smart Contracts,
          you are granted a limited license to access and use the Site or to download or print a copy of
          any portion of the Content that you have properly accessed solely for your personal, noncommercial use. We reserve all rights not expressly granted in the Site, the marketplace, the
          App, the Content and the Marks.
        </p>
        <p>
          Provided you own, or obtain permission from someone who owns an Exobots: Omens of steel
          asset, you are granted a limited license to create fan-art that may be used commercially as long
          as you follow the terms set forth herein:
          <ol>
            <li>
              The artwork must clearly state "Exobots: Omens of steel" and link to
              https://www.exobotsgame.com /
            </li>
            <li>
              The fan-art created can be used to generate a maximum of $1,000 in revenue before an
              official licensing agreement must be signed in which the terms of the commercialization of the
              fan-art will be negotiated. Revenue can come from both fanart (tokenized or physical) and
              merchandising (t-shirts, mugs, sweatshirts, etc).
              The creation of original non-profit fan-art is acceptable without any license or ownership.

            </li>
          </ol>
        </p>
        <h2><li>OBLIGATIONS OF THE USER</li></h2>
        <p>By using the Website, the marketplace, the App and the Smart Contracts, you represent andwarrant that:</p>
        <p>
          (1) all registration information you submit will be true, accurate, current and complete; (2) you
          will maintain the accuracy of such information and promptly update such registration
          information as necessary; (3) you have legal capacity and agree to abide by these Terms and
          Conditions; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not
          access the Website, the marketplace, the App and Smart Contracts through automated, nonhuman means, whether through a bot, script or otherwise. Unless expressly authorized by
          Metrobots Games Sociedad Limitada (6) you will not use the Website, marketplace,
          Application and Smart Contracts for illegal and unauthorized purposes; and (7) your use of the
          Site, Application and Smart Contracts will not violate any applicable law or regulation. If you
          provide any information that is untrue, inaccurate, not current or incomplete, METROBOTS
          GAMES SOCIEDAD LIMITADA reserves the right to suspend or terminate your account and
          refuse any and all current or future use of the Site, the marketplace, the App and Smart
          Contracts (or any portion thereof). (8) you agree that you have not been placed on any trade
          embargo or economic sanctions list (such as the united nations security council sanctions list),
          the list of Specially Designated Nationals maintained by OFAC (the office of foreign assets
          control of the u.s. department of the treasury), or the U.S. Treasury's Office of Foreign Assets
          Control (OFAC), or the U.S. Department of Treasury's Specially Designated Nationals List. (9)
          That Metrobots Games Sociedad Limitada or third party vendors with whom we work and who
          have been previously authorized, may store the IP address you use to access the Site
        </p>
        <p>
          Metrobots Games Sociedad Limitada reserves the right to choose the markets and jurisdictions
          in which to conduct its business and may restrict or refuse, in its sole discretion, to provide
          services in certain countries or regions without incurring any liability to users.
        </p>
        <h2><li>USER REGISTRATION.</li></h2>
        <p>
          You may be required to register for the website, marketplace, Application and Smart Contracts.
          You agree to keep your password confidential and will be responsible for any and all use of
          your account and password.
          We reserve the right to remove, reclaim or change a username you select if we determine, in
          our sole discretion, that such username is inappropriate, obscene or otherwise objectionable.
        </p>
        <h2><li>PROHIBITED ACTIVITIES</li></h2>
        <p>
          You may not access or use the Website, marketplace, App and Smart Contracts for any purpose
          other than the purpose for which we make the Website, marketplace, App and Smart Contracts
          available to you.

        </p>
        <p>
          The Website, the marketplace, the App and the Smart Contracts may not be used in connection
          with any commercial activity, except as previously agreed in a legally binding contract with
          Metrobots Games Sociedad Limitada.

        </p>
        <p>
          The following actions are strictly prohibited and METROBOTS GAMES SOCIEDAD
          LIMITADA reserves the right to take any disciplinary action:
        </p>
        <ul>
          <li>
            Systematically extract data or other content from the Site, marketplacce, App and Smart
            Contracts to create or compile, directly or indirectly, a collection, compilation, database or
            directory without our written permission.
          </li>
          <li>
            Use a purchasing agent or buyer agent to make purchases on the Site, marketplace, App and
            Smart Contracts.
          </li>
          <li>
            Circumvent, disable or otherwise interfere with security-related features of the Site,
            marketplace, App and Smart Contracts, including features that prevent or restrict the use or
            copying of any Content or apply limitations on the use of the Site, marketplace, App and
            Smart Contracts and/or the Content contained therein.
          </li>
          <li>
            Make any unauthorized use of the Site, the marketplace, the App and Smart Contracts,
            including collecting usernames and/or email addresses of users by electronic or other means
            for the purpose of sending unsolicited email, or creating user accounts by automated means
            or under false pretenses.
          </li>
          <li>
            Use the Site, marketplace, App and Smart Contracts to advertise or offer to sell goods or
            services.
          </li>
          <li>
            The use of any method to obtain from METROBOTS GAMES SOCIEDAD LIMITADA or
            any of the users of "Exobots: Omens of steel" any kind of confidential information from the
            users' accounts.
          </li>
          <li>
            Misuse our support services or submit false reports of abuse or misconduct.
          </li>
          <li>
            Engage in any automated use of the system, such as the use of scripts to send comments or
            messages, or the use of any data mining, robots or similar data gathering and extraction
            tools, unless these have been previously authorized by METROBOTS GAMES SOCIEDAD
            LIMITADA.
          </li>
          <li>
            Interfere with, disrupt or create an undue burden on the Site, the marketplace, the
            Application and Smart Contracts or the networks or services connected to the Site.
          </li>
          <li>
            Attempting to impersonate another user or person or using another user's username.
          </li>
          <li>
            Sell or otherwise transfer your profile or user account.
          </li>
          <li>
            Use any information obtained from the Site, the marketplace, the App and Smart Contracts
            to harass, abuse or harm another person.
          </li>
          <li>
            Use the Site, marketplace, Application and Smart Contracts as part of any effort to compete
            with us or otherwise use the Site, marketplace, Application and Smart Contracts and/or the
            Content for any revenue-generating endeavor or commercial enterprise.
          </li>
          <li>
            Decrypt, decompile, disassemble or reverse engineer any of the software comprising or
            forming part of the Website, the marketplace, the Application and the Smart Contracts.
          </li>
          <li>
            Attempt to circumvent any measures on the Website designed to prevent or restrict access to
            the Website, or any part of the Website, the marketplace, the App and Smart Contracts.

          </li>
          <li>
            Remove copyright or other proprietary rights notices from any Content.
          </li>
          <li>
            Copying or adapting Site software, including but not limited to Flash, PHP, HTML,
            JavaScript or other code.
          </li>
          <li>
            Upload or transmit (or attempt to upload or transmit) viruses, Trojan horses or other
            material, including excessive use of all capital letters and spam (continuous posting of
            repetitive text), that interferes with the uninterrupted use and enjoyment of the Website, the
            marketplace or the App, by any party or that modifies, impairs, interrupts, alters or interferes
            with the use, features, functions, operation or maintenance of the Website, the marketplace,
            the App and the Smart Contracts.
          </li>
          <li>
            Upload or transmit (or attempt to upload or transmit) any material that acts as a passive or
            active information gathering or transmission mechanism, including but not limited to clear
            graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies or other similar devices
            (sometimes referred to as "spyware" or "passive collection mechanisms").
          </li>
          <li>
            You are prohibited from using, launching, developing or distributing any automated system,
            including, without limitation, any spider, robot, spoofing utility, scraper or offline reader that
            accesses the Website, the marketplace, the Application and the Smart Contracts, or using or
            launching any unauthorized script or other software. Except as may result from the use of
            standard search engines or Internet browsers.
          </li>
          <li>
            Disparage, discredit, detract from or otherwise harm, in our opinion, us (as a legal entity and
            as individuals performing their work in this context) and/or the Site, the marketplace, the
            Application and the Smart Contracts.
          </li>
          <li>
            Use the Site, the marketplace, the Application and the Smart Contracts in a manner contrary
            to applicable laws or regulations.
          </li>
          <li>
            Engage in any practice aimed at manipulating the outcome of any game of "Exobots: Omens
            of steel". Any kind of match-fixing, victory swapping or collusion between competitors is
            strictly prohibited.
          </li>
        </ul>
        <h2><li>PAYMENTS AND COMMISSIONS.</li></h2>
        <p>
          Any purchase made in the marketplace will be carried out through smart contracts on a
          blockchain using a wallet such as the Metamask wallet. Any financial transaction you make
          will be carried out solely through the Blockchain using a wallet such as MetaMask.
        </p>
        <p>
          <strong>
            We will have no knowledge of or control over these payments or transactions, nor do we have
            the ability to reverse any transaction. With this in mind, we will not be liable to you or any third
            party for any claims or damages that may arise as a result of any transactions you make through
            the Website, the marketplace and/or the App or using the Smart Contracts, or any other
            transactions you make through the Binance Smart Chain network

          </strong>
        </p>
        <p>
          The Binance Smart Chain network requires the payment of a transaction fee (a "Gas Fee") for
          each transaction that occurs on the network. The fee funds the network of computers that run
          the network. This means that you will have to pay a fee for each transaction that occurs through
          the application.
        </p>
        <p>
          In addition to the Gas Fee or network commission, each time you use a Smart Contract to make
          a transaction with another user through the application or the marketplace, you authorize us to
          charge a commission of the total value of such transaction (each, a "Commission"). Such
          amount will be set out in the whitepaper and will be sufficiently publicly announced both on
          the website, the whitepaper and other communication channels provided. We reserve the right
          to change the amount at any time as long as the advertising requirements set forth above are met. You acknowledge and agree that the Commission will be transferred directly to us as part
          of the transaction.
        </p>
        <p>
          You will be solely responsible for paying any and all sales, use, value added and other taxes,
          fees and levies (other than taxes on our net income) now or hereafter claimed or imposed by
          any governmental authority (collectively, "Taxes") associated with your use of the Application
          (including, without limitation, any Taxes that may be payable as a result of your ownership,
          transfer or reproduction of any of your digital assets). Except for Taxes that pertain solely to the
          merchant in the conduct of its business, you will: (i) will pay or reimburse us for all national,
          federal, state, local or other taxes and levies of any jurisdiction, including value added taxes
          and taxes required by international tax treaties, customs or other import or export taxes, as well
          as amounts collected in lieu thereof based on charges assessed, services rendered or payments
          made hereunder, that are currently imposed or may be imposed in the future under the authority
          of any national, state, local or other taxing jurisdiction; and (ii) you will not be entitled to
          deduct the amount of any such taxes, levies or duties from any payments you make to us under
          these terms and conditions.
        </p>
        <h2><li>THIRD PARTY WEBSITES AND CONTENT</li></h2>
        <p>
          The Site, the marketplace and/or the App (or you may receive through the Site and/or the App)
          links to other websites ("Third Party Websites"), as well as to articles, photographs, text,
          graphics, images, designs, music, sound, video, information, applications, software and other
          content or items owned by or originating from third parties ("Third Party Content"). We do not
          investigate, monitor or check the accuracy, suitability or completeness of the Third Party
          Websites or Third Party Content, and we are not responsible for any Third Party Websites
          accessed through the Site and/or the App, or any Third Party Content posted on, made available
          on or installed from the Site and/or the App, including the content, accuracy, offensiveness,
          opinions, reliability, privacy practices or other policies of the Third Party Websites or Third
          Party Content. The inclusion of, linking to, or permitting the use or installation of Third Party
          Websites or Third Party Content does not imply approval or endorsement by us. If you decide
          to leave the Site and/or the App and access the Third Party Websites or use or install any Third
          Party Content, you do so at your own risk and should be aware that these Terms and Conditions
          of Use no longer apply. You should review the applicable terms and policies, including privacy
          and data collection practices, of any websites you navigate to from the Site and/or the App or
          relating to any applications you use or install from the Site and/or the App. Any purchases you
          make through third party websites are made through other websites and other companies, and
          we assume no responsibility or liability in connection with such purchases, which are solely
          between you and the applicable third party. You agree and acknowledge that we do not endorse
          the products and services offered on third party websites and that you shall hold us harmless for
          any damages caused by the purchase of such products and services. In addition, you shall hold
          us harmless for any loss or damage you suffer in connection with any Third Party Content or
          any contact with Third Party Websites.
        </p>
        <h2>
          <li>
            AVAILABILITY OF THE WEBSITE, MARKETPLACE
            AND APPLICATION.
          </li>

        </h2>
        <p>
          Metrobots Games Sociedad Limitada is working to improve and expand "Exobots: Omens of
          steel", as well as its content and related services. Despite this, the website, the marketplace and
          the application will be displayed as it is at any given moment according to availability,
          limitations and other concurrent circumstances.
        </p>
        <p>
          Not being able in any case be responsible for that for reasons beyond the control of Metrobots
          Games Sociedad Limitada can not provide all or some of the services.
        </p>
        <h2><li>ADVERTISING</li></h2>
        <p>
          We allow advertisers to display their advertisements and other information in certain areas of
          the Site and the App, such as sidebar ads or banner ads. If you are an advertiser, you must take
          full responsibility for the advertisements you place on the Site, the marketplace and/or the App,
          as well as for the services provided on the Site, the marketplace and/or the App, or the products
          sold through such advertisements. In addition, as an advertiser, you warrant and represent that
          you have all rights and authority to place advertisements on the Site and/or the App, including,
          without limitation, intellectual property rights, publicity rights and contractual rights. We
          merely provide the space to place such advertisements, and have no other relationship with
          advertisers.
        </p>
        <h2><li>TERMINATION.</li></h2>
        <p>
          These Terms of Use shall remain in full force and effect while you use the Site, the
          marketplace, the App and the Smart Contracts. WITHOUT LIMITING ANY OTHER
          PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT, IN OUR SOLE
          DISCRETION AND WITHOUT NOTICE OR LIABILITY, TO DENY ACCESS TO AND
          USE OF THE SITE, THE MARKETPLACE, THE APP AND THE SMART CONTRACT
          (INCLUDING BLOCKING CERTAIN IP ADDRESSES) TO ANYONE FOR ANY REASON
          OR NO REASON, INCLUDING, WITHOUT LIMITATION, BREACH OF ANY
          REPRESENTATION, WARRANTY OR COVENANT CONTAINED IN THESE TERMS OF
          USE OR ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE
          OF OR PARTICIPATION IN THE SITE, THE MARKETPLACE, THE APP AND THE
          SMART CONTRACT OR DELETE YOUR ACCOUNT WITHOUT NOTICE, IN OUR SOLE
          DISCRETION.
        </p>
        <p>
          If we terminate or suspend your account for any reason, you are prohibited from registering and
          creating a new account under your name, a false or borrowed name, or the name of a third
          party, even if you are acting on behalf of a third party. In addition to terminating and
          suspending your account, we reserve the right to take appropriate legal action, including,
          without limitation, civil, criminal, and injunctive relief.
        </p>
        <h2><li>APPLICABLE LAW.</li></h2>
        <p>
          These Terms and Conditions of Use and your use of the Website, the marketplace, the App and
          the Smart Contracts are governed by Spanish law applicable to agreements entered into and to
          be performed entirely within Spain, without regard to its conflicts of law principles.
        </p>
        <h2><li>DISPUTE RESOLUTION</li></h2>
        <h4>12.1 Informal Negotiations</h4>
        <p>
          To expedite the resolution and control the cost of any dispute, controversy or claim relating to
          these Terms and Conditions of Use (each a "Dispute" and collectively, the "Disputes") brought
          by you or us (individually, a "Party" and collectively, the "Parties"), the Parties agree to first
          attempt to negotiate any Dispute (except those Disputes expressly provided for below)
          informally for at least thirty (30) days prior to initiating arbitration. Such informal negotiations
          shall commence upon written notice from one Party to the other.
        </p>
        <h4>12.2 Law and Jurisdiction</h4>
        <p>
          The relationship between the Company and the User shall be governed by Spanish law and the
          discrepancies that could not be solved through the informal negotiation indicated in the
          previous point shall be resolved according to the condition of consumer and / or user and the
          applicable regulations.
        </p>
        <p>
          In case of not having such condition the conflicts will be ventilated before the Courts and
          Tribunals of Murcia.
        </p>
        <h2><li>DISCLAIMERS</li></h2>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF
          THE SITE, MARKETPLACE AND APP AND SMART CONTRACTS ARE AT YOUR SOLE
          RISK, AND THAT THE SITE, MARKETPLACE, APP AND SMART CONTRACTS ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTIES
          OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES AND
          LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL
          IMPLIED WARRANTIES WITH REGARD TO THE SITE, THE APP AND SMART
          CONTRACTS AND ANY PART THEREOF (INCLUDING, WITHOUT LIMITATION, THE
          SITE, ANY SMART CONTRACT OR ANY EXTERNAL WEBSITE), INCLUDING ANY
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY OR RELIABILITY.
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR
          SUBSIDIARIES, AFFILIATES AND LICENSORS DO NOT REPRESENT OR WARRANT
          TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SITE, THE MARKETPLACE,
          THE APP AND SMART CONTRACTS WILL MEET YOUR NEEDS, (II) YOUR ACCESS
          TO OR USE OF THE SITE, THE APP AND SMART CONTRACTS WILL BE
          UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, (III) THE USAGE DATA PROVIDED THROUGH THE SITE, THE MARKETPLACE, THE APP AND SMART
          CONTRACTS WILL BE ACCURATE, (IV) THAT THE SITE, THE MARKETPLACE, APP
          AND SMART CONTRACTS OR ANY CONTENT, SERVICE OR FEATURE AVAILABLE
          ON OR THROUGH THE SITE, APP AND SMART CONTRACTS ARE FREE OF VIRUSES
          OR OTHER HARMFUL COMPONENTS, OR (IV) ANY DATA YOU DISCLOSE WHEN
          USING THE SITE, MARKETPLACE, APP AND SMART CONTRACTS IS SECURE. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN
          CONSUMER CONTRACTS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY
          NOT APPLY TO YOU.
        </p>
        <p>
          YOU ACCEPT THE SECURITY RISKS INHERENT IN PROVIDING INFORMATION AND
          TRADING ONLINE OVER THE INTERNET AND AGREE THAT WE HAVE NO
          LIABILITY FOR ANY BREACH OF SECURITY UNLESS DUE TO GROSS NEGLIGENCE
          ON OUR PART.
        </p>
        <p>
          WE WILL NOT BE LIABLE TO YOU FOR ANY LOSS INCURRED BY YOU AS A
          RESULT OF YOUR USE OF THE NETWORK ON WHICH THE APPLICATION,
          METAMASK E-WALLET OR OTHERS OPERATE, INCLUDING, BUT NOT LIMITED TO,
          ANY LOSS, DAMAGE OR CLAIM ARISING FROM (A) USER ERROR, SUCH AS
          FORGOTTEN PASSWORDS OR MISINTERPRETED SMART CONTRACTS OR OTHER
          TRANSACTIONS; (B) SERVER FAILURES OR LOSS OF DATA; (C) CORRUPTED EWALLET FILES; (D) UNAUTHORIZED ACCESS OR ACTIVITIES OF THIRD PARTIES,
          INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTE
          FORCE OR OTHER MEANS OF ATTACK AGAINST THE APP, THE NETWORK USING
          THE APP OR THE METAMASK E-WALLET
        </p>
        <p>
          DIGITAL ASSETS ARE INTANGIBLE ASSETS THAT EXIST SOLELY BY VIRTUE OF
          THE OWNERSHIP RECORD MAINTAINED ON THE BNB NETWORK. ALL SMART
          CONTRACTS ARE CONDUCTED AND TAKE PLACE ON THE DECENTRALIZED
          LEDGER WITHIN THE BNB NETWORK. METROBOTS GAMES LIMITED COMPANY
          IS NOT RESPONSIBLE FOR ANY LOSSES DUE TO BLOCKCHAINS OR ANY OTHER
          FEATURE OF THE BNB NETWORK, THE METAMASK CRYPTOCURRENCY WALLET
          OR THE CRYPTOCURRENCY WALLET OF YOUR CHOICE.
        </p>
        <h2><li>LIMITATION OF LIABILITY.</li></h2>
        <p>
          YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES, AFFILIATES AND
          LICENSORS SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES THAT YOU MAY
          INCUR, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING,
          WITHOUT LIMITATION, ANY LOST PROFITS (WHETHER INCURRED DIRECTLY OR
          INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA,
          COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER
          INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES.
        </p>
        <p>
          YOU AGREE AND ACKNOWLEDGE THAT OUR TOTAL AND AGGREGATE LIABILITY
          TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE
          TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PART OF THE SITE, THE APP AND THE SMART CONTRACTS, WHETHER
          IN CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER LEGAL THEORY, IS
          LIMITED TO THE GREATER OF: (A) THE AMOUNTS YOU ACTUALLY PAID TO US
          UNDER THESE TERMS IN THE TWELVE (12) MONTH PERIOD PRIOR TO THE DATE
          THE CLAIM AROSE, OR (B) ONE HUNDRED (100) U.S. DOLLARS
        </p>
        <p>
          YOU AGREE AND ACKNOWLEDGE THAT WE HAVE MADE THE WEBSITE,
          MARKETPLACE, APP AND SMART CONTRACTS AVAILABLE TO YOU AND THAT WE
          HAVE ENTERED INTO THESE TERMS BASED ON THE WARRANTY DISCLAIMERS
          AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A
          REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES AND ARE
          AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. WE COULD NOT PROVIDE
          THE WEBSITE, MARKETPLACE, APP AND SMART CONTRACTS TO YOU WITHOUT
          THESE LIMITATIONS.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND SOME JURISDICTIONS ALSO
          LIMIT THE EXCLUSIONS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY
          CAUSED BY CONSUMER PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT
          APPLY TO PERSONAL INJURY CLAIMS.
        </p>
        <h2><li>ASSUMPTION OF RISK</li></h2>
        <p>You agree and acknowledge each of the following:</p>
        <ol type="A">
          <li>
            Blockchain asset prices are extremely volatile. Fluctuations in the price of other digital
            assets could materially and adversely affect the value of your digital assets, which may also be
            subject to significant price volatility. We cannot guarantee that purchasers of "Exobots: Omens
            of steel" will not lose money.
          </li>
          <li>
            You are solely responsible for determining what, if any, taxes apply to your EXOS-related
            transactions. Metrobots Games Sociedad Limitada is not responsible for determining what
            taxes apply to your transactions on the App, the marketplace, the Site or Smart Contracts.
          </li>
          <li>
            The App does not store, send or receive EXOS. This is because EXOS only exist by virtue of
            the ownership record maintained on the blockchain. Any transfer of EXOS only occurs on the
            blockchain network.
          </li>
          <li>
            There are risks associated with the use of an Internet-based currency, including, but not
            limited to, the risk of hardware, software and Internet connections, the risk of introduction of
            malicious software, and the risk that third parties may gain unauthorized access to information
            stored in your wallet. You agree and acknowledge that Metrobots Games Sociedad Limitada
            shall not be liable for any communication failures, interruptions, errors, distortions or delays
            that you may experience while using the network, however caused.
          </li>
          <li>
            Lack of use or public interest in the creation and development of distributed ecosystems
            could have a negative impact on the development of the EXOS ecosystem and, therefore, on the
            potential usefulness or value of EXOS.
          </li>
          <li>
            The regulatory regime governing blockchain technologies, cryptocurrencies and tokens is
            uncertain, and new regulations or policies could negatively impact the development of the
            EXOS ecosystem and thus the potential utility or value of EXOS.
          </li>
        </ol>
        <h2>
          <li>
            INDEMNIFICATION AND DISCLAIMER OF
            LIABILITY
          </li>
        </h2>
        <p>
          You agree to hold us, including our subsidiaries, affiliates and all of our respective officers,
          agents, partners and employees harmless from any loss, damage, liability, claim or demand,
          including reasonable attorneys' fees and expenses, made by any third party due to or arising out
          of: (1) your use of the Website, the marketplace, or the App, (2) your breach of these Terms and
          Conditions of Use, (3) any breach of your representations and warranties set forth in these
          Terms and Conditions of Use, (4) your violation of any third party's rights, including, without
          limitation, intellectual property rights, or (5) any overt harmful acts towards any other use of
          the Website, the marketplace, the App and the Smart Contracts you have connected with.
        </p>
        <p>
          Metrobots Games works to ensure that the services and functionalities of the Website, the
          marketplace and the App are always available. However, when you access these services, they
          will be displayed to you subject to availability and limitations from time to time.
        </p>
        <p>
          Despite the continuous effort of Metrobots Games to protect the systems and the contents
          included in the Website, marketplace and application, to which effect it employs the usual
          security standards on the Internet, it is not possible to offer full guarantees in relation to the
          intrusions or loss of information that may occur. Similarly, the absence of viruses or other
          harmful elements on the Website or on third party websites that may cause alterations in the
          User's computer system, both software and hardware, cannot be guaranteed. For this reason, the
          User assumes and understands that there are situations that may be beyond the control of
          Exobots.
        </p>
        <p>
          In the same way, Metrobots Games is exempted from any responsibility derived from a bad use
          of the same on the part of the Users, as well as for the breach of the obligations or
          commitments assumed by virtue of these conditions or any other applicable one.
        </p>
        <h2>
          <li>
            PROTECTION OF PERSONAL DATA
          </li>
        </h2>
        <p>
          In order to guarantee the fundamental right of our customers to the protection of their personal
          data, below is information about the processing of data provided during registration and throughout
          the relationship between the user and METROBOTS GAMES SOCIEDAD LIMITADA.
        </p>
        <h4>17.1 Data controllers</h4>
        <p>
          The responsible for the treatment will be the company Metrobots Games, S.L. con NIF B67907915,
          y address in Espinardo, Murcia, Parque Científico de Murcia - Ctra de Madrid Km 388 - Edificio R, módulos 26/27 inscrita en el Registro Mercantil de Murcia, Tomo: 3.555, Folio: 40, Sección: 1, Hoja Registral: 106455, es titular del Sitio Web accesible a través de
          la URL: https://exobotsgame.com/
        </p>
        <p>
          THE COMPANY is fully committed to compliance with current legislation on data protection and
          therefore, has appointed a Data Protection Officer, with whom you can contact through the email
          address info@exobotsgame.com.
        </p>
        <h4>17.2 Purposes for which the data is collected</h4>
        <p>
          Below, you will find the processing that THE COMPANY may carry out with your personal data:
          PURPOSE Nº1: Provision of services both through the website, the marketplace or the "Exobots:
          Omens of steel" application.
          PURPOSE Nº2: Management and analysis of user participation, with the main purpose of
          continuing to improve the content offered and optimize the user experience.
          PURPOSE Nº3: Analysis, segmentation and profiling of users for sending commercial and
          promotional communications.
          PURPOSE Nº4: To comply with those legal requirements that may arise according to the growing
          regulation of the cryptocurrency sector
        </p>
        <h4>17.3 Type of data processed.</h4>
        <p>
          The data to be processed are:
          <ul>
            <li>The credentials necessary to confirm the login (User and password).</li>
            <li>The IP address with which the session is initiated.</li>
            <li>The device ID, which if provided by the user may include the phone number.</li>
            <li>
              The public address of the wallet used to interact with the website, marketplace and/or
              application.

            </li>
          </ul>
        </p>
        <h4>17.4 Data retention time</h4>
        <p>
          The data will be kept for as long as the contractual relationship with the customer is maintained.
          Once the contractual relationship has ended, the data will be deleted in accordance with the
          provisions of the data protection regulations, which implies a prior blocking, being available only
          at the request of Judges and courts, the Public Prosecutor's Office or the competent Public
          Administrations during the period of limitation of the actions that may arise and, after this, will
          proceed to its complete elimination.
          In any case, if at the end of the contractual relationship there are pending lawsuits or claims, the
          data may be kept during the processing of these, until a final decision is reached -when they will be
          blocked and subsequently deleted-, although they may only be used for evidentiary purposes.
        </p>
        <h4>17.5 Data Rights</h4>
        <p>
          In accordance with data protection regulations, you are granted a series of rights relating to the
          maintenance and processing of your personal data. Among these rights may include the right to
          revoke consent, access to personal data, modification thereof, portability, deletion, opposition to
          the sending of commercial communications, and other rights conferred by current legislation.
        </p>
        <p>In order to exercise these rights, you should contact METROBOTS GAMES SOCIEDAD LIMITADA either by post to its registered office</p>
        <p>Or by e-mail to info@exobotsgame.com</p>
        <h2><li>OTHER ISSUES</li></h2>
        <p>
          These Terms of Use and any policies or operating rules posted by us on the Site, marketplace,
          App and Smart Contracts, or in connection with the Site, marketplace, App and Smart Contracts
          constitute the entire agreement and understanding between you and us. Our failure to exercise
          or enforce any right or provision of these Terms of Use shall not constitute a waiver of such
          right or provision. These Terms of Use apply to the fullest extent permitted by law. We may
          assign any or all of our rights and obligations to third parties at any time. We will not be liable
          for any loss, damage, delay or omission caused by causes beyond our reasonable control. If any
          provision or part of a provision of these Terms of Use is determined to be unlawful, void and
          unenforceable, that provision or part of the provision is deemed severable from these Terms of
          Use and does not affect the validity and enforceability of the remaining provisions. No joint
          venture, partnership, employment or agency relationship is created between you and us as a
          result of these Terms of Use or your use of the Site.
        </p>
        <p>
          The language applicable to these Terms is English. If other language versions are provided in
          other languages such as English, it will only be for the User's convenience and courtesy. In case
          of contradiction, the Spanish version shall prevail.
        </p>
      </ol>
      <strong>LAST UPDATE: 22/12/2022</strong>
    </div>
    <BottomBanner />
  </>
);

export default CookiesAndPrivacy;
