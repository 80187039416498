/* eslint-disable max-len */
/* eslint-disable import/no-mutable-exports */
const marketplaceAddress = process.env.REACT_APP_MARKETPLACE_ADDRESS || '';
const nftContract = process.env.REACT_APP_NFT_CONTRACT_ADDRESS || '';
const exos = process.env.REACT_APP_EXOS_CONTRACT_ADDRESS || '';
const claimAddress = process.env.REACT_APP_CLAIM_CONTRACT_ADDRESS || '';
const operatorAddress = process.env.REACT_APP_OPERATOR_CONTRACT_ADDRESS || '';
const referralAddress = process.env.REACT_APP_REFERRAL_CONTRACT_ADDRESS || '';

const URL_BLOB_IMAGES = process.env.REACT_APP_IMAGES_BLOB || '';
const URL_NOTIFICATIONS = process.env.REACT_APP_NOTIFICATIONS || '';

const LEADERS_BOARD_SAS = process.env.REACT_APP_LEADERS_BOARD_SAS || 'SAS';
const LEADERS_BOARD_ACCOUNT = process.env.REACT_APP_LEADERS_BOARD_ACCOUNT || '';
const LEADERS_BOARD_TABLE = process.env.REACT_APP_LEADERS_BOARD_TABLE || '';

const API_COINGEKO_URL = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=bnb&ids=exobots';

const msOperationDelay = 5 * 1000;

const ROUTE_PATHS = {
  LeadersBoard: '/leaders-board',
  Register: '/register',
  Login: '/login',
  ValidateToken: '/validate/:token',
  ResetPassword: '/reset-password/:token',
  DashboardTab: '/dashboard/:tab',
  Dashboard: '/dashboard',
  Exobot: '/exobot/:id',
  Inventory: '/inventory/:id',
  PrivacyPolicy: '/privacy-policy',
  TermsAndConditions: '/terms-and-conditions',
  SharedMarket: '/player-market/:wallet',
  Capsules: '/capsules',
  Exonite: '/exonite',
  Claim: '/claim',
};

export {
  marketplaceAddress,
  nftContract,
  exos,
  claimAddress,
  operatorAddress,
  referralAddress,
  URL_BLOB_IMAGES,
  ROUTE_PATHS,
  msOperationDelay,
  URL_NOTIFICATIONS,
  LEADERS_BOARD_SAS,
  LEADERS_BOARD_ACCOUNT,
  LEADERS_BOARD_TABLE,
  API_COINGEKO_URL,
};
